import React, { Component } from 'react';

import './PaginationComponent.scss';

class PaginationComponent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentPage: 1,
      pageCount: 1,
      pageSize: 10
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    const data = this.props.data;

    console.log('prevProps.data', prevProps.data)
    console.log('data', data)

    if (prevProps.data.length !== data.length) {
      const startingPage = this.props.startingPage
      ? this.props.startingPage
      : 1;
      const pageSize = this.state.pageSize;
      let pageCount = Math.ceil(data.length / pageSize);

      console.log('pageCount', pageCount)

      this.setState({
        currentPage: startingPage,
        pageCount: pageCount
      });
    }
 
  }
  
  setCurrentPage(num) {
    this.setState({currentPage: num});
  }

  setPageSize(num) {
    this.setState({pageSize: num});
  }

  createControls() {
    let controls = [];
    const pageCount = this.state.pageCount;
    const data = this.props.data;
    const pageSize = this.state.pageSize;

    if (data.length > pageSize) {
      for (let i = 1; i <= pageCount; i++) {
        const baseClassName = 'pagination-controls__button';
        const activeClassName = i === this.state.currentPage ? `${baseClassName}--active` : '';
        controls.push(
          <div
          key={i}
            className={`${baseClassName} ${activeClassName}`}
            onClick={() => this.setCurrentPage(i)}
          >
            {i}
          </div>
        );
      }
      return controls;
    }
    
  }

  createPaginatedData() {
    const data = this.props.data;
    const pageSize = this.state.pageSize;

    const currentPage = this.state.currentPage;
    const upperLimit = currentPage * pageSize;
    const dataSlice = data.slice((upperLimit - pageSize), upperLimit);

    if (data.length <= 10 && data.length > 0) {
      return data
    } else {

      console.log('pageSize', pageSize)
      console.log('currentPage', currentPage)
      console.log('upperLimit', upperLimit)
      console.log('dataSlice', dataSlice)

      return dataSlice;
    }
  }

  render() {
    const { pageSize } = this.state;

    return (
      <div className='pagination'>
        <div className='pagination-results'>
          {React.cloneElement(this.props.children, {data: this.createPaginatedData()})}
        </div>
        <div className='pagination-controls'>
          <div className="pagination-offset">
            Wyświetlań na stronie: 
            <span 
              onClick={() => this.setPageSize(10)} 
              className={`offset-option ${pageSize === 10 ? "offset-option-active" : ""}`}
            >
              10
            </span>, 
            <span 
              onClick={() => this.setPageSize(30)} 
              className={`offset-option ${pageSize === 30 ? "offset-option-active" : ""}`}
            >
            30
            </span>, 
            <span 
              onClick={() => this.setPageSize(50)} 
              className={`offset-option ${pageSize === 50 ? "offset-option-active" : ""}`}
            >
              50
            </span>
          </div>
          <div className="pagination-pages">
            {this.createControls()}
          </div>
      </div>
      </div>
    );
  }
}

export default PaginationComponent;
