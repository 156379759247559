import React, { Component } from 'react';
import './OrderInfoComponent.scss';
import { NavLink, Link } from "react-router-dom";

import { Container, Row, Col } from 'react-grid-system';

class OrderInfoComponent extends Component {
  constructor(props) {
    super(props);
    }
    
  render() { 
    const orderDetails = this.props.order;
    const userRole = localStorage.getItem('role');
    const isCustomer = userRole == "0";
    const hasAcceptedOffer = orderDetails.acceptedOffer === null ? false : true;

    return (
      <React.Fragment>
      <div className="orderInfo-wrapper">
        <div className="orderInfo-top">
            <Container className="orderInfo-inner">
            <Row>
                    <Col xs={6} md={2}><span className="orderInfo-title">Marka</span>{orderDetails.model.make.name}</Col>
                    <Col xs={6} md={2}><span className="orderInfo-title">Model</span>{orderDetails.model.name}</Col>
                    <Col xs={6} md={2}><span className="orderInfo-title">Rok produkcji</span>{orderDetails.year}</Col>
                    <Col xs={6} md={3}><span className="orderInfo-title">Numer VIN</span>{orderDetails.vin}</Col>
                    <Col xs={6} md={3}><span className="orderInfo-title">
                    {orderDetails.details_url && <a href={orderDetails.details_url} target="_blank">Link do ogłoszenia</a>}
                    </span></Col>
            </Row>
            </Container>
        </div>
        <div className="orderInfo-bottom">
            <Container className="orderInfo-inner">
                <Row>
                    <Col xs={6} md={6}><span className="orderInfo-title">Lokalizacja</span>
                      {orderDetails.city && orderDetails.acceptedOffer ? orderDetails.city : orderDetails.city.slice(orderDetails.city.indexOf(',')+2)}
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderInfoComponent;
