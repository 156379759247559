import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import { Dropdown } from 'semantic-ui-react';
import ServiceSideNavComponent from "../../components/SideNavComponent/ServiceSideNavComponent";
import { serverURL, MAPBOX_TOKEN } from '../../config';
import InputComponent from "../../commons/InputComponent/InputComponent";
import { NotificationManager } from 'react-notifications';
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import CheckboxComponent from "../../commons/CheckboxComponent/CheckboxComponent";
import TextareaComponent from "../../commons/TextareaComponent/TextareaComponent";
import LocationInputComponent from "../../commons/LocationInputComponent/LocationInputComponent";
import Geocoder from 'react-mapbox-gl-geocoder'
import ReactMapGL, { Marker } from 'react-map-gl'
import './ServiceDash.scss';

const mapAccess = {
  mapboxApiAccessToken: MAPBOX_TOKEN
}

const mapStyle = {
  width: '100%',
  height: 300,
}

const queryParams = {
  country: 'pl',
  language: 'pl',
}

class ServiceDashMyAccView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {},
      selectedPlace: null,
      selectedPlaceLatitude: null,
      selectedPlaceLongitude: null,
      customPointSelected: false,

      type: 2,
      backendErrors: {},
      editUser: {
        first_name: '',
        last_name: '',
        phone: '',
        radius: '',
        city: '',
        lat: '',
        lon: '',
        street: '',
        company_location: '',
        description: '',
        data_processing_1: false,
        data_processing_2: false,
      },
      errors: {
        first_name_error: false,
        last_name_error: false,
        password_error: false,
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFieldLocationChange = this.onFieldLocationChange.bind(this);

    this.validateInputs = this.validateInputs.bind(this)
  }

  onSelected = (viewport, item) => {

    this.setState({
      viewport,
      selectedPlace: item,
      selectedPlaceLatitude: item.center[1],
      selectedPlaceLongitude: item.center[0],
      customPointSelected: false,
      editUser: { ...this.state.editUser, company_location: item.place_name }
    });
  }

  componentWillMount() {
    const token = localStorage.getItem('token');

    fetch(`${serverURL}users/me`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `bearer ${token}`,
      }),
    })
      .then(
        (response) => {
          if (response.ok) {
            response.json().then(data => {
              this.setState({
                editUser: data,
                selectedPlaceLatitude: data.lat,
                selectedPlaceLongitude: data.lon,
              })
              if (data.company_location) {
                const geocoderInput = document.querySelector('.react-geocoder > input')
                if (geocoderInput) geocoderInput.value = data.company_location
              }
            })
          } else {
            this.props.history.push(`/zaloguj`);
          }
        }
      )
  }

  onChange(field, value) {
    this.setState({ editUser: { ...this.state.editUser, [field]: value } });
    if (field === "radius") {
      this.setState(prevState => ({
        editUser: {
          ...prevState.editUser,
          radius: value
        }
      }))

    }
    if (field === "description") {
      this.setState(prevState => ({
        editUser: {
          ...prevState.editUser,
          description: value
        }
      }))
    }
  }

  onFieldLocationChange(data, field) {
    this.setState({
      editUser: {
        ...this.state.editUser,
        company_location: data.title,
        lon: data.value.lon,
        lat: data.value.lat,
      }
    })

  }
  validatePassword(password) {
    // eslint-disable-next-line
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
    // return re.test(String(password))
  }


  validateInputs(e) {
    const { first_name, last_name, password } = this.state
    e.preventDefault()
    const first_name_error = first_name.length < 2
    const last_name_error = last_name.length < 2
    const password_error = !this.validatePassword(password)

    this.setState({
      errors: {
        first_name_error,
        last_name_error,
        password_error,
      }
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    const data = JSON.stringify({
      ...this.state.editUser,
      lat: this.state.selectedPlaceLatitude,
      lon: this.state.selectedPlaceLongitude,
    });
    const token = localStorage.getItem('token');
    fetch(`${serverURL}users/edit`, {
      method: 'PATCH',
      headers: new Headers({ 'Authorization': `bearer ${token}`, 'content-type': 'application/json' }),
      body: data,
    })
      .then((response) => {

        if (response.ok) {
          NotificationManager.success('Twoje konto zostało edytowane.', 'Gratulacje', 5000)

          fetch(`${serverURL}users/me`, {
            method: 'GET',
            headers: new Headers({
              'Authorization': `bearer ${token}`,
            }),
          })
            .then(
              (response) => {
                localStorage.setItem('radius', this.state.editUser.radius)

                if (response.ok) {
                  response.json().then(data => {
                    this.setState({ editUser: data })
                  })
                } else {
                  this.props.history.push(`/zaloguj`);
                }
              }
            )
        } else {
          response.json().then(json => {
            this.setState({
              backendErrors: json.errors
            })
          })
        }
      })
  }
  render() {
    const { backendErrors, errors, editUser, viewport, selectedPlace, selectedPlaceLatitude, selectedPlaceLongitude, customPointSelected } = this.state;
    const distanceOptions = [
      { key: "1", text: "+ 2 km", value: "2" },
      { key: "2", text: "+ 5 km", value: "5" },
      { key: "3", text: "+ 10 km", value: "10" },
      { key: "4", text: "+ 15 km", value: "15" },
      { key: "5", text: "+ 20 km", value: "20" },
      { key: "6", text: "+ 30 km", value: "30" },
      { key: "7", text: "+ 50 km", value: "50" },
    ];
    const checkbox2 = `Powiadomienia o nowych zleceniach w Twoim obszarze`;
    const checkbox3 = `Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od MD Tomasz Antosiak z wykorzystaniem środków komunikacji elektronicznej (m.in. e-mail).`;
    const isPublicPerson = editUser.company_name !== null ? "Firma" : "Osoba publiczna";
    return (
      <React.Fragment>
        <Container className="dashAcc-wrapper">
          <Row>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={2}>
              <ServiceSideNavComponent />
            </Col>
            <Col xs={12} md={8}>
              <form onSubmit={this.handleSubmit}>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <h2 className="dash-h2">Moje konto</h2>
                      <h3 className="dash-h3">
                        {editUser.first_name}
                        <span className="dash-h3-span">Zleceniobiorca ({isPublicPerson})</span>
                      </h3>

                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="registerServiceViewForm-input">
                        <InputComponent
                          error={backendErrors.first_name || (errors.first_name_error && 'Podaj swoje imię')}
                          value={editUser.first_name || ""}

                          onChange={e => { this.onChange("first_name", e.target.value) }} icon={false} placeholder="Imię" />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="registerServiceViewForm-input">
                        <InputComponent
                          error={backendErrors.last_name || (errors.last_name_error && 'Podaj swoje nazwisko')}
                          value={editUser.last_name}
                          onChange={e => { this.onChange("last_name", e.target.value) }} icon={false} placeholder="Nazwisko" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="registerServiceViewForm-input">
                        <InputComponent
                          error={backendErrors.phone || (errors.phone_error && 'Podaj poprawny numer telefonu')}
                          icon={false}
                          value={editUser.phone}
                          placeholder="Numer telefonu"
                          onChange={e => { this.onChange("phone", e.target.value) }} />
                      </div>
                    </Col>

                  </Row>
                  <Row>

                    <Col xs={12} md={6}>
                      <div className="registerServiceViewForm-input">
                        <InputComponent
                          error={backendErrors.password || (errors.password_error && 'Podaj poprawne hasło')}

                          type="password" onChange={e => { this.onChange("password", e.target.value) }} icon={false} placeholder="Hasło" />
                        <span className="registerViewForm">Hasło musi zawierać min. 8 znaków, wielką literę, znak specjalny i cyfrę.</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <h3 className="dash-h3">Adres firmy</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="registerServiceViewForm-input">
                        <InputComponent
                          value={editUser.street}
                          error={backendErrors.street || (errors.street_error && 'Podaj poprawną ulicę')}
                          icon={false}
                          placeholder="Ulica i numer"
                          onChange={e => { this.onChange("street", e.target.value) }} />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="registerServiceViewForm-input">
                        <InputComponent
                          value={editUser.city}
                          error={backendErrors.city || (errors.city_error && 'Podaj poprawną miejscowość')}
                          icon={false}
                          placeholder="Miejscowość"
                          onChange={e => { this.onChange("city", e.target.value) }} />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="registerServiceViewForm-input">
                        <InputComponent
                          value={editUser.postcode}
                          error={backendErrors.postcode || (errors.postcode_error && 'Podaj poprawny kod pocztowy')}
                          icon={false}
                          placeholder="Kod pocztowy"
                          onChange={e => { this.onChange("postcode", e.target.value) }} />
                      </div>
                    </Col>
                    <Col xs={12} md={12}></Col>

                    <Col xs={12} md={6}>
                      <h2 className="dash-h2">Obszar działania</h2>
                      <div className="service-dash-form">
                        <div className="double-form">
                          {/* <LocationInputComponent 
                                onResultSelect={this.onFieldLocationChange}
                                isLocation={true} 
                                error={backendErrors && backendErrors.lat}
                                value={editUser.company_location}
                                icon={false} 
                                placeholder={editUser.company_location? editUser.company_location : "Miejscowość"} /> */}
                          <div className="geocoder-wrapper">
                            <Geocoder
                              {...mapAccess}
                              onSelected={this.onSelected}
                              viewport={viewport}
                              hideOnSelect={true}
                              updateInputOnSelect={true}
                              queryParams={queryParams}
                            />
                            <i aria-hidden="true" class="search icon search-icon"></i>
                          </div>

                        </div>
                        <div className="double-form double-form-right">
                          <Dropdown placeholder="Wybierz dystans"
                            fluid
                            selection
                            options={distanceOptions}
                            value={editUser.radius}
                            defaultValue={editUser.radius}
                            className={(backendErrors.radius || errors.radius_error) && 'dropdown-error'}
                            onChange={(e, { value }) => this.onChange("radius", value)}
                          />
                          {errors.radius_error && <div className="dropdown-error-message-no-margin">Wybierz zasięg działania</div>}
                          {backendErrors.radius && <div className="dropdown-error-message-no-margin">{backendErrors.radius}</div>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="registerServiceViewForm-input">
                        <h2 className="dash-h2">Informacje dodatkowe</h2>

                        <TextareaComponent
                          placeholder="Możesz napisać coś o sobie jeśli chcesz. Ułatwi to wybór Zlecającemu i sprawi, że twój profil będzie dla niego bardziej atrakcyjny."
                          value={editUser.description}
                          onChange={e => this.onChange("description", e.target.value)}
                        />
                        <div className="registerServiceViewForm-information">
                          <span className="registerServiceViewForm-information-accent">
                            Ważne:
                                    </span>
                                    Nie zamieszczaj tutaj swoich danych kontaktowych.
                                </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={12}>
                      <div className="registerUserViewForm-input">
                        <CheckboxComponent
                          checked={editUser.data_processing_1}
                          icon={false}
                          text={checkbox2}
                          onChange={e => this.onChange('data_processing_1', e.target.checked)} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="registerUserViewForm-input">
                        <CheckboxComponent
                          checked={editUser.data_processing_2}
                          icon={false}
                          text={checkbox3}
                          onChange={e => this.onChange('data_processing_2', e.target.checked)} />
                      </div>
                    </Col>
                  </Row>

                  <div className="registerServiceViewForm-buttons">
                    <div className="registerServiceViewForm-button-wrapper">
                      <ButtonComponent type="submit" isOrange={true} isBlock={true} text="Zapisz zmiany" />

                    </div>
                  </div>
                </Container>
              </form>
            </Col>
            <Col xs={12} md={1}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(ServiceDashMyAccView)