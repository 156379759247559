import React, { Component } from 'react';
import './RegisterServiceViewForm.scss';

import { Container, Row, Col } from 'react-grid-system';
import InputComponent from "../../../commons/InputComponent/InputComponent";

class RegisterServicePublicPersonForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { userData, onFieldChange, errors, backendErrors } = this.props

    return (
      <React.Fragment>
        <Container>
            <Row>
                <Col xs={12} md={6} className="registerServiceViewForm-column">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <h3 className="registerService-header-3">Dane osoby</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.country} 
                                        error={backendErrors.country || (errors.country_error && 'Podaj poprawny kraj')} 
                                        icon={false}
                                        placeholder="Kraj" 
                                        onChange={e => {onFieldChange("country", e.target.value)}} />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.street} 
                                        error={backendErrors.street || (errors.street_error && 'Podaj poprawną ulicę')} 
                                        icon={false} 
                                        placeholder="Ulica i numer" 
                                        onChange={e => {onFieldChange("street", e.target.value)}} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                <InputComponent 
                                        value={userData.city} 
                                        error={backendErrors.city || (errors.city_error && 'Podaj poprawną miejscowość')} 
                                        icon={false} 
                                        placeholder="Miejscowość" 
                                        onChange={e => {onFieldChange("city", e.target.value)}} />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.postcode} 
                                        error={backendErrors.postcode || (errors.postcode_error && 'Podaj poprawny kod pocztowy')}
                                        icon={false} 
                                        placeholder="Kod pocztowy" 
                                        onChange={e => {onFieldChange("postcode", e.target.value)}} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default RegisterServicePublicPersonForm;
