import React, { Component } from 'react';
import './ButtonComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

class ButtonComponent extends Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      <React.Fragment>
        <button disabled={this.props.disabled} onClick={this.props.onClick} className={`button 
          button-${this.props.isOrange ? "orange" : ""} 
          button-${this.props.isDark ? "dark" : "light"} 
          button-${this.props.isBlock ? "block" : ""}
          button-${this.props.disabled ? "disabled" : ""}
          button-${this.props.isCenter ? "center" : ""}`
        }>
            {this.props.iconUser ? <FontAwesomeIcon className="button-icon" icon={faUser} /> : ""}
            {this.props.text}
        </button>
      </React.Fragment>
    );
  }
}

export default ButtonComponent;
