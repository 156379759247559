import React, { Component } from 'react';
import { Button, Header, Image, Modal, Col, Container, Row } from 'semantic-ui-react'

import { NotificationManager } from 'react-notifications';
import TextareaComponent from "../../commons/TextareaComponent/TextareaComponent";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { serverURL } from '../../config';
import './RatingModalComponent.scss';



class RatingModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: null,
      description: '',
      open: false,
      backendErrors: {},
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);


  }
 
  onChange(field, value) {
    this.setState({ [field]: value});
    }
    handleRateChange(value) {
        this.setState({ rate: value});
        }
  openModal() {
    this.setState({ open: true })
  } 
  closeModal() {
    this.setState({ open: false })
  } 

  submitRateChange(e) {
    e.preventDefault()
    const userId = this.props.userId;
    const order = this.props.orderId;
    const token = localStorage.getItem('token');
    const rate = { rate: this.state.rate, comment: this.state.description }
    const data = JSON.stringify(rate);
    fetch(`${serverURL}users/${userId}/orders/${order}/rate`, {
        method: 'POST',
        headers: new Headers({
            'Authorization': `bearer ${token}`,
        }),
        body: data
    })
        .then(
          (response) => {
            if (response.ok) {
              NotificationManager.success('Wystawiono ocenę.', 'Gratulacje', 5000)



            } else {
              response.json().then(json => {
               const isRateError = json.error.rate ? "Wybierz ocenę." : "Nie można wystawić oceny dwukrotnie.";
          
              NotificationManager.error(`${isRateError}`, 'Ups!', 5000)

              })
              

            }
          }
        )
        .then(
          this.setState({ open: false })
        )
    }


  render() {
    const isPositive = this.state.rate === 1 ? this.props.userData.positiveOptions + 1 : this.props.userData.positiveOptions;
    const isNegative = this.state.rate === 0 ? this.props.userData.negativeOptions + 1 : this.props.userData.negativeOptions;
    return (
        <Modal
            open={this.state.open}
            trigger={<span onClick={this.openModal}>Wystaw ocenę</span>}
            >

            <Modal.Header>Czy polecasz tego sprawdzającego?</Modal.Header>
            <Modal.Content>
            <form onSubmit={this.submitRateChange}>

                <Modal.Description className="modal-text">
                <Header>{this.props.user} (<span className="green">{isPositive}</span>/<span className="red">{isNegative}</span>)</Header>
                <div className="modal-rating transaction-rating">
                <div className={`rating-up ${this.state.rate === 1 ? 'rate-chosen' : ''}`} onClick={e =>{this.handleRateChange(1)}}>
                <FontAwesomeIcon icon={faThumbsUp} /> 
                </div>
                <div className={`rating-down ${this.state.rate === 0 ? 'rate-chosen' : ''}`} onClick={e =>{this.handleRateChange(0)}}>
                <FontAwesomeIcon icon={faThumbsDown} /> 
                </div>
                </div>
                <TextareaComponent 
                onChange={e => this.onChange('description', e.target.value)}
                placeholder="Możesz dodać coś od siebie jeśli chcesz" />
                </Modal.Description>
                <Button color='black' onClick={this.closeModal}>
                Anuluj
                </Button>
                <Button
                content="Wystaw ocenę"
                labelPosition='right'
                icon='checkmark'
                onClick={e => this.submitRateChange(e, this.props.user, this.props.order)}
                positive
                />
            </form>

            </Modal.Content>
        </Modal>
    );
  }
}

export default RatingModalComponent;