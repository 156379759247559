import React, { Component } from 'react';
import './LoginRegisterView.scss';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
// import LoginRegisterViewForm from "./LoginRegisterViewViewForm/LoginRegisterVViewForm";
import { Container, Row, Col } from 'react-grid-system';
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import RegisterInfoComponent from "../../commons/RegisterInfoComponent/RegisterInfoComponent";
import LoginComponent from '../../components/LoginComponent/LoginComponent';

class LoginRegisterViewView extends Component {
  onFieldChange(name, value) {
    localStorage.setItem('email', value)
  }
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={3} />
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={12}>
                  <h3 className="login-header-3">Zaloguj się</h3>
                </Col>
              </Row>
            <LoginComponent />
            <Row>
              <Col xs={12} md={12}>
                <div className="login-info">
                  Zalogowanie się oznacza akcaptację <span className="login-info-special"><a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Regulamin.pdf" target = "_blank">Regulaminu</a></span> serwisu w aktualnym brzmieniu
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="register-text">
                  <span>Nie masz konta?</span>
                  <Link to="/rejestracja-uzytkownika">Zarejestruj się</Link>
                </div>
              </Col>
            </Row>
            </Col>
            <Col xs={12} md={3} />
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginRegisterViewView);