import React, { Component } from 'react';
import { serverURL } from '../../config';

import { Search} from 'semantic-ui-react';


class LocationInputComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            results: [],
            value: '',
          }
        }


      handleSearchChange(e) {
        e.preventDefault();
        this.setState({value: e.target.value})

        fetch(`${serverURL}localizator?q=${e.target.value}`, {
          method: 'GET',
          headers: new Headers({'content-type': 'application/json'}),
        })
        .then((response) => {
            response.json().then(json => {
                const polishCities = json.filter(city => city.address.country_code === 'pl');
                const filteredCities = polishCities.filter(city => city.importance > 0.38);
                const mappedCities = filteredCities.map((city, i ) => ({ 
                    id: city.place_id,
                    title: city.address.city || city.address.hamlet || city.address.village, 
                    description: 
                    `${city.address.state ? city.address.state : ""} 
                    ${city.address.county ? ', ' + city.address.county : ''}
                    ${city.address.municipality ? ', ' + city.address.municipality : ''}`,
                    value: {title: city.address.city || city.address.hamlet || city.address.village, lat: city.lat, lon: city.lon},

                }))
                this.setState({results: mappedCities})

              })
       
        })
      }

      handleLocationOption(value){
        if (this.props.isLocation) {
         this.props.onResultSelect(value.result, 'company_location')
        } else{
          this.props.onResultSelect(value.result, 'city')
         }
      }

  render() {
    const { loading, results, value } = this.state;
   return (
      <React.Fragment>
        <Search
            loading={loading}
            onResultSelect={(e, data) =>
                this.handleLocationOption(data)
              }
            onSearchChange={e => this.handleSearchChange(e)}
            results={results}
            placeholder={this.props.placeholder}
            value={value}
        />
    </React.Fragment>
    );
  }
}

export default LocationInputComponent;