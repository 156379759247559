import React, { useState } from 'react';
import './LoginComponent.scss';
import { Container, Row, Col } from 'react-grid-system';
import { NotificationManager } from 'react-notifications';
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import CheckboxComponent from "../../commons/CheckboxComponent/CheckboxComponent";
import { withRouter } from 'react-router-dom';
import { serverURL } from '../../config';
import { useStateValue } from '../../state/state'
import { NavLink, Link } from "react-router-dom";


function LoginComponent(props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [, dispatch] = useStateValue()
  let checkbox1 = "Zapamiętaj mnie"

  const getUserData = (token) => {
    fetch(`${serverURL}users/me`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `bearer ${token}`, 
      }),
    })
    .then(
      response => { 
        response.json().then(json => {
          const isCustomer = Object.values(json.roles).includes("ROLE_CUSTOMER");
          const isService = Object.values(json.roles).includes("ROLE_CONTRACTOR");
          if (!isService) {
            localStorage.setItem('role', "0")
          }
          if (isService) {
            localStorage.setItem('role', "1")
          }
          localStorage.setItem('id', json.id)
          localStorage.setItem('first_name', json.first_name)
          localStorage.setItem('last_name', json.first_name)
          localStorage.setItem('company_city', json.company_location)
          localStorage.setItem('radius', json.radius)
          dispatch({ type: 'setData', field: 'first_name', data: json.first_name })
          dispatch({ type: 'setData', field: 'role', data: isCustomer ? "0" : "1" })

        })
      }
    )
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(`${serverURL}login_check`, {
      method: 'POST',
      headers: new Headers({'content-type': 'application/json'}),
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
    .then((response) => {
      if (response.ok) {
        response.json().then(json => {
          const token = json.token
          localStorage.removeItem('role');
          localStorage.setItem('token', token)
          localStorage.setItem('refresh_token', json.refresh_token)
          NotificationManager.success('Uśmiechnij się :)', 'Miło Cię widzieć!', 5000);
          props.history.push("/moje-zlecenia");
          getUserData(token)
          const tokenExpirationTimestamp = new Date().getTime() + 10800000
          localStorage.setItem('token_expiration_timestamp', tokenExpirationTimestamp)
          dispatch({ type: 'setData', field: 'isLoggedIn', data: true })
          const isOrderAv = localStorage.getItem('newOrder');

          if (isOrderAv) {
            fetch(`${serverURL}orders`, {
              method: 'POST',
              headers: new Headers({'Authorization': `bearer ${token}`, 'content-type': 'application/json'}),
              body: isOrderAv,
            })
            .then((response) => {
              NotificationManager.success('Twoje ogłoszenie zostało dodane do wyceny.', 'Zapisano!', 5000);
              localStorage.removeItem('newOrder');
              window.location.reload(false)
            })
          }
        })
      } else {
        response.json().then(json => {
          setErrorMessage("Nieprawidłowe dane logowania lub nieaktywne konto.")
        })
      }
    })
    .catch((error) => {
      if (error instanceof Error) {
        return { error }
      }
      return error.json().then((responseJson) => {
        return {
          error: new Error(
            `HTTP ${error.status} ${error.statusText}: ${responseJson.msg}`
          )
        }
      })
    })
  }

  const status = (res) => {
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    return res;
  }

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <Container>
        <Row>
            <Col xs={12} md={12}>
                <div className="login-input">
                    <InputComponent onChange={e => setEmail(e.target.value)} icon={false} placeholder="Adres e-mail" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={12}>
                <div className="login-input">
                    <InputComponent type="password" onChange={e => setPassword(e.target.value)} icon={false} placeholder="Hasło" />
                </div>
            </Col>
        </Row>
        {
            errorMessage && <Row>
                <Col xs={12} md={12}>
                    <div className="login-error-message">{errorMessage}</div>
                </Col>
            </Row>
        }
        <Row>
            <Col xs={12} md={6}>
                <div className="login-input">
                    <CheckboxComponent icon={false} text={checkbox1} />
                </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="login-link">
                  <Link to="/zresetuj-haslo">Nie pamiętam hasła</Link>
              </div>
            </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="login-buttons">
              <div className="login-button-wrapper">
                <ButtonComponent isOrange={true} isBlock={true} isCenter={true} text="Zaloguj się" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  )
}

export default withRouter(LoginComponent)