import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom"
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-notifications/lib/notifications.css';

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { NotificationContainer } from 'react-notifications';
import moment from 'moment';

import {Helmet} from "react-helmet";
import { Switch, Route, withRouter } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import NavbarComponent from "./components/NavbarComponent/NavbarComponent";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import HomeView from './views/HomeView/HomeView';
import LoginView from './views/LoginView/LoginView';
import RegisterUserView from './views/RegisterUserView/RegisterUserView';
import RegisterServiceView from './views/RegisterServiceView/RegisterServiceView';
import RegisterView from './views/RegisterView/RegisterView';
import CreateOrderView from './views/CreateOrderView/CreateOrderView';
import LoginRegisterView from './views/LoginRegisterView/LoginRegisterView';
import ContactView from './views/ContactView/ContactView';
import FindOrderLoginView from './views/FindOrderLoginView/FindOrderLoginView';
import UserDashOrdersView from './views/UserDashView/UserDashOrdersView';
import ServiceDashOrdersView from './views/ServiceDashView/ServiceDashOrdersView';
import UserDashAccView from './views/UserDashView/UserDashAccView';
import ServiceDashAccView from './views/ServiceDashView/ServiceDashAccView';
import ServiceDashOffersView from './views/ServiceDashView/ServiceDashOffersView';
import ServiceDashMyOrdersView from './views/ServiceDashView/ServiceDashMyOrdersView';
import AllOrdersView from './views/AllOrdersView/AllOrdersView';
import OrderView from './views/OrderView/OrderView';
import TransactionView from './views/TransactionView/TransactionView';
import ProfileView from './views/ProfileView/ProfileView';
import NotFound from './views/NotFound/NotFound';
import ResetPasswordView from './views/ResetPasswordView/ResetPasswordView';
import ResetPasswordLastStepView from './views/ResetPasswordLastStepView/ResetPasswordLastStepView';


import { useStateValue } from './state/state'

import CreateOfferView from './views/CreateOfferView/CreateOfferView';
import { serverURL } from './config';

let params = new URLSearchParams(document.location.search.substring(1));
const middleware = [ thunk ]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger())
}

const store = createStore(
  applyMiddleware(...middleware)
)

const ScrollToTop = () =>{
  const { pathname, hash } = useLocation()
  useEffect(() => {
    if (!hash) return
    if (window.location.search.includes('?sort')) return
    window.scrollTo(0, 0)
  }, [pathname, hash])

  !hash && setTimeout(() => { // timeout needed to override default back button behavior
    if (window.location.search.includes('?sort')) return
    window.scrollTo(0, 0)
  }, 0)
  return null
}

const checkTokenValidity = () => {
  setInterval(
    () => {
      const refreshToken = localStorage.getItem('refresh_token')
      const token = localStorage.getItem('token')

      const tokenExpirationTimestamp = parseInt(localStorage.getItem('token_expiration_timestamp'))
      const currentTimestamp = new Date();
      const isTokenValid = moment(currentTimestamp).isSameOrBefore(tokenExpirationTimestamp);
      if (!refreshToken) {
        return
      }
      else if (isTokenValid) {
        fetch(`${serverURL}users/me`, {
          method: 'GET',
          headers: new Headers({
            'Authorization': `bearer ${token}`, 
          }),
        })
          .then(
              response => {
                  response.json().then(data => {
                    
                    const userRoles = data.roles
                    const isCustomer = Object.values(userRoles).includes("ROLE_CUSTOMER");
                    const isService = Object.values(userRoles).includes("ROLE_CONTRACTOR");
                    localStorage.setItem('radius', data.radius)
                    localStorage.setItem('company_location', data.company_location)


                    if (!isService) {
                      localStorage.setItem('role', "0")
                    }
                    if (isService) {
                      localStorage.setItem('role', "1")
                    }
                  })
              }
          )
      }
      else {
        fetch(`${serverURL}token/refresh`, {
          method: 'POST',
          headers: new Headers({'content-type': 'application/json'}),
          body: JSON.stringify({
            refresh_token: refreshToken
          }),
        })
        .then(
          (response) => {
            if (response.ok) {
              response.json().then(json => {
                localStorage.setItem('token', json.token)
                
                localStorage.setItem('refresh_token', json.refresh_token)
                const tokenExpirationTimestamp = new Date().getTime() + 60000
                localStorage.setItem('token_expiration_timestamp', tokenExpirationTimestamp)
              })
            } else {
              localStorage.clear();
              window.location.href = "http://www.motodetektyw.pl";

            }
          }

        )
        
      }
    }, 10000
  )
}

function App() {
  const [ { role } ] = useStateValue()
const [ userRole, setUserRole ] = useState(null);

  const getUser = () => {
    const currentUserRoles = localStorage.getItem("role") || "";
    setUserRole(currentUserRoles)
  }
  
  useEffect(() => {
    checkTokenValidity()
  }, [])

  useEffect(() => {
    getUser();
  }, [role])

  return (
    <div className="App">
        <Provider store={store}>
          <Router>
            <ScrollToTop />
            <NavbarComponent />
            <div className="components-main-component">
              <Helmet>
                <title>MotoDetektyw.pl – Twój Partner w Zakupie Pojazdów</title>
                <meta name="description" content="Chcesz sprawdzić pojazd przed zakupem? MotoDetektyw.pl to właściwy adres! Dodaj zlecenie, porównaj oferty inspekcji i wybierz najlepszą." />
              </Helmet>
              <React.Fragment>
                <Switch>
                  <Route exact path="/" component={HomeView} />
                  <Route exact path="/rejestracja" component={RegisterView} />
                  <Route exact path="/rejestracja-uzytkownika" component={RegisterUserView} />
                  <Route exact path="/rejestracja-sprawdzajacego" component={RegisterServiceView} />
                  <Route exact path="/zaloguj/konto-aktywowane" component={LoginView} />
                  <Route exact path="/zaloguj" component={LoginView} />
                  <Route exact path="/zaloguj-sie" component={LoginRegisterView} />
                  <Route exact path="/zlec-sprawdzenie" component={CreateOrderView} />
                  <Route exact path="/kontakt" component={ContactView} />
                  <Route exact path="/znajdz-zlecenie-login" component={FindOrderLoginView} />
                  <Route exact path="/oferty" component={ServiceDashOffersView} />
                  <Route exact path="/zlecenia" component={ServiceDashMyOrdersView} />
                  <Route exact path="/lista-zlecen" component={AllOrdersView} />
                  <Route exact path="/zlecenie/:orderId" component={OrderView} />
                  <Route exact path="/transakcja/:orderId" component={TransactionView} />
                  <Route exact path="/uzytkownik/:userId" component={ProfileView} />
                  <Route exact path="/zresetuj-haslo" component={ResetPasswordView} />
                  <Route path="/reset-hasla-token" component={ResetPasswordLastStepView} />
                  <Route exact path="/utworz-oferte/:orderId" component={CreateOfferView} />
                  {userRole === "1" 
                    ? <Route exact path="/moje-zlecenia" component={ServiceDashOrdersView} />
                    : <Route exact path="/moje-zlecenia" component={UserDashOrdersView} />
                  }
                  {userRole === "1"
                  ? <Route exact path="/moje-konto" component={ServiceDashAccView} />
                  : <Route exact path="/moje-konto" component={UserDashAccView} />
                }
                <Route component={NotFound} />
                </Switch>
                <NotificationContainer />
              </React.Fragment>
            </div>
            <FooterComponent />
          </Router>
        </Provider>
    </div>
  );
}

export default App;
