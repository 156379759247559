import React, { Component } from 'react';
import './TransactionView.scss';

import { withRouter } from 'react-router-dom';
import { serverURL } from '../../config';
import moment from 'moment';
import timestampFormat from '../../utils/helpers/timestampFormat';
import { Link } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import RatingModalComponent from '../../components/RatingModalComponent/RatingModalComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { Container, Row, Col } from 'react-grid-system';

import OrderDetailsComponent from "../../components/OrderDetailsComponent/OrderDetailsComponent";
import OrderInfoComponent from '../../components/OrderInfoComponent/OrderInfoComponent';


class TransactionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        orderData: {
          "id": null,
          "status": null,
          "name":"-",
          "description": "-",
          "inspectDescription":"-",
          "isFullInspection":false,
          "important":false,
          "expires":"",
          "vin": "-",
          "details_url":"-",
          "postcode":"-",
          "city":"-",
          "street":"-",
          "year":"-",
          "user":{
            "id":null,
            "email":"",
            "roles":[],
            "status":null,
            "first_name":"-",
            "last_name":"-",
            "introduced_by":null
          },
          "model":{
            "id":null,
            "make":{
              "id":null,
              "name":"-"
            },
            "name":"-"
          },
          "acceptedOffer": {
            "user": {
              "first_name": "-",
              "last_name": "-",
            },
          },
          "createdTimestamp":null,
          "expiresTimestamp":null
        },
        offersList: []
    };
  }

  componentWillMount() {
    const token = localStorage.getItem('token');
    const pathArray = window.location.pathname.split('/');
    const orderId = pathArray[2];
    
    fetch(`${serverURL}orders/${orderId}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `bearer ${token}`,
        }),
    })
        .then(
          (response) => {
            if (response.ok) {
              response.json().then(data => {
                  this.setState({orderData: data})
              })
            } else {
              this.props.history.push(`/zaloguj`);
            }
        
            }
        )
  }
  
  render() {
    const userRole = localStorage.getItem('role');
    const myId = localStorage.getItem('id');

    const isCustomer = userRole === "0";
    const isService = userRole.includes("1");

    const {orderData, offersList} = this.state;
    const offersNumber = offersList.length;
    const creatorHelper = orderData.acceptedOffer ? orderData.acceptedOffer.user.last_name.substring(0, 1) : '';
    const creator =  orderData.acceptedOffer ? orderData.acceptedOffer.user.first_name + " " + creatorHelper + "." : '';
    const nettoPrice = (orderData.acceptedOffer.price / 1.23).toFixed(2)
    const expiredDate = timestampFormat(orderData.expiresTimestamp);
    const isMyOffer = orderData.acceptedOffer && orderData.acceptedOffer.user.id === myId;

    const acceptedOfferCreatedTimestamp = orderData.acceptedOffer ? moment.unix(orderData.acceptedOffer.createdAt) : false;
    const chosenOfferDate = acceptedOfferCreatedTimestamp
      ? acceptedOfferCreatedTimestamp.add(orderData.acceptedOffer.acceptedNumber-1, 'days').format("DD MMM")
      : 'Nie wybrano oferty.' 

    return (
      <React.Fragment>
        <Container>
          <Row>
          <Col className="transactionView-column" xs={12} md={1}></Col>
            <Col className="transactionView-column" xs={12} md={10}>
              <div className="transactionView-column-inner">
                <h2 className="transactionView-title">{orderData.name}</h2>
                {!isService ? <Container className="orderDetails-wrapper">
                <Row>
                  <div className="orderDetails-header header-customer">
            
                        <Col xs={12}>Wybrany termin inspekcji: <span className="title">{chosenOfferDate}</span></Col>
          
                  </div>
                </Row>
              </Container> 
              : <OrderDetailsComponent order={orderData} offers={offersNumber} />}
                <OrderInfoComponent order={orderData} />
              </div>
            </Col>
            <Col className="transactionView-column" xs={12} md={1}></Col>
          </Row>
          <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={10}>
            <h3 className="transactionView-title">Informacje dodatkowe</h3>
              <div className="transactionView-description">
              {orderData.description ? orderData.description : "-"}
              
              </div>
            </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={10} className="transactionView-zakres">
              <h3 className="transactionView-title">Zakres inspekcji</h3>
              <span className="transactionView-span">
              {orderData.isFullInspection ? 
                <span>Kompleksowa - pełne sprawdzenie pojazdu. <a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Raport.pdf" target="_blank">Wzór raportu</a>.</span> :
                "Indywidualna - zobacz wybrany zakres" 
                
              }
              </span>
              <div className="transactionView-description">{orderData.inspectDescription}</div>
            </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={10} className="transactionView-zakres">
            { orderData.acceptedOffer ? <h3 className="transactionView-title">Wybrana oferta</h3> : ""}
            </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>
            <React.Fragment>
          <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={5}>
            { orderData.acceptedOffer ?
            <div className="transactionView-down transactionView-downleft">
              <div className="transactionView-price-left">
                Sprawdzający
                </div>
                <div className="transactionView-price-right">
                <div className="trans-wrapper">
                <Link to={`/uzytkownik/${orderData.acceptedOffer.user.id}`}>{creator}</Link>
                <div className="transaction-rating">
                <div className="rating-up" >
                <FontAwesomeIcon icon={faThumbsUp} /> 
                {orderData.acceptedOffer.user.positiveOptions}
                </div>
                <div className="rating-down" >
                <FontAwesomeIcon icon={faThumbsDown} /> 
                {orderData.acceptedOffer.user.negativeOptions}
                </div>
                 {isCustomer ? <span  className="userOrdersList-button pointer-element"><RatingModalComponent orderId={orderData.id} user={creator} userId={orderData.acceptedOffer.user.id} userData={orderData.user} /></span> : ""}
                </div>
                </div>
              
                </div>
            </div> : "" }
            { orderData.acceptedOffer ?
            <div className="transactionView-down transactionView-downleft">
              <div className="transactionView-price-left">
                Termin
                </div>
                <div className="transactionView-price-right">
                {chosenOfferDate}
                </div>
            </div>
            : ""}
            { orderData.acceptedOffer ?
            <div className="transactionView-down transactionView-downleft">
              <div className="transactionView-price-left">
                Informacje dodatkowe
                </div>
                <div className="transactionView-price-right">
                {orderData.acceptedOffer ? orderData.acceptedOffer.description : "" }
                </div>
            </div>
            : ""}
            </Col>
            <Col xs={12} md={5}>
            {orderData.acceptedOffer ? 
            <div className="transactionView-price-right">
            <Container className="pricebox">
            <Row className="pricebox-top">
                <Col xs={6} className="pricebox-left accent">Cena: </Col>
                <Col xs={6} className="pricebox-right">
                    <div className="pricebox-price">
                        {orderData.acceptedOffer.price}
                        <span className="pricebox-span">zł</span>
                    </div>
                    
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="strong">Faktura </Col>
                <Col xs={6} className="pricebox-right">
                    {orderData.acceptedOffer.vat === 0 ? "Tak (23%)" : ""}
                    {orderData.acceptedOffer.vat === 1 ? "Tak" : ""}
                    {orderData.acceptedOffer.vat === 2 ? "Nie" : ""}
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="strong">Cena netto: </Col>
                <Col xs={6} className="pricebox-right">
                    <div className="strong">{orderData.acceptedOffer.vat === 0 ? nettoPrice : ""}
                    {orderData.acceptedOffer.vat === 1 ? orderData.acceptedOffer.price : ""}
                    {orderData.acceptedOffer.vat === 2 ? "-" : ""}zł</div>
                </Col>
            </Row>
        </Container>
            </div>
    : "" }
            </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={10}> 
              <h3 className="transactionView-title">{orderData.user.id === parseInt(myId) ? 'Dane sprawdzającego' : 'Dane zlecającego'}</h3>
            </Col>
            <Col xs={12} md={1}></Col>
          </Row>

          <Row>
          <Col xs={12} md={1}>
          </Col>
          <Col xs={12} md={10} className="transactionView-zakres">
          {!isCustomer && isMyOffer ? <h3 className="transactionView-title">{!isCustomer ? "Dane zlecającego" : "Dane sprawdzającego"}</h3> : "" }
          </Col>
          <Col xs={12} md={1}>
          </Col>
        </Row>
        {!isCustomer && isMyOffer ?
          <Row className="transaction-last">
          <Col xs={12} md={1}>
        </Col>
        <Col xs={12} md={5}>
        <div className="transactionView-down transactionView-downleft">
        <div className="transactionView-price-left">
          Imię i nazwisko
          </div>
          <div className="transactionView-price-right">
          {orderData.user.first_name} {orderData.user.last_name}
          </div>
      </div>
        <div className="transactionView-down transactionView-downleft">
          <div className="transactionView-price-left">
            E-mail
            </div>
            <div className="transactionView-price-right">
            {orderData.user.email}
            </div>
        </div>
        <div className="transactionView-down transactionView-downleft">
          <div className="transactionView-price-left">
            Telefon
            </div>
            <div className="transactionView-price-right">
            {orderData.user.phone}
            </div>
        </div>
        </Col>

        </Row>
          : orderData.acceptedOffer ? <Row className="transaction-last">
        <Col xs={12} md={1}></Col>
        <Col xs={12} md={5}>
        <div className="transactionView-down transactionView-downleft">
          <div className="transactionView-price-left">
            {orderData.user.id === parseInt(myId) ? 'Sprawdzający' : 'Zlecający'}
            </div>
            <div className="transactionView-price-right">
            {/* {orderData.acceptedOffer.user.company_name ? orderData.acceptedOffer.user.company_name : `${orderData.acceptedOffer.user.first_name} ${orderData.acceptedOffer.user.last_name}`} */}
            {/* { orderData.user.company_name ? orderData.user.company_name : `${orderData.user.first_name} ${orderData.user.last_name}`} */}
              {orderData.user.id === parseInt(myId) ? (orderData.acceptedOffer.user.company_name || `${orderData.acceptedOffer.user.first_name} ${orderData.acceptedOffer.user.last_name}`) : `${orderData.user.first_name} ${orderData.user.last_name}`}
            </div>
        </div>
        <div className="transactionView-down transactionView-downleft">
          <div className="transactionView-price-left">
            E-mail
            </div>
            <div className="transactionView-price-right">
            {/* {orderData.user.email} */}
            {orderData.user.id === parseInt(myId) ? orderData.acceptedOffer.user.email : orderData.user.email}
            </div>
        </div>
        <div className="transactionView-down transactionView-downleft">
          <div className="transactionView-price-left">
            Telefon
            </div>
            <div className="transactionView-price-right">
            {/* {orderData.user.phone} */}
            {orderData.user.id === parseInt(myId) ? orderData.acceptedOffer.user.phone : orderData.user.phone}
            </div>
        </div>
        </Col> 
        <Col xs={12} md={5}>
        <div className="transactionView-down transactionView-downleft trans-adres">
          <div className="transactionView-price-left">
            Adres
            </div>
            <div className="transactionView-price-right">
              {/* {orderData.user.street} <br />
              {orderData.user.city} */}
              {orderData.user.id === parseInt(myId) ? orderData.acceptedOffer.user.street : orderData.user.street} <br />
              {orderData.user.id === parseInt(myId) ? orderData.acceptedOffer.user.city : orderData.user.city}
            </div>
        </div>
        <div className="transactionView-down transactionView-downleft">
          <div className="transactionView-price-left">
              {orderData.user.id === parseInt(myId) && 'NIP'}
            </div>
            <div className="transactionView-price-right">
              {orderData.user.id === parseInt(myId) && orderData.acceptedOffer.user.company_nip}
            </div>
        </div>
        </Col>
        <Col xs={12} md={1}>
        </Col>
      </Row> : "" }
          
       </React.Fragment>
                 
               </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(TransactionView);