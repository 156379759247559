import React, { Component} from 'react';
import './RadioButtonComponent.scss';

import { ReactComponent as Auto } from '../../assets/autko.svg';
import { ReactComponent as Motocykl } from '../../assets/motorek.svg';

class RadioButtonComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (
      <React.Fragment>
        <div className={`radioButton-wrapper 
        radioButton-${this.props.isDisabled ? "disabled" : ""}`
        }>
        <label>
            <input 
              className="radioButton" 
              type="radio"
              name={this.props.name}
              value={this.props.value}
              checked={this.props.checked}
              onChange={this.props.onChange}
              disabled={this.props.isDisabled}
            />
            <div className="radioButton-label" >
                <div className={`${this.props.icon ? "radioButton-icon-wrapper" : ""}`}>
                  {this.props.icon === "auto" ? <Auto /> : ''}
                  {this.props.icon ==="moto" ? <Motocykl /> : ''}
                </div>
                <div className="radioButton-text">{this.props.text}</div>
            </div>
        </label>
        </div>
      </React.Fragment>
    );
  }
}

export default RadioButtonComponent;
