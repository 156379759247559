import React, { Component} from 'react';
import './JoinComponent.scss';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from "react-router-dom";

import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import { ReactComponent as Diagnosta } from '../../assets/dolacz.svg';

class JoinComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (
      <React.Fragment>
        <div className="join-wrapper">
          <Container className="join-container">
            <Row>
              <Col className="join-column" xs={12} md={5}>
                  <div className="join-img-background">
                    <Diagnosta className="join-img" />
                  </div>
              </Col>
              <Col className="join-column join-column-right" xs={12} md={5}>
                <div className="join-content-wrapper">
                  <div className="join-text">
                    <div className="join-message">
                      Twoją pasją jest motoryzacja? 
                      Posiadasz wiedzę oraz doświadczenie w sprawdzaniu pojazdów?
                    </div>
                    <div className="join-message-special"> Dołącz do nas!</div>
                  </div>
                  <div className="join-buttons">
                    <Link to ="/rejestracja-sprawdzajacego">
                      <ButtonComponent isDark={true} text="Rejestracja" iconUser={true}/>
                    </Link>
                    <ButtonComponent text="Czytaj więcej" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default JoinComponent;
