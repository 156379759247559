import React, { Component } from 'react';
import './OrderCheckupComponent.scss';

import OrderCheckupForm from "./OrderCheckupFormComponent/OrderCheckupFormComponent";

import { Container, Row, Col } from 'react-grid-system';

import { ReactComponent as Stacja } from '../../assets/stacja.svg';


class OrderCheckupComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <div className="orderCheckup-wrapper">
          <Container className="orderCheckup-container">
            <Row>
              <Col xs={12} md={7} className="orderCheckup-left-wrapper">
                <div className="orderCheckup-wrapper-inner">
                  <div className="orderCheckup-text-container">
                    <h2 className="orderCheckup-header">
                      Chcesz <span className="orderCheckup-text-special">sprawdzić pojazd</span> przed zakupem?
                    </h2>
                    <h3 className="orderCheckup-header-3">
                      <span className="orderCheckup-text-special">Moto</span>
                      <span className="orderCheckup-text-accent">Detektyw</span>
                      to właściwy adres!
                    </h3>
                    <h4 className="orderCheckup-header-4">Dodaj zlecenie, porównaj oferty inspekcji i wybierz najlepszą</h4>
                    <div className="orderCheckup-header-subtitle">Gdzie znajduje się pojazd?</div>
                  </div>
                  <div className="orderCheckup-form-wrapper">
                    <div className="orderCheckup-button-wrapper">
                      <OrderCheckupForm />
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="orderCheckup-column" xs={12} md={5}>
                <Stacja className="orderCheckup-img" />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderCheckupComponent;
