import React, { Component } from 'react';
import './InstructionComponent.scss';
import { Container, Row, Col } from 'react-grid-system';
import { ReactComponent as Krok1 } from '../../assets/krok1.svg';
import { ReactComponent as Krok2 } from '../../assets/krok2.svg';
import { ReactComponent as Krok3 } from '../../assets/krok3.svg';
import { ReactComponent as Krok4 } from '../../assets/krok4.svg';

class InstructionComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Container className="instruction-container">
          <Row>
            <h3 className="instruction-header">
            Zobacz jakie to łatwe
            </h3>
          </Row>
          <Row>
            <Col className="instruction-step step-1" xs={12} md={3}>
              <Krok1 />
              <div className="instruction-text">Wstaw zlecenie <span className="instruction-text-special">za darmo</span></div>
            </Col>
            <Col className="instruction-step step-2" xs={12} md={3}>
              <Krok2 />
              <div className="instruction-text">Otrzymasz oferty sprawdzenia</div>
            </Col>
            <Col className="instruction-step step-3" xs={12} md={3}>
              <Krok3 />
              <div className="instruction-text">Wybierz najlepszą z nich</div>
            </Col>
            <Col className="instruction-step" xs={12} md={3}>
              <Krok4 />
              <div className="instruction-text">Odbierz raport pojazdu</div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default InstructionComponent;
