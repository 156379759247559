import { faEnvelope, faPhone, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/pl';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import RadioButtonComponent from "../../commons/RadioButtonComponent/RadioButtonComponent";

import './ProfileView.scss';



class RatingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "all",
    };

  }
  
  onChange(field, value) {
    this.setState({[field]: value });

}

  render() {
      const commentsList = this.props.ratings;
      const { sort } = this.state;
      const filteredList = commentsList.filter(comment => {
        if (sort !==  "all"){
          return comment.rate === sort
         
        } else {
          return comment
        }
      })
       const commentList = filteredList.map((comment, i) => {
        const expiredDate = moment.utc(comment.created_at);
        const formatDate = moment.unix(comment.created_at).format("DD.MM.YYYY");
            return (
              <React.Fragment>
                <Row key={i} className={`commentsList-comment comment-${commentsList.length - 1 === i ? 'last' : 'basic'}`}>
                  <Col xs={12} md={3} className="comments-element comments-element-left"> 
                  <div className="rating">
                  {comment.rate ?
                    <div className="rating-up">
                      <FontAwesomeIcon icon={faThumbsUp} /> 
                      
                    </div> :
                    <div className="rating-down">
                      <FontAwesomeIcon icon={faThumbsDown} /> 
                      
                    </div>}
                  </div>
                  <div className="rating-user">
                  {comment.ratingByUser}
                  <div className='commentDate'>
                  <span className="rating-date">{formatDate}</span>
                  </div>
                  </div>
                  </Col>

                  <Col xs={12} md={9} className="comments-element comments-element-right">{comment.comment ? comment.comment : <span className="nocomment">Brak komentarza</span>}
                  <div className="comment-brand">{comment.model}</div>
                  </Col>
            
                </Row>
              </React.Fragment>
            )
        }); 
    return (
      <React.Fragment>
      <Container className="commentsList-container">
        <div className="comment-sort">
            <div className="comment-sort-all">
            <RadioButtonComponent 
                                  checked={sort === "all"} 
                                  icon={false} 
                                  text="Wszystkie"
                                  onChange={e => this.onChange('sort', "all")} />
            </div>
          <div className="comment-sort-left">
          <RadioButtonComponent 
                                checked={sort === true} 
                                icon={false} 
                                text="Polecam"
                                onChange={e => this.onChange('sort', true)} />
          </div>
          <div className="comment-sort-right">
            <RadioButtonComponent 
            checked={sort=== false} 
            icon={false} 
            text=" Nie polecam"
            onChange={e => this.onChange('sort', false)} />
          </div>

        </div>
      </Container>
        <Container className="commentsList-container">
            <Row>
                <Col xs={12} md={3} className="commentsList-header commentsList-header-1">Ocena</Col>
                <Col xs={12} md={9} className="commentsList-header commentsList-header-6">Komentarz </Col>
            </Row>
            {commentList}
        </Container>
      </React.Fragment>
    );
  }
}

export default RatingView;