import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './SideNavComponent.scss';

class ServiceSideNavComponent extends Component {
  render() {
    const pathArray = window.location.pathname.split('/');
    const pathLoc = pathArray[1];
    const creatorData = localStorage.getItem("id")
    return (
      <React.Fragment>
        <div className="userSideNav-menu">
        <div className="userSideNav-button-wrapper">
        <Link to="/moje-zlecenia">
          <div
            className={`userSideNav-button 
              userSideNav-button-${pathLoc === 'moje-zlecenia' ? "active" : ""}` 
            }>
            Moje zlecenia
          </div>
        </Link>
    </div>
            <div className="userSideNav-button-wrapper">
                <Link to="/zlecenia">                  <div
                className={`userSideNav-button 
                  userSideNav-button-${pathLoc === 'zlecenia' ? "active" : ""}` 
                }>Zlecenia</div></Link>
            </div>
            <div className="userSideNav-button-wrapper">
                <Link to="/oferty">                  <div
                className={`userSideNav-button 
                  userSideNav-button-${pathLoc === 'oferty' ? "active" : ""}` 
                }>Złożone oferty</div></Link>
            </div>
            <div className="userSideNav-button-wrapper">
                <Link to="/moje-konto">                  <div
                className={`userSideNav-button 
                  userSideNav-button-${pathLoc === 'moje-konto' ? "active" : ""}` 
                }>Konto</div></Link>
            </div>
            <div className="userSideNav-button-wrapper">
            <Link to={`/uzytkownik/${creatorData}`}>                 <div
            className={`userSideNav-button 
              userSideNav-button-${pathLoc === '/uzytkownik' ? "active" : ""}` 
            }>Moje oceny</div></Link>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceSideNavComponent;
