import React, { Component } from 'react';
import './HomeView.scss';

import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';

import OrderCheckupComponent from "../../components/OrderCheckupComponent/OrderCheckupComponent";
import ProsComponent from "../../components/ProsComponent/ProsComponent";
import InstructionComponent from "../../components/InstructionComponent/InstructionComponent";
import VideoComponent from "../../components/VideoComponent/VideoComponent";
import JoinComponent from "../../components/JoinComponent/JoinComponent";

class HomeView extends Component {
  render() {
    return (
      <React.Fragment>
        <OrderCheckupComponent />
        <React.Fragment>
        <div className="homeBlue-wrapper">
          <Container className="homeBlue-container">
            <Row>
              <Col xs={12} md={7} className="homeBlue-left-wrapper">
              </Col>
              <Col className="homeBlue-column" xs={12} md={5}>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
        <ProsComponent />
        <InstructionComponent />
        <VideoComponent />
        <JoinComponent />
      </React.Fragment>
    );
  }
}

export default withRouter(HomeView);
