import React, { Component } from 'react';
import './LoginView.scss';

import { Container, Row, Col } from 'react-grid-system';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import LoginComponent from '../../components/LoginComponent/LoginComponent';
import { NotificationManager } from 'react-notifications';


class LoginView extends Component {

  componentDidMount() {
    if (this.props.location.pathname === "/zaloguj/konto-aktywowane") {
      setTimeout(() => {
        NotificationManager.success('Twoje konto zostalo aktywowane.', 'Gratulacje', 5000)
      }, 0)
      this.props.history.push("/zaloguj")
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="login-container">
            <Container>
              <Row>
                <Col xs={12} md={4}></Col>
                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={12}>
                      <h2 className="login-header">Zaloguj się</h2>
                    </Col>
                  </Row>
                  <LoginComponent />
                </Col>
                <Col xs={12} md={4}></Col>
              </Row>
            </Container>
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <div className="login-info">
                  Zalogowanie się oznacza akcaptację <span className="login-info-special"><a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Regulamin.pdf" target = "_blank">Regulaminu</a></span> serwisu w aktualnym brzmieniu
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
          <Row>
            <Col xs={12} md={12}>
              <h3 className="login-header-3">Nie masz konta? 
                <Link to="/rejestracja">
                  <span className="login-info-special">
                    Zarejestruj się
                  </span>
                </Link>
              </h3>
            </Col>
          </Row>
        </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(LoginView)