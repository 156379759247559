import React, { Component } from 'react';
import './ContactView.scss';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import { NotificationManager } from 'react-notifications';
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import TextareaComponent from "../../commons/TextareaComponent/TextareaComponent";
import CheckboxComponent from "../../commons/CheckboxComponent/CheckboxComponent";
import { serverURL } from '../../config';


class ContactView extends Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.onChange= this.onChange.bind(this);
      this.state = {
        subject: '',
        body: '',
        fullname: '',
        email: '',
        errorMessage: '',
        terms_of_use: false,
        terms_of_use_error: false,
      }
    }
  onChange(e, field) {
    this.setState({ [field]: e.target.value });
  }

  onCheckboxChange(field, value) {
    this.setState({ [field]: value });
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({ terms_of_use_error: !this.state.terms_of_use })
    if (!this.state.terms_of_use) return

    fetch(`${serverURL}/contact`, {
      method: 'POST',
      body: JSON.stringify({
        subject: this.state.subject,
        body: this.state.body,
        fullname: this.state.fullname,
        email: this.state.email,
        terms_of_use: this.state.terms_of_use
      }),
    })
    .then(
      response => { 
        if (response.ok) {
          NotificationManager.success('Twoja wiadomość została wysłana.', 'Sukces!', 5000);
        } else {
          response.json().then(json => {
            this.setState({errorMessage: json.message})
          })
        }
      }
    )
  }
  render() {
    
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className="contactView-header-big contactView-header">Pomoc/Kontakt</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}></Col>
            <Col xs={12} md={6}>
              <form className="contactView-form" onSubmit={this.handleSubmit}> 
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <Container>
                        <Row>
                          <Col xs={12} md={12}>
                            <h3 className="contactView-header-3">Formularz kontaktowy</h3>
                          </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="contactView-input">
                                    <InputComponent onChange={e => {this.onChange(e, "subject")}} icon={false} placeholder="Temat" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="contactView-input">
                                    <TextareaComponent onChange={e => {this.onChange(e, "body")}} name="body" placeholder="Wiadomość" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="contactView-input">
                                    <InputComponent onChange={e => {this.onChange(e, "fullname")}} name="from" icon={false} placeholder="Imię i nazwisko" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="contactView-input">
                                    <InputComponent onChange={e => {this.onChange(e, "email")}} name="email" icon={false} placeholder="Adres e-mail" />
                                </div>
                            </Col>
                        </Row>
                        {
                            this.state.errorMessage && <Row>
                                <Col xs={12} md={12}>
                                    <div className="contact-error-message">{this.state.errorMessage}</div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="contactView-input">
                                    <CheckboxComponent icon={false} 
                                        onChange={e => this.onCheckboxChange('terms_of_use', e.target.checked)} 
                                        text="Wyrażam zgodę na przetwarzanie moich danych osobowych." 
                                        error={this.state.terms_of_use_error && 'Ta zgoda jest obwiązkowa'}
                                    />
                                </div>
                            </Col>
                        </Row>
                      </Container> 
                      <Container className="contactViewRegisterView-column">
                        <Row>
                          <Col xs={12} md={12}>
                            <div className="contactView-buttons">
                              <div className="contactView-button-wrapper">
                                <ButtonComponent type="submit" isOrange={true} isBlock={true} isCenter={true} text="Wyślij wiadomość" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <h3 className="contactView-header-3">Dane kontaktowe</h3>
                    </Col>
                    <Col xs={12} md={12}>
                        <h4 className="contactView-header-4">MotoDetektyw.pl</h4>
                        <h4 className="contactView-header-4">pomoc@motodetektyw.pl</h4>
                    </Col>
                  </Row>
                </Container>
            </form>
            </Col>
            <Col xs={12} md={3}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(ContactView);