import React, { Component } from 'react';
import './RegisterView.scss';

import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";

import { Container, Row, Col } from 'react-grid-system';
import { ReactComponent as Rejestracja } from '../../assets/diagnosta.svg';
import { ReactComponent as Diagnostyka } from '../../assets/diagnostyka3.svg';


class RegisterView extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className="register-header">Rejestracja</h2>
              <h3 className="register-header-3">Jak chcesz korzystać z MotoDetektyw.pl</h3>
            </Col>
          </Row>
          <Row>
          <Col className="register-column" xs={12} md={2}></Col>
            <Col className="register-column" xs={12} md={4}>
              <div className="register-column-inner">
                <div className="register-img-wrapper">
                  <Rejestracja 
                    width="100%"
                    height="175px"
                  />
                </div>
                <div className="register-text-wrapper">
                  <div className="register-text">
                    <Link to="/rejestracja-uzytkownika">
                      <ButtonComponent className="register-button" isCenter={true} isBlock={true} isOrange={true} text="Chcę zlecić sprawdzenie" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="register-column" xs={12} md={4}>
              <div className="register-column-inner">
                <div className="register-img-wrapper">
                  <Diagnostyka 
                    width="100%"
                    height="175px" 
                  />
                </div>
                <div className="register-text-wrapper">
                  <div className="register-text">

                    <Link to="/rejestracja-sprawdzajacego">
                      <ButtonComponent className="register-button" isCenter={true} isBlock={true} isDark={true} text="Chcę sprawdzać pojazdy / szukam zleceń" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="register-column" xs={12} md={2}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterView);