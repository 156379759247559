import React, { Component } from 'react';
import './OrderDetailsComponent.scss';
import timestampFormat from '../../utils/helpers/timestampFormat';

import moment from 'moment';
import { Container, Row, Col } from 'react-grid-system';

class OrderDetailsComponent extends Component {
  constructor(props) {
    super(props);
    }
    
  render() { 
    const orderDetails = this.props.order;
    const offersNumber = this.props.offers;
    const createdDate = timestampFormat(orderDetails.createdTimestamp);
    const creatorHelper = orderDetails.user.last_name.substring(0, 1);
    const creator = orderDetails.user.first_name + " " + creatorHelper + ".";
    const date = new Date();
    const today = moment.utc(date);
    const expiration = new Date(orderDetails.expiresTimestamp);
    const promo_end = moment(expiration);
    const difference = moment.duration(promo_end.diff(today));

    const expirationDate = new Date(orderDetails.expiresTimestamp*1000);
    const todaysDate = moment();
    const expireDate = moment(expirationDate);
    const dateDiff = expireDate.diff(todaysDate, 'days');
    const dateDiffPos = Math.abs(dateDiff)

    const expiredDate = timestampFormat(orderDetails.expiresTimestamp)
    const otherDay = dateDiff > 1 ? dateDiffPos + " dni" : "WYGASŁO";
    const isToday = dateDiff == 0 ? "DZISIAJ" : "";
    const howLongAv = dateDiff == 0 ? isToday : otherDay;
    return (
      <React.Fragment>
        <Container className="orderDetails-wrapper">
          <Row>
            <div className="orderDetails-header">
              <Container>
                <Row>
                  <Col xs={6} md={3}><span className="title">Autor: </span>{creator}</Col>
                  <Col xs={6} md={3}><span className="title">Dodano: </span> {createdDate.date} | {createdDate.time} </Col>
                  <Col xs={6} md={3}><span className="title">Do końca: </span> 
                  
                  {expiredDate.date} | {expiredDate.time}
                  </Col>
                  <Col xs={6} md={3}><span className="title">Oferty: </span> {offersNumber} </Col>
                </Row>
              </Container>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default OrderDetailsComponent;
