import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import UserSideNavComponent from "../../components/SideNavComponent/UserSideNavComponent";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import OrdersGridComponent from "../../components/GridComponent/OrdersGridComponent";
import { serverURL } from '../../config';


class UserDashOrdersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            orders: [],
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(tab) {
    }

    componentWillMount() {
        const token = localStorage.getItem('token');
        fetch(`${serverURL}orders/my`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': `bearer ${token}`,
            }),
        })
            .then(
                  
                (response) => {
                  if (response.ok) {
                    response.json().then(data => {
                      this.setState({orders: data})
                  })
                  } else {
                    this.props.history.push(`/zaloguj`);
                  }
              
                  }
            )
    }

    render() {
        const {orders} = this.state

        return (
            <React.Fragment>
              <Container>
                <Row>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={2}>
                    <UserSideNavComponent />
                  </Col>
                  <Col xs={12} md={8}>
                  {!orders ? "Brak zleceń" :
                    <PaginationComponent
                      data={orders}
                    >
                      <OrdersGridComponent />
                    </PaginationComponent>
                  }
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
              </Container>
            </React.Fragment>
        );
    }
}

export default withRouter(UserDashOrdersView)
