import React, { Component} from 'react';
import './InputComponent.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faMapMarkerAlt, faArrowDown } from '@fortawesome/free-solid-svg-icons';

class InputComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isFocused: false,
    };
    this.onFocus = this.onFocus.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    }
    
    onFocus(e) {
      this.setState({
          isFocused: true,
      });
    }

    onKeyUp(e) {
      if (e.target.value.length > 0) {
        this.setState({
            isFocused: true,
        });
      } else {
        this.setState({
          isFocused: false,
        });
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.value !== this.props.value) {
        if (this.props.value){
          this.setState({
            isFocused: true,
          });
        }
      }

    }

  render() {
    const { isFocused } = this.state
    const { error } = this.props;
    return (
      <React.Fragment>
        <div className="input-wrapper">
          { 
            this.props.value 
              ? <input 
                  value={this.props.value}
                  type={this.props.type}
                  name={this.props.name}
                  onChange={this.props.onChange}
                  onKeyUp={this.onKeyUp}
                  onFocus={this.onFocus}
                  className={`input ${error && 'input-border-red'}`} />
              : <input 
                  type={this.props.type}
                  name={this.props.name}
                  onChange={this.props.onChange}
                  onKeyUp={this.onKeyUp}
                  onFocus={this.onFocus}
                  className={`input ${error && 'input-border-red'}`} />
          }
          <span className={`input-floating-label input-floating-label-${isFocused || !!this.props.value} ${error && 'input-label-red'}
          `}>{this.props.placeholder}</span>
          <div className="input-icon">
            {this.props.iconMap ? <FontAwesomeIcon className="input-icon" icon={faMapMarkerAlt} /> : ""}
            {this.props.iconArrow ? <FontAwesomeIcon className="input-icon" icon={faArrowDown} /> : ""}
          </div>
          { error && <div className="input-error">{error}</div> }
        </div>
      </React.Fragment>
    );
  }
}

export default InputComponent;
