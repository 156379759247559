import React, { Component } from 'react';
import './VideoComponent.scss';

import { Container, Row, Col } from 'react-grid-system';


class VideoComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <React.Fragment>
        <Container className="video-container">
        <Row>
          <Col xs={12} md={2}></Col>
          <Col className="video-col" xs={12} md={8}>
            <div className="video-text">
              <div className="video-text-wrapper">
                <h3 className="video-header">Jak to <span className="video-special">działa?</span></h3>
                <div className="video-info">
                  <span>Łączymy Kupujących z Ekspertami.</span>
                  Wystarczy, że podasz nam lokalizację pojazdu, 
                  a otrzymasz oferty od zainteresowanych specjalistów z okolicy, którzy chętnie sprawdzą dla Ciebie pojazd.
              </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={2}></Col>
        </Row>
      </Container>
      </React.Fragment>
    );
  }
}

export default VideoComponent;
