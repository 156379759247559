import React, { Component } from 'react';
import './GridComponent.scss';
import { Link } from "react-router-dom";
import { serverURL } from '../../config';

import { Confirm } from 'semantic-ui-react';
import timestampFormat from '../../utils/helpers/timestampFormat';
import { NotificationManager } from 'react-notifications';

class GridComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      activeItem: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    }

    openModal(id) {
      this.setState({ open: true, activeItem: id })
    } 
    closeModal() {
      this.setState({ open: false })
    } 
    handleStatusChange(id, status) {
      const token = localStorage.getItem('token');
      fetch(`${serverURL}orders/${id}/status/${status}`, {
          method: 'POST',
          headers: new Headers({
              'Authorization': `bearer ${token}`,
          }),
      })
          .then(
            NotificationManager.success('Zmieniono status zlecenia.', 'Gratulacje', 5000)
          )
          .then(
            this.setState({ open: false })
          )
          .then(
            window.location.reload(false)
          )
      }  
    
  render() {
    
    const ordersList = this.props.data.map((order, index) => {
      const createdDate = timestampFormat(order.createdTimestamp);
      const expiresDate = timestampFormat(order.expiresTimestamp);

      return (
          <tr key={index}>
            <td>
              <Link to={`/zlecenie/${order.id}`}>
                <span className="carName">{order.model.make.name} {order.model.name}</span>
              </Link>
            </td>
            <td  className="mobile-disable"> {expiresDate.date}</td>
            <td  className="mobile-disable">{order.isFullInspection ? "Kompleksowa" : "Indywidualna"}</td>
            <td>    
            {order.status === 1 ? <span className="active">Aktywne</span> : "" }
            {order.status === 2 ? <span className="ongoing">Realizowane</span> : ""}
            {order.status === 3 ? <span className="finished">Zakończone</span> : ""}
            {order.status === 4 ? <span className="finished">Zakończone</span> : ""}

            {order.status === 5 ? <span className="finished">Zrealizowane</span> : ""}
            </td>
              <td>
              {(order.status === 2 && localStorage.getItem('role') == "0") ? <span onClick={e =>{this.openModal(order.id)}} className="userOrdersList-button pointer-element">Oznacz jako wykonaną</span> : ""}
              <Confirm
              open={this.state.open}
              onCancel={this.closeModal}
              onConfirm={e =>{this.handleStatusChange(this.state.activeItem, 5)}}
              content='Czy oznaczyć zlecenie jako wykonane?'
              cancelButton='Nie'
              confirmButton="Tak"
            />
                {(order.status === 2 || order.status === 5) ? <Link to={`/transakcja/${order.id}`}>Szczegóły</Link> : ""}
              </td>
          </tr>
      )
    }); 
    return (
      <React.Fragment>
        <table className="userOrders-table">
          <thead>
            <tr>
              <th>Zlecenie</th>
              <th className="mobile-disable">Termin inspekcji</th>
              <th className="mobile-disable">Zakres inspekcji</th>
              <th>Status</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
           {ordersList}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default GridComponent;