import React, { Component } from 'react';
import './RegisterUserView.scss';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import RegisterInfoComponent from "../../commons/RegisterInfoComponent/RegisterInfoComponent";
import RegisterUserViewForm from "./RegisterUserViewForm/RegisterUserViewForm";
import { Container, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';



class RegisterUserView extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col className="registerUser-column smaller-margins" xs={12} md={2}></Col>
            <Col xs={12} md={8} className="registerUser-column smaller-margins">
              <div className="registerUser-info-box">
                <FontAwesomeIcon icon={faInfoCircle} />
                <span>Aby wycenić sprawdzenie załóż konto lub zaloguj się.</span>
              </div>
            </Col>
            <Col className="registerUser-column smaller-margins" xs={12} md={2}></Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <div className="registerUser-header-wrapper">
                <h2 className="registerUser-header">Załóż konto</h2>
                <div>
                  <span>Masz już konto? </span>
                  <span><Link to="/zaloguj-sie">Zaloguj się</Link></span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="registerUser-column smaller-margins" xs={12} md={2}></Col>
            <Col className="registerUser-column smaller-margins" xs={12} md={8}>
              <div className="registerUser-column-inner">
                <RegisterUserViewForm />
                <RegisterInfoComponent />
              </div>
            </Col>
            <Col className="registerUser-column smaller-margins" xs={12} md={2}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterUserView);