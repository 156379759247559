import React, { Component } from 'react';
import './RegisterServiceViewForm.scss';

import { Container, Row, Col } from 'react-grid-system';
import InputComponent from "../../../commons/InputComponent/InputComponent";
import { Dropdown} from 'semantic-ui-react';

class RegisterServiceCompanyForm extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { userData, onFieldChange, errors, backendErrors } = this.props
    const vatOptions = [
      {key: "1", text: "Tak", value: true},
      {key: "2", text: "Nie", value: false},
    ]

    return (
      <React.Fragment>
        <Container>
            <Row>
                <Col xs={12} md={6} className="registerServiceViewForm-column">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <h3 className="registerService-header-3">Dane firmy</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.country} 
                                        error={backendErrors.country || (errors.country_error && 'Podaj poprawny kraj')} 
                                        icon={false} 
                                        placeholder="Kraj" 
                                        onChange={e => {onFieldChange("country", e.target.value)}} />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.company_nip} 
                                        error={backendErrors.company_nip || (errors.company_nip_error && 'Podaj poprawny NIP')} 
                                        icon={false} 
                                        placeholder="NIP"
                                        onChange={e => {onFieldChange("company_nip", e.target.value)}} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.company_name} 
                                        error={backendErrors.company_name || (errors.company_name_error && 'Podaj nazwę firmy')} 
                                        icon={false} 
                                        placeholder="Pełna nazwa firmy"
                                        onChange={e => {onFieldChange("company_name", e.target.value)}} />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="registerServiceViewForm-input">
                                    <Dropdown placeholder="Wystawiam faktury VAT"
                                        value={userData.company_vat}
                                        fluid
                                        selection
                                        options={vatOptions}
                                        className={(backendErrors.company_vat || errors.company_vat_error) && 'dropdown-error'}
                                        onChange={(e, { value }) => onFieldChange("company_vat", value)} 
                                    />
                                </div>
                                { errors.company_vat_error && <div className="dropdown-error-message">Wybierz czy wystawiasz faktury VAT</div> }
                                { backendErrors.company_vat && <div className="dropdown-error-message">{backendErrors.company_vat}</div> }
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={12} md={6}  className="registerServiceViewForm-column">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <h3 className="registerService-header-3">Adres firmy</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.street} 
                                        error={backendErrors.street || (errors.street_error && 'Podaj poprawną ulicę')} 
                                        icon={false} 
                                        placeholder="Ulica i numer" 
                                        onChange={e => {onFieldChange("street", e.target.value)}} />
                                </div>
                            </Col>
                            <Col xs={12} md={12}>
                                <div className="registerServiceViewForm-input">
                                <InputComponent 
                                        value={userData.city} 
                                        error={backendErrors.city || (errors.city_error && 'Podaj poprawną miejscowość')} 
                                        icon={false} 
                                        placeholder="Miejscowość" 
                                        onChange={e => {onFieldChange("city", e.target.value)}} />
                                </div>
                            </Col>
                            <Col xs={12} md={12}>
                                <div className="registerServiceViewForm-input">
                                    <InputComponent 
                                        value={userData.postcode} 
                                        error={backendErrors.postcode || (errors.postcode_error && 'Podaj poprawny kod pocztowy')}
                                        icon={false} 
                                        placeholder="Kod pocztowy" 
                                        onChange={e => {onFieldChange("postcode", e.target.value)}} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default RegisterServiceCompanyForm;
