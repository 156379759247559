import React, { Component } from 'react';
import './OrderView.scss';

import { withRouter } from 'react-router-dom';
import { serverURL } from '../../config';

import { Container, Row, Col } from 'react-grid-system';

import OrderDetailsComponent from "../../components/OrderDetailsComponent/OrderDetailsComponent";
import OrderInfoComponent from '../../components/OrderInfoComponent/OrderInfoComponent';
import OrderInfoBoxComponent from '../../commons/OrderInfoBoxComponent/OrderInfoBoxComponent';
import OffersComponent from '../../components/OffersComponent/OffersComponent';


import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";


class OrderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        orderData: {
          "id": null,
          "status": null,
          "name":"-",
          "description": "",
          "inspectDescription":"",
          "isFullInspection":false,
          "important":false,
          "expires":"",
          "vin": "",
          "details_url":"",
          "postcode":"",
          "city":"",
          "street":"",
          "year":"",
          "user":{
            "id":null,
            "email":"",
            "roles":[],
            "status":null,
            "first_name":"",
            "last_name":"",
            "introduced_by":null
          },
          "model":{
            "id":null,
            "make":{
              "id":null,
              "name":""
            },
            "name":""
          },
          "createdTimestamp":null,
          "expiresTimestamp":null
        },
        offersList: []
    };
    this.fetchOrderData = this.fetchOrderData.bind(this)
  }

  componentWillMount() {
    this.fetchOrderData()
  }

  fetchOrderData() {
    const token = localStorage.getItem('token');
    const pathArray = window.location.pathname.split('/');
    const orderId = pathArray[2];
    
    fetch(`${serverURL}orders/${orderId}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `bearer ${token}`,
        }),
    })
        .then(
          (response) => {
            if (response.ok) {
              response.json().then(data => {
                  this.setState({orderData: data})
              })
            } else {
              this.props.history.push(`/zaloguj`);
            }
          }
        )
        fetch(`${serverURL}orders/${orderId}/offers`, {
          method: 'GET',
          headers: new Headers({
              'Authorization': `bearer ${token}`,
          }),
      })
          .then(
              response => {
                  response.json().then(data => {
                      this.setState({offersList: data})
                  })
              }
          )
  }

  createOffer(id, expires) { 
    this.props.history.push(`/utworz-oferte/${id}`);
    localStorage.setItem('orderExpires', expires)
  }

  render() {
    const {orderData, offersList} = this.state;
    const offersNumber = offersList.length;
    const userRole = localStorage.getItem('role');
    const isCustomer = userRole == "0";
    const userId = localStorage.getItem('id');
    const didUserOffer = offersList.find(offer => offer.user.id == userId);
    const isThereOffer = didUserOffer ? true : false;
    const showButton = !isCustomer && !isThereOffer && orderData.id;
    return (
      <React.Fragment>
        <Container>
          <Row>
          <Col className="orderView-column" xs={12} md={1}></Col>
            <Col className="orderView-column" xs={12} md={10}>
              <div className="orderView-column-inner">
                <h2 className="orderView-title">{orderData.name}</h2>
                <OrderDetailsComponent order={orderData} offers={offersNumber} />
                <OrderInfoComponent order={orderData} />
              </div>
            </Col>
            <Col className="orderView-column" xs={12} md={1}></Col>
          </Row>
          { orderData.description && orderData.description.length > 0 
            ?    <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={10}>
              <h4 className="orderView-title">Informacje dodatkowe</h4>
              <div className="orderView-description">{orderData.description}</div>
            </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>
            : ""}
       
          <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={10} className="orderView-zakres">
              <h3 className="orderView-title">Zakres inspekcji</h3>
              <span className="orderView-span">
              {orderData.isFullInspection ? 
                <span>Kompleksowa - pełne sprawdzenie pojazdu. <a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Raport.pdf" target="_blank">Wzór raportu</a>.</span> :
                "Indywidualna - zobacz wybrany zakres" 
                
              }
              </span>
              <div className="orderView-description">{orderData.inspectDescription}</div>
            </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>
            <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={10}>
          {showButton
              ? <ButtonComponent isOrange={true} text="Dodaj ofertę" onClick={e => this.createOffer(orderData.id, orderData.expiresTimestamp)} />
              : ""}
              <h3 className="orderView-title">Lista ofert ({offersList.length})</h3>
           
              </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>
           
       
          </Container>

          {offersList.length === 0 
            ? "Brak ofert dla danego zlecenia" 
            : <OffersComponent 
                offers={offersList} 
                order={orderData.id} 
                orderData={orderData} 
                isCustomer={isCustomer}
                fetchOrderData={this.fetchOrderData}
          />}
            
        <Container>
          <Row>
            <Col xs={12} md={1}>
            </Col>
            <Col xs={12} md={10}>
              <OrderInfoBoxComponent />
            </Col>
            <Col xs={12} md={1}>
            </Col>
          </Row>
          <Row>
          <Col xs={12} md={1}>
          </Col>
          <Col xs={12} md={10}>
           <h4 className="orderView-title">Numer zlecenia: {orderData.id}</h4>
          </Col>
          <Col xs={12} md={1}>
          </Col>
        </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(OrderView);