export const reducer = (state, action) => {
  switch (action.type) {

    case 'setData':
      return {
        ...state,
        [`${action.field}`]: action.data,
      }

    default:
      return state
  }
}