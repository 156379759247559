import React, { Component } from 'react';
import './RegisterServiceViewForm.scss';
import { Container, Row, Col } from 'react-grid-system';
import { Dropdown} from 'semantic-ui-react';
import ButtonComponent from "../../../commons/ButtonComponent/ButtonComponent";
import TextareaComponent from "../../../commons/TextareaComponent/TextareaComponent";
import CheckboxComponent from "../../../commons/CheckboxComponent/CheckboxComponent";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Geocoder from 'react-mapbox-gl-geocoder'
import { MAPBOX_TOKEN } from '../../../config';


const mapAccess = {
  mapboxApiAccessToken: MAPBOX_TOKEN
}

const mapStyle = {
  width: '100%',
  height: 300,
}

const queryParams = {
  country: 'pl',
  language: 'pl',
}


class RegisterServiceLastStepViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlace: null,
      customPointSelected: false,
      errors: {
        radius_error: false,
        terms_of_use_error: false,
        data_processing_1_error: false,
      }
    }
    this.validateInputs = this.validateInputs.bind(this)
  }

  onSelected = (viewport, item) => {
    this.setState({
      selectedPlace: item,
      customPointSelected: false,
      orderData: { ...this.state.orderData, city: item.text }
    });
    this.props.onLocationChange(viewport, item)
  }

  validateInputs(e) {
    const { userData, onSubmit } = this.props
    e.preventDefault()
    const radius_error = userData.radius === 0
    const terms_of_use_error = !userData.terms_of_use
    const data_processing_1_error = !userData.data_processing_1

    if (
      !radius_error &&
      !terms_of_use_error
    ) {
      onSubmit(e)
    }

    this.setState({
      errors: {
        radius_error,
        terms_of_use_error
      }
    })
  }

  componentWillMount() {
    this.setState({radius: 2 })
  }

  componentDidMount() {
    const geocoderInput = document.querySelector('.react-geocoder > input')
    if (geocoderInput) geocoderInput.placeholder = 'Ulica, nr domu, miasto'
    if (this.props.userData.company_location) geocoderInput.placeholder = this.props.userData.company_location
  }

  render() {
    const { errors, customPointSelected } = this.state;
    const { userData, onFieldChange, onFieldLocationChange, backendErrors } = this.props


    const checkbox2 = `Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od MD Tomasz Antosiak z wykorzystaniem środków komunikacji elektronicznej (m.in. e-mail).`;
    const checkbox3 = `Wyrażam zgode na przesyłanie mi przez MotoDetektyw.pl za pomocą środków komunikacji elektronicznej informacji o nowych funkcjach, promocjach, itp.`;
    const distanceOptions = [
        {key: "1", text: "+ 2 km", value: "2"},
        {key: "2", text: "+ 5 km", value: "5"},
        {key: "3", text: "+ 10 km", value: "10"},
        {key: "4", text: "+ 15 km", value: "15"},
        {key: "5", text: "+ 20 km", value: "20"},
        {key: "6", text: "+ 30 km", value: "30"},
        {key: "7", text: "+ 50 km", value: "50"},
    ];
    const promoOptions = [
        {key: "1", text: "Z polecenia", value: "polecenie"},
        {key: "2", text: "Poradnik lub blog", value: "poradnik/blog"},
        {key: "3", text: "Google", value: "google"},
        {key: "4", text: "Facebook", value: "facebook"},
        {key: "5", text: "Youtube", value: "youtube"},
        {key: "6", text: "Instagram", value: "instagram"},
        {key: "7", text: "Billboard", value: "billboard"},
        {key: "8", text: "Inne", value: "inne"},
    ];

    return (
      <React.Fragment>
        <div className="registerServiceViewForm-container">
            <Container>
                <Row>
                    <Col xs={12} md={12}>
                    <h2 className="registerService-header">Już prawie gotowe, ostatni krok</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <h3 className="registerService-header-3">Informacje dodatkowe i doświadczenie</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="registerServiceViewForm-input">
                            <TextareaComponent 
                                placeholder="Możesz napisać coś o sobie jeśli chcesz. Ułatwi to wybór Zlecającemu i sprawi, że twój profil będzie dla niego bardziej atrakcyjny."
                                value={userData.company_info}
                                onChange={e => onFieldChange('company_info', e.target.value)} />
                            <div className="registerServiceViewForm-information">
                                <span className="registerServiceViewForm-information-accent">
                                    Ważne:
                                </span>
                                Nie zamieszczaj tutaj swoich danych kontaktowych.
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="registerServiceViewForm-column-info">
                    <Col xs={12} md={12}>
                        <h3 className="registerService-header-3">Obszar działania</h3>
                        <div className="registerServiceViewForm-information-accent">
                            Będziesz otrzymywał powiadomienia o zleceniach sprawdzenia w rejonie, który zaznaczysz
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <div className="geocoder-wrapper">
                          <Geocoder 
                            {...mapAccess} 
                            onSelected={this.onSelected}
                            viewport={userData.viewport}
                            hideOnSelect={true}
                            updateInputOnSelect={true}
                            queryParams={queryParams}
                          />
                          <i aria-hidden="true" class="search icon search-icon"></i>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <Dropdown placeholder="Wybierz dystans" 
                          value={userData.radius}
                          fluid
                          selection
                          options={distanceOptions}
                          defaultValue={this.state.radius}
                          className={(backendErrors.radius || errors.radius_error) && 'dropdown-error'}
                          onChange={(e, { value }) => onFieldChange("radius", value)}
                        />
                        { errors.radius_error && <div className="dropdown-error-message-no-margin">Wybierz zasięg działania</div> }
                        { backendErrors.radius && <div className="dropdown-error-message-no-margin">{backendErrors.radius}</div> }
                    </Col>
                </Row>
                <Row className="registerServiceViewForm-column-info">
                    <Col xs={12} md={7}>
                        <div className="registerUserViewForm-input">
                        <h3 className="registerService-header-3">Skąd wiesz o Motodetektyw.pl?</h3>

                            <Dropdown placeholder="Skąd wiesz o MotoDetektyw.pl"
                              value={userData.introduced_by}
                              fluid 
                              selection
                              options={promoOptions} 
                              className={(backendErrors.introduced_by || errors.introduced_by_error) && 'dropdown-error'}
                              onChange={(e, { value }) => onFieldChange("introduced_by", value)}
                            />
                            { errors.introduced_by_error && <div className="dropdown-error-message-no-margin">Wybierz skąd o nas wiesz</div> }
                            { backendErrors.introduced_by && <div className="dropdown-error-message-no-margin">{backendErrors.introduced_by}</div> }
                        </div>
                    </Col>
                </Row>
                <Row className="registerServiceViewForm-column-info">
                    <Col xs={12} md={12}>
                        <div className="registerUserViewForm-input">
                            <CheckboxComponent 
                                checked={userData.terms_of_use} 
                                error={backendErrors.terms_of_use || (errors.terms_of_use_error && 'Musisz zaznaczyć tę zgodę')}
                                icon={false}
                                text={<span>Oświadczam, że znam i akceptuję postanowienia <a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Regulamin.pdf" target = "_blank"> Regulaminu</a> motoDetektyw.pl.</span>}
                                icon={false}  
                                onChange={e => onFieldChange('terms_of_use', e.target.checked)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="registerUserViewForm-input">
                            <CheckboxComponent 
                                checked={userData.data_processing_1} 
                                icon={false} 
                                text={checkbox2} 
                                onChange={e => onFieldChange('data_processing_1', e.target.checked)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="data-admin-info">Administratorem Twoich danych osobowych jest MD Tomasz Antosiak REGON: 365793874.</div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xs={12} md={12}>
                        <div className="registerUserViewForm-input">
                            <CheckboxComponent 
                                checked={userData.data_processing_2} 
                                icon={false} 
                                text={checkbox3} 
                                onChange={e => onFieldChange('data_processing_2', e.target.checked)} />
                        </div>
                    </Col>
                </Row> */}
            </Container>
            <Container>
                <Row>
                    <Col xs={12} md={12} className="registerServiceViewForm-column">
                        <div className="registerServiceViewForm-buttons">
                            <div className="registerServiceViewForm-back-button-wrapper" 
                                 onClick={() => this.props.onHandleStepChange(false)}>
                              <FontAwesomeIcon className="footer-text-accent footer-text-icon" icon={faArrowLeft} /> 
                              <span>Wstecz</span>
                            </div>

                            <div className="registerServiceViewForm-button-wrapper">
                                <ButtonComponent isOrange={true} isBlock={true} text="Utwórz konto" onClick={e => this.validateInputs(e)} />
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    Object.keys(backendErrors).length 
                        ? <Row className="errors-row">
                            <Col xs={12} md={12}>
                                {
                                    Object.keys(backendErrors).map((errorKey, i) => {
                                        return <div key={i}>{backendErrors[errorKey]}</div>
                                    })

                                }
                            </Col>
                        </Row>
                        : null
                }
                <Row></Row>
            </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterServiceLastStepViewForm;
