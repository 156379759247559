import React, { Component } from 'react';
import './OrderInfoBoxComponent.scss';

class OrderInfoBoxComponent extends Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      <React.Fragment>
        <div className="register-info-wrapper">
            <h3 className="info-header">Pamiętaj!</h3>
            <div className="info-message">Najtańsza oferta nie znaczy najlepsza!</div>
            <div className="info-message">Popatrz na oferty również pod kątem ocen Sprawdzającego oraz możliwego terminu wykonania inspekcji.</div>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderInfoBoxComponent;
