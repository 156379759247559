import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
// import { ReactComponent as Logo } from '../../assets/logo.svg';
import './NavbarComponent.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import DualButtonComponent from "../../commons/DualButtonComponent/DualButtonComponent";
import { useStateValue } from '../../state/state'
import { withRouter } from 'react-router-dom'

function NavbarComponent(props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [userName, setUserName] = useState('')
  const [{ isLoggedIn, first_name, role, isCustomer, isService }, dispatch] = useStateValue()


  console.log('pathname', window.location.pathname)

  const handleToggle = e => {
    e.preventDefault();
    setIsExpanded(!isExpanded)
  }

  const handleLink = e => {
    setIsExpanded(false)
  }
  
  const logout = () => {
    localStorage.clear();
    dispatch({ type: 'setData', field: 'isLoggedIn', data: false })
    props.history.push('/')
    window.location.reload(false)
  } 

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('first_name');
    const role = localStorage.getItem('role');
    const isCustomer = role == 0;
    const isService = role == 1;
    if (isCustomer) {
      dispatch({ type: 'setData', field: 'isCustomer', data: true })

    }
    if (isService) {
      dispatch({ type: 'setData', field: 'isService', data: true })

    }
    if (token) {
      dispatch({ type: 'setData', field: 'role', data: role })
      
      setUserName(user)
      dispatch({ type: 'setData', field: 'isLoggedIn', data: true })
    }
    
  }, [first_name])
  const isServiceLogged = isService || !isLoggedIn;
  const isCustomerLogged = isCustomer || !isLoggedIn;


  if (
    window.location.pathname === '/zlec-sprawdzenie' 
    || window.location.pathname === '/zaloguj-sie' 
    || window.location.pathname === '/rejestracja-uzytkownika'
  ) {
    return <div className="navbar-container">
      <div className="navbar-logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </div>
  }

  return (
    <React.Fragment>
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <nav className={`nav nav-list nav-${isExpanded ? "change" : ""}`}>
          <ul className="navbar-list">
              <NavLink to="/zlec-sprawdzenie" onClick={e => handleLink(e)}>
                  <li className="navbar-list-element">Zleć sprawdzenie</li>
                </NavLink>
          {isServiceLogged
            ?  <NavLink to="/lista-zlecen" onClick={e => handleLink(e)}>
                  <li className="navbar-list-element">Szukaj zleceń</li>
                </NavLink>
            : ""
          }
        
            <a href="https://blog.motodetektyw.pl/">
              <li className="navbar-list-element">Poradniki</li>
            </a>
          </ul>
        </nav>
        {isLoggedIn
        ? <div className="navbar-buttons-2">
        {isCustomerLogged 
          ? <Link to="/moje-zlecenia">
          <span className="navUser">
            {userName}
          </span>
        </Link>
          : ""
        }
        {isServiceLogged 
          ? <Link to="/zlecenia">
          <span className="navUser">
            {userName}
          </span>
        </Link>
          : ""
        }
            <span className="nav-logout" onClick={logout}>
              Wyloguj
            </span>
        </div>
        : <div className="navbar-buttons">
            <div className={`navbar-mobile-buttons navbar-buttons-${isExpanded ? "change" : ""}`} onClick={e => handleToggle(e)}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            <Link to="/rejestracja">
              <DualButtonComponent hideMobileText={true} iconUser={true} isDark={true} isLeft={true} isBlock={true} text="Rejestracja" />
            </Link>
            <Link to="/zaloguj">
              <DualButtonComponent hideMobileText={true} iconLock={true} isOrange={true} isBlock={true} text="Logowanie" />
            </Link>
          </div>
        }

      </div>
      
      <div className="navbar-mobile">
      <div  className="navbar-container-mobile">
      <div className="navbar-logo">
      <Link to="/">
        <Logo />
      </Link>
    </div>
    {isLoggedIn
    ? <div className="navbar-buttons-2">
      {isCustomerLogged 
        ? <Link to="/moje-zlecenia">
        <span className="navUser">
          {userName}
        </span>
      </Link>
        : ""
      }
      {isServiceLogged 
        ? <Link to="/zlecenia">
        <span className="navUser">
          {userName}
        </span>
      </Link>
        : ""
      }
    </div>
    : "" }
    <div className="navbar-buttons">
        <div className={`navbar-mobile-buttons navbar-buttons-${isExpanded ? "change" : ""}`} onClick={e => handleToggle(e)}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        {!isLoggedIn
        ? <div>
        <Link to="/rejestracja">
          <DualButtonComponent hideMobileText={true} iconUser={true} isDark={true} isLeft={true} isBlock={true} text="Rejestracja" />
        </Link>
        <Link to="/zaloguj">
          <DualButtonComponent hideMobileText={true} iconLock={true} isOrange={true} isBlock={true} text="Logowanie" />
        </Link>
        </div>
        : ""}
      </div>
    
      </div>
  
        <nav className={`nav nav-list nav-${isExpanded ? "change" : ""}`}>
          <ul className="navbar-list">
          <NavLink to="/zlec-sprawdzenie" onClick={e => handleLink(e)}>
                  <li className="navbar-list-element">Zleć sprawdzenie</li>
                </NavLink>
            
          {isServiceLogged
            ?  <NavLink to="/lista-zlecen" onClick={e => handleLink(e)}>
                  <li className="navbar-list-element">Szukaj zleceń</li>
                </NavLink>
            : ""
          }
        
            <a href="https://blog.motodetektyw.pl/">
              <li className="navbar-list-element">Poradniki</li>
            </a>
          </ul>
          {isLoggedIn ? <span className="nav-logout" onClick={logout}> Wyloguj </span> : ""}
        </nav>
      </div>
      </React.Fragment>
  );
}

export default withRouter(NavbarComponent)
