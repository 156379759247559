import React, { Component } from 'react';
import './RegisterUserViewForm.scss';
import { Container, Row, Col } from 'react-grid-system';
import { Link, withRouter } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { Dropdown } from 'semantic-ui-react';
import ButtonComponent from "../../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../../commons/InputComponent/InputComponent";
import CheckboxComponent from "../../../commons/CheckboxComponent/CheckboxComponent";
import { serverURL } from '../../../config';

class RegisterUserViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      terms_of_use: false,
      data_processing_1: false,
      introduced_by: "",
      email: localStorage.getItem('email') || '',
      phone: '',
      first_name: '',
      last_name: '',
      password: '',
      backendErrors: {},
      errors: {
        email_error: false,
        first_name_error: false,
        last_name_error: false,
        password_error: false,
        terms_of_use_error: false,
        data_processing_1_error: false,
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validateInputs = this.validateInputs.bind(this)


  }

  componentWillMount() {
    const getEmail = localStorage.getItem('email');
    this.setState({
      orderData: {
        ...this.state.orderData,
        email: getEmail,
      }
    })
    localStorage.removeItem('email');

  }
  onChange(field, value) {
    this.setState({ [field]: value });
  }
  validatePassword(password) {
    // eslint-disable-next-line
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
    // return re.test(String(password))
    return password.length >= 8
  }

  validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  validateInputs(e) {
    const { email, first_name, last_name, password, terms_of_use, data_processing_1 } = this.state
    e.preventDefault()
    const email_error = !this.validateEmail(email);
    const first_name_error = first_name.length < 2;
    const last_name_error = last_name.length < 2;
    const password_error = !this.validatePassword(password);
    const terms_of_use_error = !terms_of_use;
    const data_processing_1_error = !data_processing_1;

    this.setState({
      errors: {
        email_error,
        first_name_error,
        last_name_error,
        password_error,
        terms_of_use_error,
        data_processing_1_error,
      }
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    const temporaryOrder = localStorage.getItem('temporaryOrderId');
    const registerData = { ...this.state, temporaryOrder }
    const data = JSON.stringify(registerData);
    console.log('rejestracja');
    fetch(`${serverURL}register`, {
      method: 'POST',
      body: data,
    })
      .then((response) => {
        if (response.ok) {
          NotificationManager.success('Twoje konto zostało utworzone. Link aktywacyjny wysłaliśmy na twojego maila.', 'Gratulacje', 5000)
          localStorage.removeItem('temporaryOrderId');
          this.props.history.push("/")
        } else {
          response.json().then(json => {
            this.setState({
              backendErrors: Array.isArray(json.errors) ? {
                email_is_used: json.errors[0]
              } : json.errors
            })
          })
        }
      })
  }
  render() {
    const { introduced_by, terms_of_use, data_processing_1, backendErrors, errors } = this.state
    const checkbox2 = `Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od MD Tomasz Antosiak z wykorzystaniem środków komunikacji elektronicznej (m.in. e-mail).`;
    const promoOptions = [
      { key: "1", text: "Z polecenia", value: "polecenie" },
      { key: "2", text: "Poradnik lub blog", value: "poradnik/blog" },
      { key: "3", text: "Google", value: "google" },
      { key: "4", text: "Facebook", value: "facebook" },
      { key: "5", text: "Youtube", value: "youtube" },
      { key: "6", text: "Instagram", value: "instagram" },
      { key: "7", text: "Billboard", value: "billboard" },
      { key: "8", text: "Inne", value: "inne" },
    ];

    return (
      <React.Fragment>
        <div className="registerUserViewForm-container">
          <form className="registerUserViewForm-form" onSubmit={this.handleSubmit}>
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <div className="registerUserViewForm-input">
                    <InputComponent
                      value={this.state.email}
                      error={backendErrors.email || (errors.email_error && 'Podaj poprawny adres email')}
                      onChange={e => { this.onChange("email", e.target.value) }} icon={false} placeholder="Adres e-mail" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="registerUserViewForm-input">
                    <InputComponent
                      error={backendErrors.first_name || (errors.first_name_error && 'Podaj swoje imię')}

                      onChange={e => { this.onChange("first_name", e.target.value) }} icon={false} placeholder="Imię" />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="registerUserViewForm-input">
                    <InputComponent
                      error={backendErrors.last_name || (errors.last_name_error && 'Podaj swoje nazwisko')}

                      onChange={e => { this.onChange("last_name", e.target.value) }} icon={false} placeholder="Nazwisko" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className="registerUserViewForm-input">
                    <InputComponent
                      error={backendErrors.password || (errors.password_error && 'Podaj poprawne hasło')}
                      type="password" onChange={e => { this.onChange("password", e.target.value) }} icon={false} placeholder="Hasło" />
                    <span className="registerViewForm-msg">Hasło musi zawierać min. 8 znaków.</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className="registerUserViewForm-input">
                    <CheckboxComponent
                      checked={terms_of_use}
                      error={backendErrors.terms_of_use && backendErrors.terms_of_use}
                      onChange={e => this.onChange('terms_of_use', e.target.checked)}
                      text={<span>Oświadczam, że znam i akceptuję postanowienia <a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Regulamin.pdf" target="_blank"> Regulaminu</a> MotoDetektyw.pl.</span>}
                      icon={false} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className="registerUserViewForm-input">
                    <CheckboxComponent
                      checked={data_processing_1}

                      onChange={e => this.onChange('data_processing_1', e.target.checked)}
                      icon={false} text={checkbox2} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className="data-admin-info">Administratorem Twoich danych osobowych jest MD Tomasz Antosiak REGON: 365793874.</div>
                </Col>
              </Row>
              {/* <Row className="registerUserViewForm-row">
                <Col xs={12} md={12}>
                  <h3 className="registerUser-header-3">Skąd wiesz o Motodetektyw.pl?</h3>

                  <div className="registerUserViewForm-input">
                    <Dropdown placeholder="Skąd wiesz o MotoDetektyw.pl"
                      fluid
                      selection
                      options={promoOptions}
                      onChange={(e, { value }) => this.onChange("introduced_by", value)}
                    />
                  </div>
                </Col>
              </Row> */}
            </Container>
            <div className="registerUserViewForm-buttons">
              <div className="registerUserViewForm-button-wrapper">
                <ButtonComponent type="submit" isOrange={true} isBlock={true} text="Załóż konto" />
                {backendErrors.email_is_used && <p className='email_is_used'>{backendErrors.email_is_used}</p>}
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterUserViewForm);
