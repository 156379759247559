import React, { Component } from 'react';
import './ResetPasswordView.scss';

import { Container, Row, Col } from 'react-grid-system';
import { Link, withRouter } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import { serverURL } from '../../config';

class ResetPasswordLastStepView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        password: '',
        code: '',
        backendErrors: {},
        errors: {
            password_error: false,
          }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange= this.onChange.bind(this);
    this.validateInputs = this.validateInputs.bind(this)


    }

    componentWillMount() {
      const pathArray = window.location.search.split("?");
      const token = pathArray[1];
      this.setState({code: token})
      /*
      const getEmail = localStorage.getItem('email');
      this.setState({orderData: {
       ...this.state.orderData,
       email: getEmail,
       }})
       localStorage.removeItem('email');
 */
     } 

    onChange(field, value) {
        this.setState({ [field]: value });
    }
 
    
    validatePassword(password) {
      // eslint-disable-next-line
      const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
      // return re.test(String(password))
    }
      validateInputs(e) {
        const { password } = this.state
        e.preventDefault()
        const password_error = !this.validatePassword(password);

        this.setState({
          errors: {
            password_error,
          }
        })
      }
    handleSubmit(event) {
        event.preventDefault();
        const password = { newPassword: this.state.password, code: this.state.code }
        const data = JSON.stringify(password);
       fetch(`${serverURL}change-password`, {
            method: 'POST',
            body: data,
        })
        .then((response) => {
          if (response.ok) {
            NotificationManager.success('Zaloguj się nowym hasłem', 'Gratulacje', 5000)
            
            this.props.history.push("/zaloguj")
          } else {
            response.json().then(json => {
              this.setState({
                backendErrors: { password: "Podane hasło nie spełnia wymagań lub token wygasł."}
              })
            })
          }
        }) 
    }
  render() {
    const { password, backendErrors, errors } = this.state;
    
    return (
        <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className="registerUser-header">Zresetuj hasło</h2>
            </Col>
          </Row>
          <Row>
          <Col className="registerUser-column" xs={12} md={4}></Col>
            <Col className="registerUser-column" xs={12} md={4}>
              <div className="registerUser-column-inner">
                <h3 className="registerUser-header-3">Podaj nowe hasło</h3>
                <div className="registerUserViewForm-container">
                <form className="registerUserViewForm-form" onSubmit={this.handleSubmit}>
                  <Container>
                      <Row>
                          <Col xs={12} md={12}>
                          <div className="registerUserViewForm-input">
                            <InputComponent 
                            error={backendErrors.password || (errors.password_error && 'Podaj poprawne hasło')} 
                            
                            type="password" onChange={e => {this.onChange("password", e.target.value)}} icon={false} placeholder="Hasło" />
                            <span className="registerViewForm-msg">Hasło musi zawierać min. 8 znaków, wielką literę, znak specjalny i cyfrę.</span>
                            </div>
                          </Col>
                      </Row>
      
                  </Container>
                  <div className="registerUserViewForm-buttons">
                      <div className="registerUserViewForm-button-wrapper">
                              <ButtonComponent type="submit" isOrange={true} isBlock={true} text="Zresetuj hasło" />
                          
                      </div>
                  </div>
              </form>
              </div>
              </div>
            </Col>
            <Col className="registerUser-column" xs={12} md={4}></Col>
          </Row>
        </Container>
      </React.Fragment>
   
    );
  }
}

export default withRouter(ResetPasswordLastStepView);
