import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import UserSideNavComponent from "../../components/SideNavComponent/UserSideNavComponent";
import { serverURL } from '../../config';
import InputComponent from "../../commons/InputComponent/InputComponent";
import { NotificationManager } from 'react-notifications';
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import CheckboxComponent from "../../commons/CheckboxComponent/CheckboxComponent";


class UserDashMyAccView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        user: {
          first_name: '',
          last_name: '',

        terms_of_use: false,
        data_processing_1: false,
        },
        backendErrors: {},
        errors: {
            first_name_error: false,
            last_name_error: false,
          }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange= this.onChange.bind(this);
    this.validateInputs = this.validateInputs.bind(this)
}

componentWillMount() {
  const token = localStorage.getItem('token');

  fetch(`${serverURL}users/me`, {
    method: 'GET',
    headers: new Headers({
      'Authorization': `bearer ${token}`, 
    }),
  })
    .then(
      (response) => {
        if (response.ok) {
          response.json().then(data => {
            this.setState({user: data})
        })
        } else {
          this.props.history.push(`/zaloguj`);
        }
    
        }
       
    )
}

  onChange(field, value) {
    this.setState({ user: {...this.state.user, [field]: value }});
}
validatePassword(password) {
    // eslint-disable-next-line
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
    // return re.test(String(password))
  }


  validateInputs(e) {
    const { first_name, last_name, password} = this.state
    e.preventDefault()
    const first_name_error = first_name.length < 2
    const last_name_error = last_name.length < 2
    const password_error = !this.validatePassword(password)

    this.setState({
      user: {
        first_name: '',
        last_name: '',
      },
      errors: {
        first_name_error,
        last_name_error,
        password_error,
      }
    })
  }
  handleSubmit(event) {
    event.preventDefault();
    const data = JSON.stringify(this.state.user);
    const token = localStorage.getItem('token');

    fetch(`${serverURL}users/edit`, {
        method: 'PATCH',
        headers: new Headers({'Authorization': `bearer ${token}`, 'content-type': 'application/json'}),
        body: data,
    })
    .then((response) => {
        if (response.ok) {
          NotificationManager.success('Twoje konto zostało edytowane.', 'Gratulacje', 5000)
          window.location.reload();
        } else {
          response.json().then(json => {
            this.setState({
              backendErrors: json.errors
            })
          })
        }
      })
}
  render() {
    const { user, backendErrors, errors } = this.state;
    const checkbox2 = `Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych i otrzymywanie informacji handlowych od MD Tomasz Antosiak z wykorzystaniem środków komunikacji elektronicznej (m.in. e-mail).`;
   
    return (
      <React.Fragment>
        <Container className="dashAcc-wrapper">
          <Row>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={2}>
              <UserSideNavComponent />
            </Col>
            <Col xs={12} md={8}>
              <form onSubmit={this.handleSubmit}>
                <Container>
                    <Row>
                    <Col xs={12}>
                    <h2 className="dash-h2">Moje konto</h2>
                    <h3 className="dash-h3">
                    {user.first_name} 
                    <span className="dash-h3-span">Zleceniodawca</span>
                    </h3>
                    </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="registerUserViewForm-input">
                                <InputComponent 
                                error={backendErrors.first_name || (errors.first_name_error && 'Podaj swoje imię')} 
                                value={user.first_name || ""}
                                
                                onChange={e => {this.onChange("first_name", e.target.value)}} icon={false} placeholder="Imię" />
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="registerUserViewForm-input">
                                <InputComponent 
                                error={backendErrors.last_name || (errors.last_name_error && 'Podaj swoje nazwisko')} 
                                value={user.last_name || ""}
                                
                                onChange={e => {this.onChange("last_name", e.target.value)}} icon={false} placeholder="Nazwisko" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col xs={12} md={6}>
                        <div className="registerUserViewForm-input">
                            <InputComponent 
                            error={backendErrors.password || (errors.password_error && 'Podaj poprawne hasło')} 
                            
                            type="password" onChange={e => {this.onChange("password", e.target.value)}} icon={false} placeholder="Hasło" />
                            <span className="registerViewForm">Hasło musi zawierać min. 8 znaków, wielką literę, znak specjalny i cyfrę.</span>
                            </div>
                    </Col>
                </Row>
           
                <Row>
                    <Col xs={12} md={12}>
                        <div className="registerUserViewForm-input">
                            <CheckboxComponent 
                            checked={user.data_processing_1} 

                            onChange={e => this.onChange('data_processing_1', e.target.checked)} 
                            icon={false} text={checkbox2} />
                        </div>
                    </Col>
                </Row>
                    <div className="registerUserViewForm-buttons">
                        <div className="registerUserViewForm-button-wrapper">
                                <ButtonComponent type="submit" isOrange={true} isBlock={true} text="Zapisz zmiany" />
                            
                        </div>
                    </div>
                </Container>
              </form>
            </Col>
            <Col xs={12} md={1}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(UserDashMyAccView)