import React, { Component } from 'react';
import './CreateOrderView.scss';
import { MAPBOX_TOKEN, serverURL } from '../../config';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-grid-system';
import { Dropdown} from 'semantic-ui-react';
import RegisterInfoComponent from "../../commons/RegisterInfoComponent/RegisterInfoComponent";
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import TextareaComponent from "../../commons/TextareaComponent/TextareaComponent";
import RadioButtonComponent from "../../commons/RadioButtonComponent/RadioButtonComponent";
import LocationInputComponent from "../../commons/LocationInputComponent/LocationInputComponent";
import Geocoder from 'react-mapbox-gl-geocoder'
import ReactMapGL, { Marker } from 'react-map-gl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import ProsComponent from "../../components/ProsComponent/ProsComponent";


const mapAccess = {
  mapboxApiAccessToken: MAPBOX_TOKEN
}

const mapStyle = {
  width: '100%',
  height: 300,
}

const queryParams = {
  country: 'pl',
  language: 'pl',
}

class CreateOrderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: {},
            selectedPlace: null,
            selectedPlaceLatitude: null,
            selectedPlaceLongitude: null,
            customPointSelected: false,
            additionalInfoOpened: false,

            isCustomized: "false",
            isDisabled: false,
            backendErrors: {
              name: false,
              brand: false,
              model: false,
              year: false,
              inspect_description: false,
              city_error: false,
              street_error: false,
              postcode_error: false,
            },
            orderData: {
                name: '',
                brand: '',
                model_id: '',
                year: '',
                vin: '', 
                street: '',
                city: localStorage.getItem('car_city') || "",
                country: "Polska",
                description: '',
                details_url: '',
                inspect_description: '',
                expires: 4,
                important: 1,
                is_full_inspection: true,
            },
          }

        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.onFieldLocationChange = this.onFieldLocationChange.bind(this);
        }

        onSelected = (viewport, item) => {
          this.setState({
            viewport,
            selectedPlace: item,
            selectedPlaceLatitude: item.center[1],
            selectedPlaceLongitude: item.center[0],
            customPointSelected: false,
            orderData: { ...this.state.orderData, city: item.place_name}
          });
        }

      componentDidMount() {

            fetch(`${serverURL}makes`, {
                method: 'GET',
            })
                .then(
                    response => {
                        response.json().then(data => {
                            const modelsArray = data.map(car => {
                                const model = {
                                    key: car.name,
                                    value: car.name,
                                    text: car.name,
                                    models: car.models
                                }
                                return model;
                            })
                            this.setState({carModels: modelsArray})
                        })
                    }
                )
                .catch(err => {
                  throw new Error(err)
                })
           const getCity = localStorage.getItem('car_city');
           const getLat = localStorage.getItem('lat');
           const getLon = localStorage.getItem('lon');
           const getViewport = JSON.parse(localStorage.getItem('viewport'))
           const getSelectedPlace = JSON.parse(localStorage.getItem('selectedPlace'))

           this.setState({
              // viewport: getViewport,
              selectedPlace: getSelectedPlace,
              selectedPlaceLatitude: getLat,
              selectedPlaceLongitude: getLon,
              orderData: {
                ...this.state.orderData,
                city: getCity,
                lat: getLat,
                lon: getLon,
              }
            })

            const geocoderInput = document.querySelector('.react-geocoder > input')
            if (geocoderInput && getCity) geocoderInput.placeholder = getCity
            if (geocoderInput && !getCity) geocoderInput.placeholder = 'Ulica, nr domu, miasto'

            localStorage.removeItem('car_city')
            localStorage.removeItem('lat')
            localStorage.removeItem('lon')
            localStorage.removeItem('viewport')
            localStorage.removeItem('selectedPlace')
          }  
    handleRadioChange(e) {
        this.setState({
            isCustomized: e.target.value,
        });
    }
    onFieldChange(field, value) {
        if (field === "brand") {
            let chosenCarBrand = this.state.carModels.find(element => element.key === value);
            const chosenCarArray = chosenCarBrand.models.map(model => {
                const carModel = {
                    key: model.name,
                    value: model.id,
                    text: model.name,
                }
                return carModel;
            })
        this.setState({modelsList: chosenCarArray})
        }
        this.setState({orderData: {
          ...this.state.orderData,
          [field]: value
        }})
      }

      onFieldLocationChange(data) {
       this.setState({orderData: {
        ...this.state.orderData,
        city: data.title,
        lon: data.value.lon,
        lat: data.value.lat,
      }})
      }

      handleOrderCreation(e) {
        e.preventDefault();
        const token = localStorage.getItem('token');

        fetch(`${serverURL}orders`, {
          method: 'POST',
          headers: new Headers({'Authorization': `bearer ${token}`, 'content-type': 'application/json'}),
          body: JSON.stringify({
            ...this.state.orderData,
            lat: this.state.selectedPlaceLatitude,
            lon: this.state.selectedPlaceLongitude,
          }),
        })
        .then((response) => {
          if (response.ok) {
            NotificationManager.success('Twoje ogłoszenie zostało dodane do wyceny.', 'Zapisano!', 5000)
            this.props.history.push("/moje-zlecenia")
          } else {
            response.json().then(json => {
              NotificationManager.error('W formularzu wystąpiły błędy.', 'Uwaga!', 5000)

              this.setState({
                backendErrors: json.errors
              })
            })
          }
        })
      }

      handleValidation(e) {
        e.preventDefault()
        const token = localStorage.getItem('token');
        this.setState({isButtonDisabled: true})

        fetch(`${serverURL}orders/validate`, {
          method: 'POST',
          headers: new Headers({'content-type': 'application/json'}),
          body: JSON.stringify({
            ...this.state.orderData,
            lat: this.state.selectedPlaceLatitude,
            lon: this.state.selectedPlaceLongitude,
          }),
        })
        .then((response) => {
          if (response.ok) {
              if (token) {
                  this.handleOrderCreation(e);
              } else {
                NotificationManager.success('Zapisaliśmy twoje ogłoszenie i wystawimy je, gdy tylko aktywujesz swoje konto.', 'Zapisano!', 5000)
                localStorage.setItem('newOrder', JSON.stringify({
                  ...this.state.orderData,
                  lat: this.state.selectedPlaceLatitude,
                  lon: this.state.selectedPlaceLongitude,
                }));
                
                response.json().then(json => {
                    localStorage.setItem('temporaryOrderId', json.temporaryOrderId);
                  })
                // this.props.history.push("/zaloguj-sie")
                this.props.history.push("/rejestracja-uzytkownika")

              }
          } else {
            this.setState({isButtonDisabled: false})
            response.json().then(json => {
              this.setState({
                backendErrors: json.errors
              })
            })
          }
        })
      }

  render() {
    const { isCustomized, isButtonDisabled, orderData, backendErrors, viewport, selectedPlace, selectedPlaceLatitude, selectedPlaceLongitude, customPointSelected } = this.state;
    const timeOptions = [
        {key: "1", text: "Dzisiaj", value: 1},
        {key: "2", text: "2 dni", value: 2},
        {key: "3", text: "3 dni", value: 3},
        {key: "4", text: "4 dni", value: 4},
    ];
    const countryList = [{
        id: 0,
        text: "Afganistan",
        value: "Afganistan"
      }, {
        id: 1,
        text: "Albania",
        value: "Albania"
      }, {
        id: 2,
        text: "Algieria",
        value: "Algieria"
      }, {
        id: 3,
        text: "Andora",
        value: "Andora"
      }, {
        id: 4,
        text: "Angola",
        value: "Angola"
      }, {
        id: 5,
        text: "Anguilla",
        value: "Anguilla"
      }, {
        id: 6,
        text: "Antarktyka",
        value: "Antarktyka"
      }, {
        id: 7,
        text: "Antigua i Barbuda",
        value: "Antigua i Barbuda"
      }, {
        id: 8,
        text: "Arabia Saudyjska",
        value: "Arabia Saudyjska"
      }, {
        id: 9,
        text: "Argentyna",
        value: "Argentyna"
      }, {
        id: 10,
        text: "Armenia",
        value: "Armenia"
      }, {
        id: 11,
        text: "Aruba",
        value: "Aruba"
      }, {
        id: 12,
        text: "Australia",
        value: "Australia"
      }, {
        id: 13,
        text: "Austria",
        value: "Austria"
      }, {
        id: 14,
        text: "Azerbejdżan",
        value: "Azerbejdżan"
      }, {
        id: 15,
        text: "Bahamy",
        value: "Bahamy"
      }, {
        id: 16,
        text: "Bahrajn",
        value: "Bahrajn"
      }, {
        id: 17,
        text: "Bangladesz",
        value: "Bangladesz"
      }, {
        id: 18,
        text: "Barbados",
        value: "Barbados"
      }, {
        id: 19,
        text: "Belgia",
        value: "Belgia"
      }, {
        id: 20,
        text: "Belize",
        value: "Belize"
      }, {
        id: 21,
        text: "Benin",
        value: "Benin"
      }, {
        id: 22,
        text: "Bermudy",
        value: "Bermudy"
      }, {
        id: 23,
        text: "Bhutan",
        value: "Bhutan"
      }, {
        id: 24,
        text: "Białoruś",
        value: "Białoruś"
      }, {
        id: 25,
        text: "Boliwia",
        value: "Boliwia"
      }, {
        id: 26,
        text: "Bonaire, Sint Eustatius i Saba",
        value: "Bonaire, Sint Eustatius i Saba"
      }, {
        id: 27,
        text: "Bośnia i Hercegowina",
        value: "Bośnia i Hercegowina"
      }, {
        id: 28,
        text: "Botswana",
        value: "Botswana"
      }, {
        id: 29,
        text: "Brazylia",
        value: "Brazylia"
      }, {
        id: 30,
        text: "Brunei",
        value: "Brunei"
      }, {
        id: 31,
        text: "Brytyjskie Terytorium Oceanu Indyjskiego",
        value: "Brytyjskie Terytorium Oceanu Indyjskiego"
      }, {
        id: 32,
        text: "Brytyjskie Wyspy Dziewicze",
        value: "Brytyjskie Wyspy Dziewicze"
      }, {
        id: 33,
        text: "Bułgaria",
        value: "Bułgaria"
      }, {
        id: 34,
        text: "Burkina Faso",
        value: "Burkina Faso"
      }, {
        id: 35,
        text: "Burundi",
        value: "Burundi"
      }, {
        id: 36,
        text: "Chile",
        value: "Chile"
      }, {
        id: 37,
        text: "Chiny",
        value: "Chiny"
      }, {
        id: 38,
        text: "Chorwacja",
        value: "Chorwacja"
      }, {
        id: 39,
        text: "Curaçao",
        value: "Curaçao"
      }, {
        id: 40,
        text: "Cypr",
        value: "Cypr"
      }, {
        id: 41,
        text: "Czad",
        value: "Czad"
      }, {
        id: 42,
        text: "Czarnogóra",
        value: "Czarnogóra"
      }, {
        id: 43,
        text: "Czechy",
        value: "Czechy"
      }, {
        id: 44,
        text: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
        value: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych"
      }, {
        id: 45,
        text: "Dania",
        value: "Dania"
      }, {
        id: 46,
        text: "Demokratyczna Republika Konga",
        value: "Demokratyczna Republika Konga"
      }, {
        id: 47,
        text: "Dominika",
        value: "Dominika"
      }, {
        id: 48,
        text: "Dominikana",
        value: "Dominikana"
      }, {
        id: 49,
        text: "Dżibuti",
        value: "Dżibuti"
      }, {
        id: 50,
        text: "Egipt",
        value: "Egipt"
      }, {
        id: 51,
        text: "Ekwador",
        value: "Ekwador"
      }, {
        id: 52,
        text: "Erytrea",
        value: "Erytrea"
      }, {
        id: 53,
        text: "Estonia",
        value: "Estonia"
      }, {
        id: 54,
        text: "Etiopia",
        value: "Etiopia"
      }, {
        id: 55,
        text: "Falklandy",
        value: "Falklandy"
      }, {
        id: 56,
        text: "Fidżi",
        value: "Fidżi"
      }, {
        id: 57,
        text: "Filipiny",
        value: "Filipiny"
      }, {
        id: 58,
        text: "Finlandia",
        value: "Finlandia"
      }, {
        id: 59,
        text: "Francja",
        value: "Francja"
      }, {
        id: 60,
        text: "Francuskie Terytoria Południowe i Antarktyczne",
        value: "Francuskie Terytoria Południowe i Antarktyczne"
      }, {
        id: 61,
        text: "Gabon",
        value: "Gabon"
      }, {
        id: 62,
        text: "Gambia",
        value: "Gambia"
      }, {
        id: 63,
        text: "Georgia Południowa i Sandwich Południowy",
        value: "Georgia Południowa i Sandwich Południowy"
      }, {
        id: 64,
        text: "Ghana",
        value: "Ghana"
      }, {
        id: 65,
        text: "Gibraltar",
        value: "Gibraltar"
      }, {
        id: 66,
        text: "Grecja",
        value: "Grecja"
      }, {
        id: 67,
        text: "Grenada",
        value: "Grenada"
      }, {
        id: 68,
        text: "Grenlandia",
        value: "Grenlandia"
      }, {
        id: 69,
        text: "Gruzja",
        value: "Gruzja"
      }, {
        id: 70,
        text: "Guam",
        value: "Guam"
      }, {
        id: 71,
        text: "Guernsey",
        value: "Guernsey"
      }, {
        id: 72,
        text: "Gujana Francuska",
        value: "Gujana Francuska"
      }, {
        id: 73,
        text: "Gujana",
        value: "Gujana"
      }, {
        id: 74,
        text: "Gwadelupa",
        value: "Gwadelupa"
      }, {
        id: 75,
        text: "Gwatemala",
        value: "Gwatemala"
      }, {
        id: 76,
        text: "Gwinea Bissau",
        value: "Gwinea Bissau"
      }, {
        id: 77,
        text: "Gwinea Równikowa",
        value: "Gwinea Równikowa"
      }, {
        id: 78,
        text: "Gwinea",
        value: "Gwinea"
      }, {
        id: 79,
        text: "Haiti",
        value: "Haiti"
      }, {
        id: 80,
        text: "Hiszpania",
        value: "Hiszpania"
      }, {
        id: 81,
        text: "Holandia",
        value: "Holandia"
      }, {
        id: 82,
        text: "Honduras",
        value: "Honduras"
      }, {
        id: 83,
        text: "Hongkong",
        value: "Hongkong"
      }, {
        id: 84,
        text: "Indie",
        value: "Indie"
      }, {
        id: 85,
        text: "Indonezja",
        value: "Indonezja"
      }, {
        id: 86,
        text: "Irak",
        value: "Irak"
      }, {
        id: 87,
        text: "Iran",
        value: "Iran"
      }, {
        id: 88,
        text: "Irlandia",
        value: "Irlandia"
      }, {
        id: 89,
        text: "Islandia",
        value: "Islandia"
      }, {
        id: 90,
        text: "Izrael",
        value: "Izrael"
      }, {
        id: 91,
        text: "Jamajka",
        value: "Jamajka"
      }, {
        id: 92,
        text: "Japonia",
        value: "Japonia"
      }, {
        id: 93,
        text: "Jemen",
        value: "Jemen"
      }, {
        id: 94,
        text: "Jersey",
        value: "Jersey"
      }, {
        id: 95,
        text: "Jordania",
        value: "Jordania"
      }, {
        id: 96,
        text: "Kajmany",
        value: "Kajmany"
      }, {
        id: 97,
        text: "Kambodża",
        value: "Kambodża"
      }, {
        id: 98,
        text: "Kamerun",
        value: "Kamerun"
      }, {
        id: 99,
        text: "Kanada",
        value: "Kanada"
      }, {
        id: 100,
        text: "Katar",
        value: "Katar"
      }, {
        id: 101,
        text: "Kazachstan",
        value: "Kazachstan"
      }, {
        id: 102,
        text: "Kenia",
        value: "Kenia"
      }, {
        id: 103,
        text: "Kirgistan",
        value: "Kirgistan"
      }, {
        id: 104,
        text: "Kiribati",
        value: "Kiribati"
      }, {
        id: 105,
        text: "Kolumbia",
        value: "Kolumbia"
      }, {
        id: 106,
        text: "Komory",
        value: "Komory"
      }, {
        id: 107,
        text: "Kongo",
        value: "Kongo"
      }, {
        id: 108,
        text: "Korea Południowa",
        value: "Korea Południowa"
      }, {
        id: 109,
        text: "Korea Północna",
        value: "Korea Północna"
      }, {
        id: 110,
        text: "Kostaryka",
        value: "Kostaryka"
      }, {
        id: 111,
        text: "Kuba",
        value: "Kuba"
      }, {
        id: 112,
        text: "Kuwejt",
        value: "Kuwejt"
      }, {
        id: 113,
        text: "Laos",
        value: "Laos"
      }, {
        id: 114,
        text: "Lesotho",
        value: "Lesotho"
      }, {
        id: 115,
        text: "Liban",
        value: "Liban"
      }, {
        id: 116,
        text: "Liberia",
        value: "Liberia"
      }, {
        id: 117,
        text: "Libia",
        value: "Libia"
      }, {
        id: 118,
        text: "Liechtenstein",
        value: "Liechtenstein"
      }, {
        id: 119,
        text: "Litwa",
        value: "Litwa"
      }, {
        id: 120,
        text: "Luksemburg",
        value: "Luksemburg"
      }, {
        id: 121,
        text: "Łotwa",
        value: "Łotwa"
      }, {
        id: 122,
        text: "Macedonia",
        value: "Macedonia"
      }, {
        id: 123,
        text: "Madagaskar",
        value: "Madagaskar"
      }, {
        id: 124,
        text: "Majotta",
        value: "Majotta"
      }, {
        id: 125,
        text: "Makau",
        value: "Makau"
      }, {
        id: 126,
        text: "Malawi",
        value: "Malawi"
      }, {
        id: 127,
        text: "Malediwy",
        value: "Malediwy"
      }, {
        id: 128,
        text: "Malezja",
        value: "Malezja"
      }, {
        id: 129,
        text: "Mali",
        value: "Mali"
      }, {
        id: 130,
        text: "Malta",
        value: "Malta"
      }, {
        id: 131,
        text: "Mariany Północne",
        value: "Mariany Północne"
      }, {
        id: 132,
        text: "Maroko",
        value: "Maroko"
      }, {
        id: 133,
        text: "Martynika",
        value: "Martynika"
      }, {
        id: 134,
        text: "Mauretania",
        value: "Mauretania"
      }, {
        id: 135,
        text: "Mauritius",
        value: "Mauritius"
      }, {
        id: 136,
        text: "Meksyk",
        value: "Meksyk"
      }, {
        id: 137,
        text: "Mikronezja",
        value: "Mikronezja"
      }, {
        id: 138,
        text: "Mjanma",
        value: "Mjanma"
      }, {
        id: 139,
        text: "Mołdawia",
        value: "Mołdawia"
      }, {
        id: 140,
        text: "Monako",
        value: "Monako"
      }, {
        id: 141,
        text: "Mongolia",
        value: "Mongolia"
      }, {
        id: 142,
        text: "Montserrat",
        value: "Montserrat"
      }, {
        id: 143,
        text: "Mozambik",
        value: "Mozambik"
      }, {
        id: 144,
        text: "Namibia",
        value: "Namibia"
      }, {
        id: 145,
        text: "Nauru",
        value: "Nauru"
      }, {
        id: 146,
        text: "Nepal",
        value: "Nepal"
      }, {
        id: 147,
        text: "Niemcy",
        value: "Niemcy"
      }, {
        id: 148,
        text: "Niger",
        value: "Niger"
      }, {
        id: 149,
        text: "Nigeria",
        value: "Nigeria"
      }, {
        id: 150,
        text: "Nikaragua",
        value: "Nikaragua"
      }, {
        id: 151,
        text: "Niue",
        value: "Niue"
      }, {
        id: 152,
        text: "Norfolk",
        value: "Norfolk"
      }, {
        id: 153,
        text: "Norwegia",
        value: "Norwegia"
      }, {
        id: 154,
        text: "Nowa Kaledonia",
        value: "Nowa Kaledonia"
      }, {
        id: 155,
        text: "Nowa Zelandia",
        value: "Nowa Zelandia"
      }, {
        id: 156,
        text: "Oman",
        value: "Oman"
      }, {
        id: 157,
        text: "Pakistan",
        value: "Pakistan"
      }, {
        id: 158,
        text: "Palau",
        value: "Palau"
      }, {
        id: 159,
        text: "Palestyna",
        value: "Palestyna"
      }, {
        id: 160,
        text: "Panama",
        value: "Panama"
      }, {
        id: 161,
        text: "Papua-Nowa Gwinea",
        value: "Papua-Nowa Gwinea"
      }, {
        id: 162,
        text: "Paragwaj",
        value: "Paragwaj"
      }, {
        id: 163,
        text: "Peru",
        value: "Peru"
      }, {
        id: 164,
        text: "Pitcairn",
        value: "Pitcairn"
      }, {
        id: 165,
        text: "Polinezja Francuska",
        value: "Polinezja Francuska"
      }, {
        id: 166,
        text: "Polska",
        value: "Polska"
      }, {
        id: 167,
        text: "Portoryko",
        value: "Portoryko"
      }, {
        id: 168,
        text: "Portugalia",
        value: "Portugalia"
      }, {
        id: 169,
        text: "Republika Południowej Afryki",
        value: "Republika Południowej Afryki"
      }, {
        id: 170,
        text: "Republika Środkowoafrykańska",
        value: "Republika Środkowoafrykańska"
      }, {
        id: 171,
        text: "Republika Zielonego Przylądka",
        value: "Republika Zielonego Przylądka"
      }, {
        id: 172,
        text: "Reunion",
        value: "Reunion"
      }, {
        id: 173,
        text: "Rosja",
        value: "Rosja"
      }, {
        id: 174,
        text: "Rumunia",
        value: "Rumunia"
      }, {
        id: 175,
        text: "Rwanda",
        value: "Rwanda"
      }, {
        id: 176,
        text: "Sahara Zachodnia",
        value: "Sahara Zachodnia"
      }, {
        id: 177,
        text: "Saint Kitts i Nevis",
        value: "Saint Kitts i Nevis"
      }, {
        id: 178,
        text: "Saint Lucia",
        value: "Saint Lucia"
      }, {
        id: 179,
        text: "Saint Vincent i Grenadyny",
        value: "Saint Vincent i Grenadyny"
      }, {
        id: 180,
        text: "Saint-Barthélemy",
        value: "Saint-Barthélemy"
      }, {
        id: 181,
        text: "Saint-Martin",
        value: "Saint-Martin"
      }, {
        id: 182,
        text: "Saint-Pierre i Miquelon",
        value: "Saint-Pierre i Miquelon"
      }, {
        id: 183,
        text: "Salwador",
        value: "Salwador"
      }, {
        id: 184,
        text: "Samoa Amerykańskie",
        value: "Samoa Amerykańskie"
      }, {
        id: 185,
        text: "Samoa",
        value: "Samoa"
      }, {
        id: 186,
        text: "San Marino",
        value: "San Marino"
      }, {
        id: 187,
        text: "Senegal",
        value: "Senegal"
      }, {
        id: 188,
        text: "Serbia",
        value: "Serbia"
      }, {
        id: 189,
        text: "Seszele",
        value: "Seszele"
      }, {
        id: 190,
        text: "Sierra Leone",
        value: "Sierra Leone"
      }, {
        id: 191,
        text: "Singapur",
        value: "Singapur"
      }, {
        id: 192,
        text: "Sint Maarten",
        value: "Sint Maarten"
      }, {
        id: 193,
        text: "Słowacja",
        value: "Słowacja"
      }, {
        id: 194,
        text: "Słowenia",
        value: "Słowenia"
      }, {
        id: 195,
        text: "Somalia",
        value: "Somalia"
      }, {
        id: 196,
        text: "Sri Lanka",
        value: "Sri Lanka"
      }, {
        id: 197,
        text: "Stany Zjednoczone",
        value: "Stany Zjednoczone"
      }, {
        id: 198,
        text: "Suazi",
        value: "Suazi"
      }, {
        id: 199,
        text: "Sudan",
        value: "Sudan"
      }, {
        id: 200,
        text: "Sudan Południowy",
        value: "Sudan Południowy"
      }, {
        id: 201,
        text: "Surinam",
        value: "Surinam"
      }, {
        id: 202,
        text: "Svalbard i Jan Mayen",
        value: "Svalbard i Jan Mayen"
      }, {
        id: 203,
        text: "Syria",
        value: "Syria"
      }, {
        id: 204,
        text: "Szwajcaria",
        value: "Szwajcaria"
      }, {
        id: 205,
        text: "Szwecja",
        value: "Szwecja"
      }, {
        id: 206,
        text: "Tadżykistan",
        value: "Tadżykistan"
      }, {
        id: 207,
        text: "Tajlandia",
        value: "Tajlandia"
      }, {
        id: 208,
        text: "Tajwan",
        value: "Tajwan"
      }, {
        id: 209,
        text: "Tanzania",
        value: "Tanzania"
      }, {
        id: 210,
        text: "Timor Wschodni",
        value: "Timor Wschodni"
      }, {
        id: 211,
        text: "Togo",
        value: "Togo"
      }, {
        id: 212,
        text: "Tokelau",
        value: "Tokelau"
      }, {
        id: 213,
        text: "Tonga",
        value: "Tonga"
      }, {
        id: 214,
        text: "Trynidad i Tobago",
        value: "Trynidad i Tobago"
      }, {
        id: 215,
        text: "Tunezja",
        value: "Tunezja"
      }, {
        id: 216,
        text: "Turcja",
        value: "Turcja"
      }, {
        id: 217,
        text: "Turkmenistan",
        value: "Turkmenistan"
      }, {
        id: 218,
        text: "Turks i Caicos",
        value: "Turks i Caicos"
      }, {
        id: 219,
        text: "Tuvalu",
        value: "Tuvalu"
      }, {
        id: 220,
        text: "Uganda",
        value: "Uganda"
      }, {
        id: 221,
        text: "Ukraina",
        value: "Ukraina"
      }, {
        id: 222,
        text: "Urugwaj",
        value: "Urugwaj"
      }, {
        id: 223,
        text: "Uzbekistan",
        value: "Uzbekistan"
      }, {
        id: 224,
        text: "Vanuatu",
        value: "Vanuatu"
      }, {
        id: 225,
        text: "Wallis i Futuna",
        value: "Wallis i Futuna"
      }, {
        id: 226,
        text: "Watykan",
        value: "Watykan"
      }, {
        id: 227,
        text: "Wenezuela",
        value: "Wenezuela"
      }, {
        id: 228,
        text: "Węgry",
        value: "Węgry"
      }, {
        id: 229,
        text: "Wielka Brytania",
        value: "Wielka Brytania"
      }, {
        id: 230,
        text: "Wietnam",
        value: "Wietnam"
      }, {
        id: 231,
        text: "Włochy",
        value: "Włochy"
      }, {
        id: 232,
        text: "Wybrzeże Kości Słoniowej",
        value: "Wybrzeże Kości Słoniowej"
      }, {
        id: 233,
        text: "Wyspa Bouveta",
        value: "Wyspa Bouveta"
      }, {
        id: 234,
        text: "Wyspa Bożego Narodzenia",
        value: "Wyspa Bożego Narodzenia"
      }, {
        id: 235,
        text: "Wyspa Man",
        value: "Wyspa Man"
      }, {
        id: 236,
        text: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
        value: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha"
      }, {
        id: 237,
        text: "Wyspy Alandzkie",
        value: "Wyspy Alandzkie"
      }, {
        id: 238,
        text: "Wyspy Cooka",
        value: "Wyspy Cooka"
      }, {
        id: 239,
        text: "Wyspy Dziewicze Stanów Zjednoczonych",
        value: "Wyspy Dziewicze Stanów Zjednoczonych"
      }, {
        id: 240,
        text: "Wyspy Heard i McDonalda",
        value: "Wyspy Heard i McDonalda"
      }, {
        id: 241,
        text: "Wyspy Kokosowe",
        value: "Wyspy Kokosowe"
      }, {
        id: 242,
        text: "Wyspy Marshalla",
        value: "Wyspy Marshalla"
      }, {
        id: 243,
        text: "Wyspy Owcze",
        value: "Wyspy Owcze"
      }, {
        id: 244,
        text: "Wyspy Salomona",
        value: "Wyspy Salomona"
      }, {
        id: 245,
        text: "Wyspy Świętego Tomasza i Książęca",
        value: "Wyspy Świętego Tomasza i Książęca"
      }, {
        id: 246,
        text: "Zambia",
        value: "Zambia"
      }, {
        id: 247,
        text: "Zimbabwe",
        value: "Zimbabwe"
      }, {
        id: 248,
        text: "Zjednoczone Emiraty Arabskie",
        value: "Zjednoczone Emiraty Arabskie"
      }]

    return (
      <React.Fragment>
        <form className="createOrder-container" onSubmit={this.handleOrderCreation}>
            <Container className="createOrder-container">
                <Row>
                    <Col xs={12} md={12}>
                      <h2 className="createOrder-header">Zleć sprawdzenie</h2>
                      <br/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={0} md={2} />
                    <Col xs={12} md={8}>
                        <Container>
                            <Row>
                                <Col xs={12} md={12}>
                                    <h3 className="createOrder-header-3">Pojazd i lokalizacja</h3>
                                </Col>
                            </Row>
                            <Row className="createOrderRow">
                                <Col xs={12} md={6}>
                                <div className="createOrderViewForm-input createOrder-input">
                                <Dropdown placeholder="Marka" 
                                    value={orderData.model}
                                    fluid
                                    selection
                                    error={backendErrors && backendErrors.model}
                                    search
                                    options={this.state.carModels}
                                    onChange={(e, { value }) => this.onFieldChange("brand", value)}
                                />
                            </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="createOrderViewForm-input createOrder-input">
                                    <Dropdown placeholder="Model" 
                                        value={orderData.model}
                                        fluid
                                        selection
                                        search
                                        error={backendErrors && backendErrors.model}
                                        options={this.state.modelsList}
                                        onChange={(e, { value }) => this.onFieldChange("model_id", value)}
                                    />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="createOrderRow">
                              <Col xs={12} md={12}>
                                <div className={`geocoder-wrapper ${(backendErrors.lng || backendErrors.lat) ? 'geocoder-error' : ''}`}>
                                  <Geocoder 
                                    {...mapAccess} 
                                    onSelected={this.onSelected}
                                    viewport={viewport}
                                    hideOnSelect={true}
                                    updateInputOnSelect={true}
                                    queryParams={queryParams}
                                  />
                                  <i aria-hidden="true" class="search icon search-icon"></i>
                                  { (backendErrors.lng || backendErrors.lat) && <div className="input-error">Wybierz lokalizację pojazdu</div> }
                                </div>
                                <div className="geocoder-info-text">Podaj dokładne miejsce pojazdu razem z ulicą i numerem domu oraz wybierz adres z podpowiedzi.</div>
                              </Col>
                          </Row>
                        </Container>
                    </Col>
                    <Col xs={0} md={2} />
                </Row>
            </Container>
            
            <Container>
              <Row>
                <Col xs={0} md={2} />
                <Col xs={12} md={8}>
                  <h3 className="additional-info-header" onClick={() => this.setState({
                    additionalInfoOpened: !this.state.additionalInfoOpened
                  })}>
                    <span>
                      <FontAwesomeIcon className="dualButton-icon" icon={this.state.additionalInfoOpened ? faMinus : faPlus} />
                    </span>
                    <span>Chcę podać dodatkowe informacje</span>
                  </h3>
                  <Container className="createOrder-container">
                      { this.state.additionalInfoOpened && <div>
                            <Row>
                                <Col xs={12} md={12}>
                                    <h3 className="createOrder-header-3">Zakres inspekcji</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} className="createOrderViewForm-column-small">
                                    <div className="createOrderViewForm-radio-wrapper">
                                        <RadioButtonComponent 
                                            name="isCustomized" 
                                            value={true}
                                            checked={this.state.orderData.is_full_inspection === true} 
                                            onChange={e => this.onFieldChange('is_full_inspection', true)} 
                                        />
                                    </div>
                                    <div className="createOrderViewForm-input-text">
                                        <b>Kompleksowa -</b> Pełne sprawdzenie pojazdu. <a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Raport.pdf" target="_blank">Wzór raportu</a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} className="createOrderViewForm-column-small">
                                    <div className="createOrderViewForm-radio-wrapper">
                                        <RadioButtonComponent 
                                            name="isCustomized" 
                                            value={false}
                                            checked={this.state.orderData.is_full_inspection === false} 
                                            onChange={e => this.onFieldChange('is_full_inspection', false)} 
                                        />
                                    </div>
                                    <div className="createOrderViewForm-input-text">
                                        <b>Indywidualna -</b> napisz, co ma zostać sprawdzone
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                { this.state.orderData.is_full_inspection === false ? <TextareaComponent 
                                onChange={e => this.onFieldChange('inspect_description', e.target.value)}
                                placeholder="Opisz szczegóły inspekcji" /> : "" }
                                </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                  <Row>
                                      <Col xs={12} md={12}>
                                          <div className="createOrderViewForm-input">
                                              <InputComponent icon={false} 
                                              onChange={e => this.onFieldChange('details_url', e.target.value)}
                                              placeholder="Link do ogłoszenia" />
                                          </div>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col xs={12} md={12}>
                                          <div className="createOrderViewForm-input">
                                              <InputComponent icon={false} 
                                              onChange={e => this.onFieldChange('vin', e.target.value)}
                                              placeholder="Numer VIN" />
                                          </div>
                                      </Col>
                                  </Row>
                              </Col>
                              {/* <Col xs={12} md={12} className="createOrderViewForm-column"> */}
                                  {/* <Row> */}
                                      {/* <Col xs={12} md={12}> */}
                                          <div className="createOrderViewForm-input textarea-container">
                                              <TextareaComponent 
                                              onChange={e => this.onFieldChange('description', e.target.value)}
                                              placeholder="Dodatkowe informacje" />
                                              <div className="createOrderViewForm-info"><span className="accent">Uwaga! </span>Nie zamieszczaj tutaj swoich danych kontaktowych.</div>
                                          </div>
                                      {/* </Col> */}
                                  {/* </Row> */}
                              {/* </Col> */}
                          </Row>
                      </div> }
                    </Container>
                </Col>
                <Col xs={0} md={2} />
              </Row>  
            </Container>
            <Container>
                <Row>
                    <Col xs={0} md={2} />
                    <Col xs={12} md={8} className="createOrderViewForm-column">
                        <div className="createOrderViewForm-buttons">
                            <div className="createOrderViewForm-button-wrapper">
                                    <ButtonComponent 
                                        isOrange={true} 
                                        isBlock={true} 
                                        onClick={e => this.handleValidation(e)}
                                        disabled={this.state.isButtonDisabled}
                                        text="Sprawdzam"
                                    />
                            </div>
                        </div>
                    </Col>
                    <Col xs={0} md={2} />
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={0} md={2} />
                    <Col xs={12} md={8} className="createOrderViewForm-column">
                    <div className="register-info-wrapper">
                      <ul className="register-info-list">
                        <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /><span className="register-info-special">0zł</span> za wystawienie zlecenia</li>
                        <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /><span className="register-info-special">Oględziny</span> u sprzedawcy</li>
                        <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} />Wygodne <span className="register-info-special">porównanie ofert oraz opinii</span> sprawdzających</li>
                        <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /><span className="register-info-special">Brak zobowiązań</span> - nie musisz akceptować żadnej oferty</li>
                      </ul>
                    </div>
                    </Col>
                    <Col xs={0} md={2} />
                </Row>
            </Container>
        </form>
      </React.Fragment>
    );
  }
}

export default withRouter(CreateOrderView);