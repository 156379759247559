import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import ServiceSideNavComponent from "../../components/SideNavComponent/ServiceSideNavComponent";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import OrdersGridComponent from "../../components/GridComponent/OrdersGridComponent";
import { serverURL } from '../../config';


class ServiceDashOrdersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
    };
    }

  componentWillMount() {
    const token = localStorage.getItem('token');
    const radius = localStorage.getItem('radius');

    fetch(`${serverURL}orders/my`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `bearer ${token}`, 
      }),
    })
    .then(
      (response) => {
        if (response.ok) {
          response.json().then(json => {

            this.setState({orders: json})
          })
        } else {
          this.props.history.push(`/zaloguj`);
        }
    
        }
    )
  }

  render() {
    const { orders } = this.state
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={2}>
              <ServiceSideNavComponent />
            </Col>
            <Col xs={12} md={8}>
            {!orders ? "Brak zleceń" :
            <PaginationComponent
              data={orders}
            >
              <OrdersGridComponent />
            </PaginationComponent>
          }
            </Col>
            <Col xs={12} md={1}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(ServiceDashOrdersView)