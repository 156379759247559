const serverUrls = {
  // 'localhost': 'http://127.0.0.1:8000/api/',
  'localhost': 'http://testing.moto-detektyw.wenus.softnauts.com/api/',
  'testing.moto-detektyw.wenus.softnauts.com': 'http://testing.moto-detektyw.wenus.softnauts.com/api/',
  'www.testing.moto-detektyw.wenus.softnauts.com': 'http://testing.moto-detektyw.wenus.softnauts.com/api/',
  'moto-detektyw.wenus.softnauts.com': 'http://testing.moto-detektyw.wenus.softnauts.com/api/',
  'www.moto-detektyw.wenus.softnauts.com': 'http://testing.moto-detektyw.wenus.softnauts.com/api/',
  'moto.softnauts.pl': 'http://moto.softnauts.pl/api/',
  'www.moto.softnauts.pl': 'http://moto.softnauts.pl/api/',
  'motodetektyw.pl': 'https://motodetektyw.pl/api/',
  'www.motodetektyw.pl': 'https://motodetektyw.pl/api/',
}


export const serverURL = serverUrls[window.location.hostname]
export const MAPBOX_TOKEN = 'pk.eyJ1Ijoia29uZHppbzIyIiwiYSI6ImNraGFuY3F4NDE1eWMyeG84Mjhxbmg2c3oifQ.xlkw3kJsLs8JnFFjjWuDew'