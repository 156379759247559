import React, { Component } from 'react';
import './NotFound.scss';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import { NotificationManager } from 'react-notifications';
import { NavLink, Link } from "react-router-dom";



class NotFound extends Component {

  
  render() {
    
    return (
      <React.Fragment>
      
        <h2 className="not-found">404</h2>
        <span className="nf-message">Ups... Coś poszło nie tak :(</span>
        <span className="nf-message">Nie możemy znaleźć takiej strony.</span>
        <span className="nf-message">Przejdź na <Link to="/">Stronę główną</Link></span>


      </React.Fragment>
    );
  }
}

export default withRouter(NotFound);