import React, { Component } from 'react';
import './RegisterServiceViewForm.scss';
import RegisterServiceLastStepViewForm from "./RegisterServiceLastStepViewForm";
import RegisterServiceFirstStepViewForm from "./RegisterServiceFirstStepViewForm";
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { serverURL } from '../../../config';


class RegisterServiceViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLastStep: false,
      userData: {
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        password: '',
        type: 2,
        company_name: '',
        company_nip: '',
        company_vat: '',
        country: '',
        city: '',
        street: '',
        postcode: '',
        company_type: 0,
        company_info: '',
        company_location: '',
        selectedPlaceLatitude: null,
        selectedPlaceLongitude: null,
        radius: 0,
        introduced_by: "",
        terms_of_use: false,
        data_processing_1: false,
        data_processing_2: false,
        viewport: {},
      },
      backendErrors: {},
    }
    this.setUserDataField = this.setUserDataField.bind(this)
    this.setMapCoordinates = this.setMapCoordinates.bind(this)
    this.handleStepChange = this.handleStepChange.bind(this)
    this.handleUserRegister = this.handleUserRegister.bind(this)
  }

  setUserDataField(field, value) {
    this.setState({
      userData: {
        ...this.state.userData,
        [field]: value
      }
    })
  }

  setMapCoordinates(viewport, item) {
    this.setState({
      userData: {
        ...this.state.userData,
        company_location: item.place_name,
        selectedPlaceLatitude: item.center[1],
        selectedPlaceLongitude: item.center[0],
        viewport: viewport,
      }
    })
  }

  handleStepChange(isLastStep) {
    this.setState({
      isLastStep: isLastStep,
    })
  }

  handleUserRegister(e) {
    e.preventDefault()
    fetch(`${serverURL}register`, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        ...this.state.userData,
        notify_is_new_order: true,
        lat: this.state.userData.selectedPlaceLatitude,
        lon: this.state.userData.selectedPlaceLongitude,
      }),
    })
      .then((response) => {

        if (response.ok) {
          NotificationManager.success('Twoje konto zostało utworzone. Link aktywacyjny wysłaliśmy na twojego maila.', 'Gratulacje', 5000)
          this.props.history.push("/")
        } else {
          response.json().then(json => {
            this.setState({
              backendErrors: json.errors
            })
          })
        }
      })
  }

  render() {
    const { isLastStep } = this.state

    return (
      <React.Fragment>
        <div className="registerServiceViewForm-container">
          <form className="registerServiceViewForm-form">
            {isLastStep === true
              ? <RegisterServiceLastStepViewForm
                userData={this.state.userData}
                backendErrors={this.state.backendErrors}
                onFieldChange={this.setUserDataField}
                onLocationChange={this.setMapCoordinates}
                onSubmit={this.handleUserRegister}
                onHandleStepChange={this.handleStepChange} />
              : <RegisterServiceFirstStepViewForm
                userData={this.state.userData}
                backendErrors={this.state.backendErrors}
                onFieldChange={this.setUserDataField}
                onHandleStepChange={this.handleStepChange} />}
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterServiceViewForm)
