import React, { Component } from 'react';
import './ResetPasswordView.scss';

import { Container, Row, Col } from 'react-grid-system';
import { Link, withRouter } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import { serverURL } from '../../config';

class ResetPasswordView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        email: '',
        backendErrors: {},
        errors: {
            email_error: false,
          }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange= this.onChange.bind(this);
    this.validateInputs = this.validateInputs.bind(this)


    }

    onChange(field, value) {
        this.setState({ [field]: value });
    }
 
    
      validateEmail(email) {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
      }
      validateInputs(e) {
        const { email } = this.state
        e.preventDefault()
        const email_error = !this.validateEmail(email);

        this.setState({
          errors: {
            email_error,
          }
        })
      }
    handleSubmit(event) {
        event.preventDefault();
        const email = { email: this.state.email }
        const data = JSON.stringify(email);
       fetch(`${serverURL}reset-password`, {
            method: 'POST',
            body: data,
        })
        .then((response) => {
          if (response.ok) {
            NotificationManager.success('Jeśli podany adres e-mail znajduje się w naszej bazie, znajdziesz na nim link do resetu hasła.', 'Gratulacje', 5000)
            
            this.props.history.push("/")
          } else {
            response.json().then(json => {
              this.setState({
                backendErrors: { email: "Podaj poprawny adres e-mail."}
              })
            })
          }
        }) 
    }
  render() {
    const { email, backendErrors, errors } = this.state;

    return (
        <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className="registerUser-header">Zresetuj hasło</h2>
            </Col>
          </Row>
          <Row>
          <Col className="registerUser-column" xs={12} md={4}></Col>
            <Col className="registerUser-column" xs={12} md={4}>
              <div className="registerUser-column-inner">
                <h3 className="registerUser-header-3">Podaj adres e-mail</h3>
                <div className="registerUserViewForm-container">
                <form className="registerUserViewForm-form" onSubmit={this.handleSubmit}>
                  <Container>
                      <Row>
                          <Col xs={12} md={12}>
                          <div className="registerUserViewForm-input">
                          <InputComponent 
                          value={email}
                          error={backendErrors.email || (errors.email_error && 'Podaj poprawny adres email')} 
                          onChange={e => {this.onChange("email", e.target.value)}} icon={false} placeholder="Adres e-mail" />
                      </div>
                          </Col>
                      </Row>
      
                  </Container>
                  <div className="registerUserViewForm-buttons">
                      <div className="registerUserViewForm-button-wrapper">
                              <ButtonComponent type="submit" isOrange={true} isBlock={true} text="Zresetuj hasło" />
                          
                      </div>
                  </div>
              </form>
              </div>
              </div>
            </Col>
            <Col className="registerUser-column" xs={12} md={4}></Col>
          </Row>
        </Container>
      </React.Fragment>
   
    );
  }
}

export default withRouter(ResetPasswordView);
