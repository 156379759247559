import React, { Component } from 'react';
import './RegisterServiceViewForm.scss';
import { Container, Row, Col } from 'react-grid-system';

import ButtonComponent from "../../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../../commons/InputComponent/InputComponent";
import RadioButtonComponent from "../../../commons/RadioButtonComponent/RadioButtonComponent";
import RegisterInfoComponentService from "../../../commons/RegisterInfoComponentService/RegisterInfoComponentService";
import RegisterServiceCompanyForm from "./RegisterServiceCompanyForm";
import RegisterServicePublicPersonForm from "./RegisterServicePublicPersonForm";


class RegisterServiceViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        email_error: false,
        phone_error: false,
        first_name_error: false,
        last_name_error: false,
        password_error: false,
        company_name_error: false,
        company_nip_error: false,
        company_vat_error: false,
        country_error: false,
        city_error: false,
        street_error: false,
        postcode_error: false,
      }
    }
    this.validateInputs = this.validateInputs.bind(this)
  }

  validatePassword(password) {
    // eslint-disable-next-line
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
    return re.test(String(password))
  }

  validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

 

  validateInputs(e) {
    const { userData } = this.props
    e.preventDefault()
    const email_error = !this.validateEmail(userData.email)
    const phone_error = userData.phone.length < 9
    const first_name_error = userData.first_name.length < 2
    const last_name_error = userData.last_name.length < 2
    const password_error = !this.validatePassword(userData.password)
    const company_name_error = userData.company_type === 1 && !userData.company_name
    const company_nip_error = userData.company_type === 1 && userData.company_nip.length < 9
    const company_vat_error = userData.company_type === 1 && userData.company_vat !== true && userData.company_vat !== false 
    const country_error = userData.country.length < 2
    const city_error = userData.city.length < 2
    const street_error = userData.street.length < 2
    const postcode_error = userData.postcode.length < 6

    if (
      !email_error &&
      !phone_error &&
      !password_error &&
      !first_name_error &&
      !last_name_error &&
      !company_name_error &&
      !company_nip_error &&
      !company_vat_error &&
      !country_error &&
      !city_error &&
      !street_error &&
      !postcode_error
    ) {
      this.props.onHandleStepChange(true)
    }

    this.setState({
      errors: {
        email_error,
        phone_error,
        first_name_error,
        password_error,
        last_name_error,
        company_name_error,
        company_nip_error,
        company_vat_error,
        country_error,
        city_error,
        street_error,
        postcode_error,
      }
    })
  }

  render() {
    const { userData, onFieldChange, backendErrors } = this.props
    const { errors } = this.state

    return (
      <React.Fragment>
        <div className="registerServiceViewForm-container">
            <div className="registerServiceViewForm-step-1">
                <Container>
                <Row>
                    <Col xs={12} md={12}>
                      <h2 className="registerService-header">Rejestracja - chcę sprawdzać pojazdy</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} className="registerServiceViewForm-column">
                        <Container>
                            <Row>
                                <Col xs={12} md={12}>
                                    <h3 className="registerService-header-3">Dane logowania</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <div className="registerServiceViewForm-input">
                                        <InputComponent 
                                            value={userData.email}
                                            error={backendErrors.email || (errors.email_error && 'Podaj poprawny adres email')} 
                                            icon={false} 
                                            placeholder="Adres e-mail" 
                                            onChange={e => {onFieldChange("email", e.target.value)}} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <div className="registerServiceViewForm-input">
                                        <InputComponent 
                                            value={userData.phone} 
                                            error={backendErrors.phone || (errors.phone_error && 'Podaj poprawny numer telefonu')} 
                                            icon={false} 
                                            placeholder="Numer telefonu" 
                                            onChange={e => {onFieldChange("phone", e.target.value)}} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className="registerServiceViewForm-input">
                                        <InputComponent 
                                            value={userData.first_name} 
                                            error={backendErrors.first_name || (errors.first_name_error && 'Podaj swoje imię')} 
                                            icon={false} 
                                            placeholder="Imię" 
                                            onChange={e => {onFieldChange("first_name", e.target.value)}} />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="registerServiceViewForm-input">
                                        <InputComponent 
                                            value={userData.last_name} 
                                            error={backendErrors.last_name || (errors.last_name_error && 'Podaj swoje nazwisko')} 
                                            icon={false} 
                                            placeholder="Nazwisko" 
                                            onChange={e => {onFieldChange("last_name", e.target.value)}} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <div className="registerServiceViewForm-input">
                                        <InputComponent 
                                            value={userData.password} 
                                            error={backendErrors.password || (errors.password_error && 'Podaj poprawne hasło')} 
                                            icon={false} 
                                            type="password"
                                            placeholder="Hasło" 
                                            onChange={e => {onFieldChange("password", e.target.value)}} />
                                            <span className="registerViewForm-msg">Hasło musi zawierać min. 8 znaków, wielką literę, znak specjalny i cyfrę.</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} className="registerServiceViewForm-column">
                                    <RadioButtonComponent 
                                        checked={userData.company_type === 0} 
                                        name="isCompany" 
                                        value="false" 
                                        text="Osobą fizyczną"
                                        onChange={() => onFieldChange('company_type', 0)}
                                    />
                                </Col>
                                <Col xs={12} md={6} className="registerServiceViewForm-column">
                                    <RadioButtonComponent 
                                        checked={userData.company_type === 1} 
                                        name="isCompany" 
                                        value="true"
                                        text="Przedstawicielem firmy" 
                                        onChange={() => onFieldChange('company_type', 1)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Col>

                    <Col xs={12} md={6}  className="registerServiceViewForm-column registerServiceViewForm-column-info">
                        <RegisterInfoComponentService />
                    </Col>
                </Row>
            </Container>
            { userData.company_type === 1 
                ? <RegisterServiceCompanyForm 
                    userData={userData} 
                    backendErrors={backendErrors}
                    errors={this.state.errors}
                    onFieldChange={onFieldChange}
                     /> 
                : <RegisterServicePublicPersonForm 
                    userData={userData}
                    backendErrors={backendErrors}
                    errors={this.state.errors}
                    onFieldChange={onFieldChange} 
                    />
            }
                <Container>
                    <Row>
                        <Col xs={12} md={12} className="registerServiceViewForm-column">
                            <div className="registerServiceViewForm-buttons">
                                <div className="registerServiceViewForm-button-wrapper">
                                        <ButtonComponent 
                                            isOrange={true} 
                                            isBlock={true} 
                                            text="Przejdź dalej" 
                                            onClick={this.validateInputs}
                                        />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        Object.keys(backendErrors).length 
                            ? <Row className="errors-row">
                                <Col xs={12} md={12}>
                                    {
                                        Object.keys(backendErrors).map((errorKey, i) => {
                                            return <div key={i}>{backendErrors[errorKey]}</div>
                                        })

                                    }
                                </Col>
                            </Row>
                            : null
                    }
                </Container>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterServiceViewForm;
