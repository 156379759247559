import React, { Component } from 'react';
import './FindOrderLoginView.scss';

import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";

import { Container, Row, Col } from 'react-grid-system';
import { ReactComponent as Rejestracja } from '../../assets/diagnosta.svg';
import { ReactComponent as Diagnostyka } from '../../assets/diagnostyka3.svg';


class FindOrderLoginView extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h2 className="findOrder-header">Szukaj zleceń</h2>
            </Col>
          </Row>
          <Row>
          <Col className="findOrder-column" xs={12} md={2}></Col>
            <Col className="findOrder-column" xs={12} md={4}>
              <div className="findOrder-column-inner">
                <div className="findOrder-img-wrapper">
                  <Rejestracja 
                    width="100%"
                    height="175px"
                  />
                </div>
                <div className="findOrder-text-wrapper">
                  <div className="findOrder-text">
                    <h3 className="findOrder-header-3">Masz konto?</h3>
                    <h4 className="findOrder-header-4">Zlecenia mogą zobaczyć tylko zarejestrowani i aktywni użytkownicy.</h4>
                    <Link to="/zaloguj">
                      <ButtonComponent className="findOrder-button" 
                        isCenter={true} isBlock={true} isOrange={true} text="ZALOGUJ SIĘ" 
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="findOrder-column" xs={12} md={4}>
              <div className="findOrder-column-inner">
                <div className="findOrder-img-wrapper">
                  <Diagnostyka 
                    width="100%"
                    height="175px" 
                  />
                </div>
                <div className="findOrder-text-wrapper">
                  <div className="findOrder-text">
                    <h3 className="findOrder-header-3">Dołącz do nas!</h3>
                    <h4 className="findOrder-header-4">Masz wiedzę oraz doświadczenie w sprawdzaniu pojazdów?</h4>
                    <Link to="/rejestracja-sprawdzajacego">
                      <ButtonComponent className="findOrder-button" isCenter={true} isBlock={true} isDark={true} text="ZAREJESTRUJ SIĘ" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="findOrder-column" xs={12} md={2}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(FindOrderLoginView);