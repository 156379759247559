import React, { Component } from 'react';
import './OrderCheckupFormComponent.scss';
import Geocoder from 'react-mapbox-gl-geocoder'
import { Link } from "react-router-dom";
import ButtonComponent from "../../../commons/ButtonComponent/ButtonComponent";
import { MAPBOX_TOKEN } from '../../../config';


const mapAccess = {
  mapboxApiAccessToken: MAPBOX_TOKEN
}


const queryParams = {
  country: 'pl',
  language: 'pl',
}


class OrderCheckupFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {},
      city: '',
    }
  }

  componentDidMount() {
    const geocoderInput = document.querySelector('.react-geocoder > input')
    if (geocoderInput) geocoderInput.placeholder = 'Ulica, nr domu, miasto'
  }

  onFieldChange(viewport, item) {
    localStorage.setItem('car_city', item.place_name)
    localStorage.setItem('lat', item.center[1])
    localStorage.setItem('lon', item.center[0])
    localStorage.setItem('viewport', JSON.stringify(viewport))
    localStorage.setItem('selectedPlace', JSON.stringify(item))
  }

  render() {
    return (
      <React.Fragment>
        <div className="orderCheckupForm-container">
          <form className="orderCheckupForm-form">
            <div className="orderCheckupForm-input">

            <div className="geocoder-wrapper">
              <Geocoder 
                {...mapAccess} 
                onSelected={this.onFieldChange}
                viewport={this.state.viewport}
                hideOnSelect={true}
                updateInputOnSelect={true}
                queryParams={queryParams}
              />
              <i aria-hidden="true" class="search icon search-icon"></i>
            </div>
            </div>
            <div className="orderCheckupForm-buttons">
              <Link to="/zlec-sprawdzenie">
                 <ButtonComponent isOrange={true} isBlock={true} text="Zleć sprawdzenie" />
              </Link>
              <span className="orderCheckupForm-info">ZA DARMO</span>
            </div>
        </form>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderCheckupFormComponent;
