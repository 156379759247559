import { faEnvelope, faPhone, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/pl';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { NotificationManager } from 'react-notifications';
import { Confirm } from 'semantic-ui-react';
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import RadioButtonComponent from "../../commons/RadioButtonComponent/RadioButtonComponent";
import CountdownComponent from "../../components/CountdownComponent/CountdownComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import { Link } from "react-router-dom";

import { serverURL } from '../../config';
import './OffersComponent.scss';

class OffersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemId: null,
      open: false,
      days_available: [],
      backendErrors: {},
      form: {
        phone_number: '',
        dayChosen:{
        number: null,
        },
      },
      errors: {
        phone: false,
        day: false,
      }
    };

    this.setActiveItemId = this.setActiveItemId.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getTimeLeft = this.getTimeLeft.bind(this);
  }
  onFieldChange(field, value) {
    this.setState({form: {
      ...this.state.form,
      dayChosen: {number: value}
    }})
  }
 
  onChange(field, value) {
      this.setState({form: {
        ...this.state.form,
        phone_number: value
      }})
    }

    handleStatusChange(orderId, offerId) {
      const token = localStorage.getItem('token');
      const number = this.state.form.dayChosen.number;
      const phone_number = this.state.form.phone_number;
      const body = {number, phone_number}
      const bodyDay = JSON.stringify(body);
     
      fetch(`${serverURL}orders/${orderId}/${offerId}`, {
          method: 'POST',
          headers: new Headers({'Authorization': `bearer ${token}`, 'content-type': 'application/json'}),
          body: bodyDay,
      })
          .then(
             this.setState({ open: false })
          )
          .then(
            (response) => {
              if (response.ok) {
                NotificationManager.success('Zaakceptowano ofertę.', 'Gratulacje', 5000)
                this.props.fetchOrderData()
              } else {
                NotificationManager.error('Coś poszło nie tak.', 'Ups!', 5000)
                response.json().then(json => {
                  NotificationManager.error(`${json}`, 'Ups!', 5000)

                  this.setState({
                    backendErrors: json.errors || json
                  })
                })
              }
            }
          )
      }  

  setActiveItemId(id) {
    this.setState({ activeItemId: id }) 
  }   
  
  
  openModal() {
    const isPhone = this.state.form.phone_number.length <= 8 ? true: false;
    const isDay = this.state.form.dayChosen.number === null ? true : false;
    const isFormFilled = !isPhone && !isDay;
    if (isFormFilled){
     this.setState({ open: true});
    } else if (!isFormFilled) {
      this.setState({ open: false, errors: {phone: isPhone, day: isDay} });
    }
  } 
  closeModal() {
    this.setState({ open: false })
  } 
 getTimeLeft(hour) {
    var now = moment();
    var deadline = now.clone().hour(hour).minute(0).second(0);
    const deadlineTime = undefined;
    setInterval(function() {
      if (now.isAfter(deadline)) {
        return undefined;
      } else {
        const deadlineTime = deadline.diff(now, "hours") + ':' + (deadline.diff(now, "minutes") % 60);
        
        return deadlineTime; 
      }
    }, 60 * 1000);
  }


  render() {
      const offersList = this.props.offers;
      const orderID = this.props.order;
      const orderData = this.props.orderData;
      const { activeItemId, backendErrors } = this.state;
      const isCustomer = this.props.isCustomer;
        const ordersList = offersList.map((offer, i) => {
            moment.locale('pl');
            const getHourExpire = offer.expiresTime;
            const expireHour = getHourExpire.substring(0, getHourExpire.indexOf(':'));
            const creatorHelper = offer.user.last_name ? offer.user.last_name.substring(0, 1) : "";
            const creator = offer.user.first_name + " " + creatorHelper + ".";
            const expiredDate = moment.utc(offer.expires);
            const formatDate = moment(expiredDate).format("DD/MM/YYYY");
            const createdDate = moment.utc(offer.created);
            const formatCreatedDate = moment(createdDate).format("DD.MM.YYYY");
            // const vat = Math.round(offer.price - (offer.price / 1.23));
            // const nettoValue = offer.price - vat;
            // const nettoPrice = Math.round((nettoValue + Number.EPSILON) * 100) / 100;

            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const dd = parseInt(day);
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const monthsArray = [
                {key: "01", value: "sty"},
                {key: "02", value: "lut"},
                {key: "03", value: "mar"},
                {key: "04", value: "kwi"},
                {key: "05", value: "maj"},
                {key: "06", value: "cze"},
                {key: "07", value: "lip"},
                {key: "08", value: "sie"},
                {key: "09", value: "wrz"},
                {key: "10", value: "paź"},
                {key: "11", value: "lis"},
                {key: "12", value: "gru"},
            ]
            const thisMonth = monthsArray.find( month => month.key === mm).value;
            moment.locale('pl');
            const thisDay = moment().format("[Dzisiaj] (DD MMM)");
            const todayDay = moment();
            const tomorrow = moment().add(1, 'days').format("[Jutro] (DD MMM)");
            const dayAfterTomorrow = moment().add(2, 'days').format("DD MMM");
            const twoDaysAfterTomorrow = moment().add(3, 'days').format("DD MMM");
            const arrayDays = offer.offerData.map(day => day.number);
            const isAccepted = orderData.acceptedOffer !== null ? true : false;
            const isDisabled = this.state.form.phone_number < 9;
            const isExpired = moment(todayDay).isAfter(expiredDate)

            let isStillAvailable = false
            const selectedOfferIndex = this.state.form.dayChosen.number
            const selectedOfferDate = offer.offerData && offer.offerData.find(item => item.number === selectedOfferIndex) && offer.offerData.find(item => item.number === selectedOfferIndex).date
            const currentDate = moment()
            if (selectedOfferDate && moment(selectedOfferDate).add(-1,'day').format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')) {
              if (currentDate.format('hh:mm') <= offer.expiresTime) {
                isStillAvailable = true
              }
            } else if (selectedOfferDate && moment(selectedOfferDate).add(-1,'day').format('YYYY-MM-DD') > currentDate.format('YYYY-MM-DD')) {
              isStillAvailable = true
            } 
            const isAvailable = !isAccepted && orderData.status === 1 && isStillAvailable;

            const isPhone = this.state.form.phone_number.length < 9 ? true : false;
            const isDay = this.state.form.dayChosen.number === null ? true : false;
            const isFormFilled = (isPhone && isDay) ? true : false;

            const parseDay = day => {
              if (day === moment().format('DD MMM')) return `Dzisiaj (${day})`
              if (day === moment().add(1, 'days').format('DD MMM')) return `Jutro (${day})`
              return day
            }

            return (
              <React.Fragment>
                <Row key={offer.id} className={`offerList-offer offer-${offer.status === 3 ? 'cancelled' : ''} offer-${offersList.length - 1 === i ? 'last' : 'basic'}`}>
                  <Col xs={12} md={1} ></Col>
                  <Col xs={4} md={2} className="offerList-element offerList-element-1"> 
                  <Link to={`/uzytkownik/${offer.user.id}`}>{creator}

                    <div className="rating">
                        <div className="rating-up">
                          <FontAwesomeIcon icon={faThumbsUp} /> 
                          {offer.user.positiveOptions}
                        </div>
                        <div className="rating-down">
                          <FontAwesomeIcon icon={faThumbsDown} /> 
                          {offer.user.negativeOptions}
                        </div>
                    </div>
                    </Link>
                  </Col>
                  <Col xs={12} md={2} className="offerList-element mobile-disable">
                    {/* {arrayDays.includes(1) ? <span className="date-span">{thisDay}</span> : ""}
                    {arrayDays.includes(2) ? <span className="date-span">{tomorrow}</span> : ""}
                    {arrayDays.includes(3) ? <span className="date-span">{dayAfterTomorrow}</span> : ""}
                    {arrayDays.includes(4) ? <span className="date-span">{twoDaysAfterTomorrow}</span> : ""} */}
                    {
                      offer.offerData.map((day, i) => {
                        if (`${moment().format('YYYY.MM.DD-HH:mm')}` > `${moment(day.date).add('-1', 'days').format('YYYY.MM.DD')}-${getHourExpire}`) return null
                        return <span className="date-span">{parseDay(moment(day.date).add('-1', 'days').format('DD MMM'))}</span>
                      })
                    }

                  </Col>
                  <Col xs={12} md={2} className="offerList-element mobile-disable">                       
                   {(isExpired || !arrayDays.includes(1))? "-" : <CountdownComponent order={orderData} offer={offer} listView={true} />}
                  </Col>
                  <Col xs={4} md={2} className="offerList-element">{offer.price} zł</Col>
                  <Col xs={4} md={2} className="offerList-element-7 offerList-element">
                   {offer.status !== 3 ? <div onClick={() => offer.id === activeItemId ? this.setActiveItemId(null) : this.setActiveItemId(offer.id)}>Zobacz więcej</div> : ""}
                   {offer.status === 3 ? "Oferta wycofana" : ""}
                   </Col>
                  <Col xs={12} md={1} ></Col>
                </Row>
                <Row className={`offerList-element-expand isOpened${activeItemId === offer.id ? '-true' : ''}`}>
                  <Col xs={12} md={1} className="offerList-element"></Col>
                  <Col xs={12} md={5} className="offerList-element offerList-times offerList-element-1">
                  <form onSubmit={e => {e.preventDefault()}}>
                    <div className="offerList-element-date">
                      <div className="contact-title-1">Data wystawienia oferty:</div> {formatCreatedDate}
                    </div>
                    {!isAccepted ?
                    <div className="offerList-element-expired">
                    <div className="offerList-left">
                      <div className="contact-title-1">Dostępne terminy:</div>
                      <div className="offer-dates">
                      
                      {/* {arrayDays.includes(1) ? <RadioButtonComponent 
                        checked={this.state.form.dayChosen.number === 1} 
                        name="number" 
                        value={1} 
                        text={thisDay}
                        onChange={() => this.onFieldChange('number', 1)}
                      /> : ""}
                      {arrayDays.includes(2) ? <RadioButtonComponent 
                        checked={this.state.form.dayChosen.number === 2} 
                        name="number" 
                        value={2} 
                        text={tomorrow}
                        onChange={() => this.onFieldChange('number', 2)}
                      /> : ""}
                      {arrayDays.includes(3) ? <RadioButtonComponent 
                        checked={this.state.form.dayChosen.number === 3} 
                        name="number" 
                        value={3} 
                        text={dayAfterTomorrow}
                        onChange={() => this.onFieldChange('number', 3)}
                      /> : ""}
                      {arrayDays.includes(4) ? <RadioButtonComponent 
                        checked={this.state.form.dayChosen.number === 4} 
                        name="number" 
                        value={4} 
                        text={twoDaysAfterTomorrow}
                        onChange={() => this.onFieldChange('number', 4)}
                      /> : ""} */}

                      {
                        offer.offerData.map((day, i) => {
                          if (`${moment().format('YYYY.MM.DD-HH:mm')}` > `${moment(day.date).add('-1', 'days').format('YYYY.MM.DD')}-${getHourExpire}`) return null
                          return <RadioButtonComponent 
                            checked={this.state.form.dayChosen.number === day.number} 
                            name="number" 
                            value={day.number} 
                            text={parseDay(moment(day.date).add('-1', 'days').format('DD MMM'))}
                            onChange={() => this.onFieldChange('number', day.number)}
                          />
                        })
                      }

                      {this.state.errors.day ? <div className='offer-form-error'>Wybierz datę</div> : ""}
                      </div> 
                      <div className="offer-phone">
                      <span className="offer-phone-nr">
                      Podaj swój numer telefonu
                      </span>
                      <InputComponent 
                                icon={false} 
                                error={this.state.errors.phone && "Podaj numer telefonu."}
                                placeholder="Numer telefonu" 
                                onChange={e => {this.onChange("phone_number", e.target.value)}} />  
                      </div>
                      </div>
                      <div className="offerList-right">
                        <CountdownComponent order={orderData} offer={offer} availableToday={offer.offerData.find(day => moment(day.date).add(-1, 'days').format('YYYY.MM.DD') === moment().format('YYYY.MM.DD'))} />
                      </div>
                    </div>
                    : "" }
                    <div>
                      <Container className="pricebox">
                        <Row className="pricebox-top">
                            <Col xs={6} className="pricebox-left accent">Cena: </Col>
                            <Col xs={6} className="pricebox-right">
                                <div className="pricebox-price">
                                    {offer.price}
                                    <span className="pricebox-span">zł</span>
                                </div>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="strong">Faktura </Col>
                            <Col xs={6} className="pricebox-right">
                                {offer.vat === 0 ? "Tak (23%)": ""}
                                {offer.vat === 1 ? "Tak": ""}
                                {offer.vat === 2 ? "Nie": ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} className="strong">Cena netto: </Col>
                            <Col xs={6} className="pricebox-right">
                                <div className="strong">
                                {offer.vat === 0 ? (offer.price / 1.23).toFixed(2) + 'zł' : ""}
                                {offer.vat === 1 ? offer.price + 'zł' : ""}
                                {offer.vat === 2 ? "-" : ""}
                                </div>
                            </Col>
                        </Row>
                      </Container>
                      <div class="offer-button-wrapper">
                        { !isAccepted && <ButtonComponent isOrange={true} isBlock={true} isCenter={true}  onClick={this.openModal} text="Zaakceptuj ofertę" /> }
                        { this.state.form.dayChosen.number !== null && !isAvailable && <span className="warning">Oferta nie jest już dostępna. Wybierz inny termin</span> }
                          <Confirm
                            open={this.state.open}
                            onCancel={this.closeModal}
                            onConfirm={e =>{  this.handleStatusChange(orderID, this.state.activeItemId)}}
                            content='Czy chcesz zaakceptować tę ofertę?'
                            cancelButton='Nie'
                            confirmButton="Tak"
                          />
                        </div>
                    </div>
                    </form>
                  </Col>
                  <Col xs={12} md={5} className="offerList-element offerList-element-7 ">
                    <div className="contact-title-1">Informacje dodatkowe</div>
                     <div className="offer-description"> {offer.description ? offer.description : 'Brak'}</div>
                    <div className="contact-title-1">Dane kontaktowe</div>
                    {isCustomer && (offer.status === 2 || offer.status === 0)
                      ? <div className="contact-info">
                          <div className="offer-contact-element">
                            <FontAwesomeIcon icon={faPhone} />  {offer.user.phone}
                          </div>
                          { offer.status === 2 && <div className="offer-contact-element">
                            <FontAwesomeIcon icon={faEnvelope} />  {offer.user.email}
                          </div> }
                        </div>
                      : <div className="contact-info">Tylko zlecający może zobaczyć te dane</div>
                  }
                    
           
                  </Col>
                  <Col xs={12} md={1} className="offerList-element"></Col>
                </Row>
              </React.Fragment>
            )
        });  
    return (
      <React.Fragment>
        <Container className="offerList-container">
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={4} md={2} className="offerList-header offerList-header-1">Sprawdzający</Col>
                <Col xs={12} md={2} className="offerList-header mobile-disable">Dostępne terminy <span className="offer-span">(najbliższe)</span></Col>
                <Col xs={12} md={2} className="offerList-header mobile-disable">Oferta dostępna dziś jeszcze przez</Col>
                <Col xs={4} md={2} className="offerList-header">Cena</Col>
                <Col xs={4} md={2} className="offerList-header offerList-header-6">Szczegóły</Col>
                <Col xs={12} md={1}></Col>
            </Row>
            {ordersList}
        </Container>
      </React.Fragment>
    );
  }
}

export default OffersComponent;