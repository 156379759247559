import React, { Component } from 'react';
import './CountdownComponent.scss';
import moment from 'moment';


class CountdownComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeCount: {
        hours: "00",
        min: "00"
      }
    }
    this.countDown = this.countDown.bind(this);

  }

  countDown() {
    const expires = this.props.offer.expiresTime;
    const offerHour = parseInt(expires.split(':')[0]);
  
    var d = new Date();
    var hours = (offerHour - d.getHours()) - 1;
    var min = 60 - d.getMinutes();
    if((min + '').length == 1){
      min = '0' + min;
    }
    this.setState({ timeCount: { hours: hours, min: min,}})

  }
  componentDidMount() {
    
    this.intervalId = setInterval(this.countDown.bind(this), 6 * 100);
  }
  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  render() {
    const offerTime = this.props.offer.expiresTime;
    const offerHour = offerTime.split(':')[0];
    const listView = this.props.listView;

    return (
      <React.Fragment>
      {!listView 
        ?       <div>
        <div className="contact-title-1">Wykonanie inspekcji dzisiaj dostępne jeszcze przez:</div>
        <div className="time">
        {moment().format('HH:mm') < this.props.offer.expiresTime && this.props.availableToday
          ? <div className="time-wrapper">
              <div className="hours"> {this.state.timeCount.hours}
             <span className="info info-span-left">godzin</span> 
              </div>
              <div className="hours"> : </div>
              <div className="hours"> {this.state.timeCount.min} 
              <span className="info info-span-right">minut</span>
              </div>
            </div>
          : "-"}
        </div>
        </div>
        :       <div>
        <div className="time">
        {this.state.timeCount.hours >= 0
          ? <div className="time-wrapper-list">
              <div className="hours"> {this.state.timeCount.hours}
              </div>
              <div className="hours"> : </div>
              <div className="hours"> {this.state.timeCount.min} 
              </div>
            </div>
          : "-"}
        </div>
        </div>
      }
      
      </React.Fragment>
    );
  }
}

export default CountdownComponent;
