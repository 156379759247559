import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StateProvider } from './state/state'

import { initialState } from './state/initialState'
import { reducer } from './state/reducer'

import './assets/Montserrat/Montserrat-Light.ttf';
import './assets/Montserrat/Montserrat-Bold.ttf';
import './assets/Montserrat/Montserrat-Regular.ttf';
import './assets/Roboto/Roboto-Light.ttf';
import './assets/Roboto/Roboto-Bold.ttf';
import './assets/Roboto/Roboto-Regular.ttf';

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
