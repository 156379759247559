import React, { Component } from 'react';
import './ProfileView.scss';

import { withRouter } from 'react-router-dom';
import { serverURL } from '../../config';
import timestampFormat from '../../utils/helpers/timestampFormat';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { Container, Row, Col } from 'react-grid-system';
import RatingView from "./RatingView";


class ProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userData: {
          companyName: "",
          company_type: 1,
          createdTimestamp: null,
          email: "",
          first_name: "",
          id: null,
          lastLoggedTimestamp: null,
          last_name: "",
          negativeOptions: null,
          ordersCountFromPastYear: null,
          positiveOptions: null,
          ratings: [],
        },
    };
  }

  componentWillMount() {
    const token = localStorage.getItem('token');
    const pathArray = window.location.pathname.split('/');
    const userId = pathArray[2];
    
    fetch(`${serverURL}users/${userId}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `bearer ${token}`,
        }),
    })
        .then(
          (response) => {
            if (response.ok) {
              response.json().then(data => {
                this.setState({userData: data})
            })
            } else {
              this.props.history.push(`/zaloguj`);
            }
        
            }
        )
  }
  
  render() {
    const {userData} = this.state;
    const creatorHelper = userData.last_name.substring(0, 1);
    const creator = userData.first_name + " " + creatorHelper + ".";

    const createdDate = timestampFormat(userData.createdTimestamp);
    const loginDate = timestampFormat(userData.lastLoggedTimestamp);

  
    return (
      <React.Fragment>
        <Container>
          <Row>
          <Col className="profileView-column" xs={12} md={1}></Col>
            <Col className="profileView-column" xs={12} md={10}>
              <div className="profileView-column-inner">
                <h2 className="profileView-title">Użytkownik: <span className="profileView-user">
                {!userData.companyName ? creator : `${userData.first_name} ${userData.last_name[0]}.`} </span>
                <span className="profileView-span">{userData.companyName ? `(firma)` : ``}</span>
                <span className="profileView-id">ID: {userData.id}</span>
                </h2>
      
              </div>
            </Col>
            <Col className="profileView-column" xs={12} md={1}></Col>
          </Row>
          <Row>
            <Col xs={12} md={1}></Col>
            <Col xs={12} md={10}>
            <div className="userInfo">
            <Container>

              <Row>
                <Col xs={12} md={6}>
                  <h4 className="profileView-title-4">Oceny użytkownika w ciagu ostatnich 12 miesiecy:</h4>
                  <div className="rating">
                    <div className="rating-up">
                      <FontAwesomeIcon icon={faThumbsUp} /> 
                      {userData.positiveOptions}
                    </div>
                    <div className="rating-down">
                      <FontAwesomeIcon icon={faThumbsDown} /> 
                      {userData.negativeOptions}
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="profileView-down profileView-downleft mobile-margin">
                    <div className="profileView-price-left">
                      Ilość transakcji w ostatnich 12 miesiącach:
                    </div>
                    <div className="profileView-price-right">
                      {userData.lastYearTransactions}
                    </div>
                  </div>
                  <div className="profileView-down profileView-downleft">
                    <div className="profileView-price-left">
                      Użytkownik od:
                    </div>
                    <div className="profileView-price-right">
                      {createdDate.date}
                    </div>
                  </div>
                  <div className="profileView-down profileView-downleft">
                    <div className="profileView-price-left">
                      Ostatnio logował się:
                    </div>
                    <div className="profileView-price-right">
                      {loginDate.date} || {loginDate.time}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            </div>

            </Col>
            <Col xs={12} md={1}></Col>

          </Row>
          <Row>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={10}>
          <div className="profileView-more">
          <h4 className="profileView-title-4">Informacje dodatkowe</h4>
          {userData.description}
          </div>

          </Col>
          <Col xs={12} md={1}></Col>
          
          </Row>
          <Row>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={10}>
          <div className="profileView-more">
          <h4 className="profileView-title-4">Opinie ({userData.ratings.length})</h4>
          <RatingView ratings={userData.ratings}/>
          </div>

          </Col>
          <Col xs={12} md={1}></Col>
          
          </Row>
          </Container>
          
      </React.Fragment>
    );
  }
}

export default withRouter(ProfileView);