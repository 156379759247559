import moment from 'moment';


export default function timeStampFormat (timestamp) {
    let unix_timestamp = timestamp;
    const date = new Date(unix_timestamp * 1000);
    const hours = date.getHours();
    const year = date.getYear();
    const minutes = "0" + date.getMinutes();
    const currMonth = date.getMonth()+1;
    const monthsArray = [
        {key: 1, value: "sty"},
        {key: 2, value: "lut"},
        {key: 3, value: "mar"},
        {key: 4, value: "kwi"},
        {key: 5, value: "maj"},
        {key: 6, value: "cze"},
        {key: 7, value: "lip"},
        {key: 8, value: "sie"},
        {key: 9, value: "wrz"},
        {key: 10, value: "paź"},
        {key: 11, value: "lis"},
        {key: 12, value: "gru"},
    ]
    const thisMonth = monthsArray.find( month => month.key === currMonth).value;
    const currYear = date.getFullYear();
    const thisYearFalse = currYear !== year;
    const displayYear = new Date(unix_timestamp).toString().split(" ")[3];
    const newDate = date.getDate() + ' ' + thisMonth +  ' ' + (thisYearFalse ? "" : displayYear);
    // const formattedDate = newDate.split("/").join(".")
    const orderDayNumber = newDate.split(" ");
    const orderDay = orderDayNumber[0]
    const today = moment().date();
    const yesterday = moment().add(-1, 'days').date();
    const isToday = orderDay == today;
    const isYesterday = orderDay == yesterday;
    const isTodayOrYesterday = isToday || isYesterday;
    const isTodayText = isToday ? "Dzisiaj" : "Wczoraj";
    const formattedDate = !isTodayOrYesterday 
        ? newDate.split("/").join(".")
        : isTodayText;

    var formattedTime = hours + ':' + minutes.substr(-2);
    const dateObject = {
        "date": formattedDate,
        "time": formattedTime,
    }
    return dateObject;
}