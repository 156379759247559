import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import { NavLink, Link } from "react-router-dom";

import './AllOrdersView.scss';
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import AllOrdersGridComponent from "../../components/GridComponent/AllOrdersGridComponent";
import { serverURL } from '../../config';


class AllOrdersView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: []
    }
  }

  componentWillMount() {
    const token = localStorage.getItem('token');
    const radius = localStorage.getItem('radius');

    if (!token) {
      this.props.history.push("/znajdz-zlecenie-login");
      return
    }

    fetch(`${serverURL}users/me`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `bearer ${token}`, 
      }),
    })
      .then(
        (response) => {
          if (response.ok) {
            response.json().then(data => {
            this.setState({location: data.company_location, dist: data.radius})

          })
          } else {
            this.props.history.push(`/zaloguj`);
          }
      
          }
       
      )
    fetch(`${serverURL}orders`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `bearer ${token}`, 
      }),
    })
    .then(
      (response) => {
        if (response.ok) {
          response.json().then(json => {
            this.setState({orders: json, dist: radius})
          })
        } else {
          this.props.history.push(`/zaloguj`);
        }
    
        }
    )
  }

  render() {
    const orders = this.state.orders.data;
    const { location, dist } = this.state;
    if (!orders) return null
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs={12} md={12}>
                <div className="grid-info">
                  Wyświetlono oferty z Twojego obszaru: {location}, +{dist}km <span className="link"><Link to="/moje-konto">Zmień</Link></span>
                </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <PaginationComponent
                data={orders}
              >
                <AllOrdersGridComponent />
              </PaginationComponent>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(AllOrdersView)