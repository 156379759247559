import React, { Component } from 'react';
import './GridComponent.scss';
import { NotificationManager } from 'react-notifications';
import { serverURL } from '../../config';
import { Link } from "react-router-dom";
import { Confirm } from 'semantic-ui-react';
import timestampFormat from '../../utils/helpers/timestampFormat';


class OffersGridComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      activeItem: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    }

    openModal(id) {
      this.setState({ open: true, activeItem: id })
    } 
    closeModal() {
      this.setState({ open: false })
    } 
    handleStatusChange(id) {
      const token = localStorage.getItem('token');
      fetch(`${serverURL}offers/${this.state.activeItem}/cancel`, {
          method: 'POST',
          headers: new Headers({
              'Authorization': `bearer ${token}`,
          }),
      })
          .then(
            (response) => {
              if (response.ok) {
                NotificationManager.success('Zmieniono status oferty.', 'Gratulacje', 5000)
              } else {
                NotificationManager.error('Coś poszło nie tak.', 'Ups!', 5000)

              }
            }
          )
          .then(
            this.setState({ open: false })
          )
          .then(
           window.location.reload(false)
          )
      }  
  render() {
    const ordersList = this.props.data.map((order, index) => {
      
      const expiresDate = timestampFormat(order.createdAt);
      return (
          <tr key={index}>
              <td>
                <Link to={`/zlecenie/${order.id}`}>
                  <span className="carName">{order.model.make.name || ''} {order.model.name || ''}</span>
                </Link>
              </td>
              <td  className="mobile-disable">{expiresDate.date} <span className="allorders-time">{expiresDate.time}</span></td>
              <td  className="mobile-disable">{order.isOrderFullInspection ? "Kompleksowa" : "Indywidualna"}</td>
              <td> 
              {order.status === 0 ? <span className="active">Aktywna</span> : "" }

              {order.status === 1 ? <span className="finished">Anulowana</span> : "" }
              {order.status === 2 ? <span className="ongoing">Zaakceptowana</span> : ""}
              {order.status === 3 ? <span className="finished">Wycofana</span> : ""}

              </td>
              <td>
                {order.status === 0 ? <span  onClick={e =>{this.openModal(order.id)}} className="userOrdersList-button pointer-element">WYCOFAJ</span> : ""}
                <Confirm
                open={this.state.open}
                onCancel={this.closeModal}
                onConfirm={e =>{this.handleStatusChange(order.id)}}
                content='Czy chcesz wycofać tę ofertę?'
                cancelButton='Nie'
                confirmButton="Tak"
              />
                {(order.status === 2 || order.status === 3)? <Link to={`/transakcja/${order.orderId}`}>Szczegóły</Link> : ""}
              </td>
          </tr>
      )
    }); 
    return (
      <React.Fragment>
        <table className="userOrders-table">
          <thead>
            <tr>
              <th>Zlecenie</th>
              <th  className="mobile-disable">Data wystawienia</th>
              <th  className="mobile-disable">Zakres</th>
              <th>Status</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
           {ordersList}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default OffersGridComponent;