import React, { Component } from 'react';
import './FooterComponent.scss';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Container, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookF , faLinkedinIn, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {  faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import Umowa from '../../assets/UmowaKupnaSprzedazy.pdf';


class FooterComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <React.Fragment>
        <Container className="footer-container">
          <Row>
            <Col xs={12} md={2}></Col>
            <Col className="footer-column" sm={2}>
              <Logo className="footer-logo" />
              <div className="footer-content">
                <div className="footer-element"><a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Polityka_Prywatności.pdf" target="_blank">Polityka Prywatności</a></div>
                <div className="footer-element"><a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Regulamin.pdf" target = "_blank">Regulamin</a></div>
                <div className="footer-element footer-element-smaller">
                  Coś nie działa tak jak powinno? 
                  <span className="footer-link"><Link to="/kontakt">Daj znać</Link></span>
                </div>
                <div className="footer-element footer-element-smaller">Korzystanie z serwisu oznacza akceptację regulaminu</div>
              </div>
            </Col>
            <Col className="footer-column" sm={2}>
              <h4 className="footer-header footer-header-special">MotoDetektyw</h4>
              <div className="footer-content">
                <div className="footer-element">
                  <a href="https://blog.motodetektyw.pl/">
                    <FontAwesomeIcon className="footer-text-accent footer-text-icon" icon={faLongArrowAltRight} /> 
                    Poradniki
                  </a>
                </div>
                <div className="footer-element">
                  <a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Umowa-Kupna-Pojazdu-MotoDetektyw.pl.pdf" target = "_blank">
                    <FontAwesomeIcon className="footer-text-accent footer-text-icon" icon={faLongArrowAltRight} />
                    Umowa kupna / sprzedaży
                  </a>
                </div>
                <div className="footer-element">
                  <Link to="/kontakt">
                    <FontAwesomeIcon className="footer-text-accent footer-text-icon" icon={faLongArrowAltRight} /> 
                    Pomoc / Kontakt
                  </Link>
                </div>
              </div>
            </Col>
            <Col className="footer-column" sm={2}>
              <h4 className="footer-header">Serwis</h4>
              <div className="footer-content">
                <div className="footer-element">
                  <FontAwesomeIcon className="footer-text-accent footer-text-icon" icon={faLongArrowAltRight} /> 
                  <Link to="/zlec-sprawdzenie">Zleć sprawdzenie</Link>
                </div>
                <div className="footer-element">
                  <FontAwesomeIcon className="footer-text-accent footer-text-icon" icon={faLongArrowAltRight} /> 
                  <Link to="/lista-zlecen">Sprawdzaj pojazdy i zarabiaj</Link>
                </div>
                <div className="footer-element">
                <FontAwesomeIcon className="footer-text-accent footer-text-icon" icon={faLongArrowAltRight} /> 
                <a href="https://blog.motodetektyw.pl/pliki-do-pobrania/Raport.pdf" target="_blank">Wzór raportu</a>
                </div>
              </div>
            </Col>
            <Col className="footer-column footer-socials-mobile" sm={2}>
              <h4 className="footer-header">Bądźmy blisko</h4>
              <div className="footer-content">
                <div className="footer-icon"><a href="https://www.facebook.com/MotoDetektywPL/"><FontAwesomeIcon icon={faFacebookF} /></a></div>
                <div className="footer-icon"><FontAwesomeIcon icon={faLinkedinIn} /></div>
                <div className="footer-icon"><FontAwesomeIcon icon={faYoutube} /></div>
                <div className="footer-icon"><a href="https://twitter.com/motodetektyw"><FontAwesomeIcon icon={faTwitter} /></a></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="footer-socials" sm={12}>
              <h4 className="footer-header">Bądźmy blisko</h4>
              <div className="footer-content">
              <div className="footer-icon"><Link to="https://www.facebook.com/MotoDetektywPL/"><FontAwesomeIcon icon={faFacebookF} /></Link></div>
              <div className="footer-icon"><FontAwesomeIcon icon={faLinkedinIn} /></div>
              <div className="footer-icon"><FontAwesomeIcon icon={faYoutube} /></div>
              <div className="footer-icon"><Link to="https://twitter.com/motodetektyw"><FontAwesomeIcon icon={faTwitter} /></Link></div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="footer-copyright">&copy; 2019 MotoDetektyw</div>
      </React.Fragment>
    );
  }
}

export default FooterComponent;
