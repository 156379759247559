import React, { Component} from 'react';
import './TextareaComponent.scss';

class TextareaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      };
    this.onFocus = this.onFocus.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }
  onFocus(e) {
    this.setState({
        isFocused: true,
    });
  }
  onKeyUp(e) {
    if (e.target.value.length > 0) {
      this.setState({
          isFocused: true,
      });
    } else {
      this.setState({
        isFocused: false,
      });
    }
  }
  render() {
    const { isFocused } = this.state
    const { error } = this.props

    return (
      <React.Fragment>
        <div className="textarea-wrapper">
          { 
            this.props.value 
              ? <textarea value={this.props.value} 
                  name={this.props} 
                  onChange={this.props.onChange} 
                  onKeyUp={this.onKeyUp} 
                  onFocus={this.onFocus} 
                  className={`textarea ${error && 'textarea-border-red'}`} />
              : <textarea name={this.props} 
                  onChange={this.props.onChange} 
                  onKeyUp={this.onKeyUp} 
                  onFocus={this.onFocus} 
                  className={`textarea ${error && 'textarea-border-red'}`} />
          }
          <span className={`input-floating-label input-floating-label-${isFocused || !!this.props.value} ${error && 'textarea-label-red'}
          `}>{this.props.placeholder}</span>
          { error && <div className="textarea-error">{error}</div> }
        </div>
      </React.Fragment>
    );
  }
}

export default TextareaComponent;
