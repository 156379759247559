import React, { Component } from 'react';
import './ProsComponent.scss';
import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";

import { Link } from "react-router-dom";

import { Container, Row, Col } from 'react-grid-system';
import { ReactComponent as Rejestracja } from '../../assets/diagnosta.svg';
import { ReactComponent as Zero } from '../../assets/zero.svg';
import { ReactComponent as Dyskusja } from '../../assets/dyskusja.svg';
import { ReactComponent as BezZobowiazan } from '../../assets/bez-zobowiazan.svg';

class ProsComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="pros-wrapper">
          <Container className="pros-container">
            <Row>
              <Col className="pros-column pros-column-left" xs={12} md={4}>
                <div className="pros-header-wrapper">
                  <h3 className="pros-header">
                    <span className="pros-text-special">
                      Dlaczego warto 
                      </span> 
                    wybrać MotoDetektywa?
                  </h3>
                </div>
              </Col>
              <Col className="pros-column" xs={12} md={2}>
                <div className="pros-column-inner">
                  <div className="pros-img-wrapper">
                    <Rejestracja   width="100%"
                    preserveAspectRatio="xMidYMid meet"/>
                  </div>
                  <div className="pros-text-wrapper">
                    <div className="pros-text">
                      <span className="pros-text-special">
                        Darmowa
                      </span>
                      <Link to="/rejestracja">
                        <ButtonComponent isBlock={true} isDark={true} text="Rejestracja" iconUser={true}/>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pros-column" xs={12} md={2}>
                <div className="pros-column-inner">
                  <div className="pros-img-wrapper">
                    <Zero  width="100%"
                    preserveAspectRatio="xMidYMid meet"/>
                  </div>
                  <div className="pros-text-wrapper">
                    <div className="pros-text">
                      <span className="pros-text-special">
                        Ozł
                      </span>
                      za wystawienie twojego zlecenia
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pros-column" xs={12} md={2}>
                <div className="pros-column-inner">
                  <div className="pros-img-wrapper">
                    <Dyskusja  width="60%"
                    preserveAspectRatio="xMidYMid meet"/>
                  </div>
                  <div className="pros-text-wrapper">
                    <div className="pros-text">
                      Wygodne
                      <span className="pros-text-special">
                        porównanie ofert oraz opinii
                      </span>
                      sprawdzających
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pros-column" xs={12} md={2}>
                <div className="pros-column-inner">
                  <div className="pros-img-wrapper">
                    <BezZobowiazan  width="100%"
                    preserveAspectRatio="xMidYMid meet"/>
                  </div>
                  <div className="pros-text-wrapper">
                    <div className="pros-text">
                      <span className="pros-text-special">
                        Brak zobowiązań
                      </span>
                      - nie musisz akceptować żadnej oferty
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ProsComponent;
