import React, { Component } from 'react';
import './RegisterServiceView.scss';

import { withRouter } from 'react-router-dom';
import RegisterServiceViewForm from "./RegisterServiceViewForm/RegisterServiceViewForm";

import { Container, Row, Col } from 'react-grid-system';



class RegisterServiceView extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
          <Col className="registerService-column" xs={12} md={1}></Col>
            <Col className="registerService-column" xs={12} md={10}>
              <div className="registerService-column-inner">
                <RegisterServiceViewForm />
              </div>
            </Col>
            <Col className="registerService-column" xs={12} md={1}></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterServiceView);