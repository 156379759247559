import React, { Component} from 'react';
import './CheckboxComponent.scss';

class CheckboxComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { error } = this.props

    return (
      <React.Fragment>
        <div className="checkbox-wrapper">
        <label className="checkbox-label">
          <div className="checkbox-container">
          {
            this.props.checked 
              ? <input checked={this.props.checked} type="checkbox" className="Checkbox" onChange={this.props.onChange} />
              : <input type="checkbox" className="Checkbox" onChange={this.props.onChange} />
          }
          </div>
          <div className="checkbox-text">
            { error && <span className="error-star">* </span> }
            {this.props.text}
          </div>
          </label>
        </div>
        { error && <div className="error-message">{error}</div> }
      </React.Fragment>
    );
  }
}

export default CheckboxComponent;
