import React, { Component } from 'react';
import './DualButtonComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

class DualButtonComponent extends Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      <React.Fragment>
        <div className={`dualButton 
          dualButton-${this.props.isOrange ? "orange" : ""}
          dualButton-${this.props.isLeft ? "left" : ""} 
          dualButton-${this.props.isRight ? "right" : ""}
          dualButton-${this.props.hideMobileText ? "hide" : ""} 
          dualButton-${this.props.isDark ? "dark" : "light"}`
        }>
            {this.props.iconUser ? <FontAwesomeIcon className="dualButton-icon" icon={faUser} /> : ""}
            {this.props.iconLock ? <FontAwesomeIcon className="dualButton-icon" icon={faLock} /> : ""}
            <span className={`dualButton-text dualButton-text-${this.props.hideMobileText ? "hide" : ""}`}>
              {this.props.text}
            </span>
        </div>
      </React.Fragment>
    );
  }
}

export default DualButtonComponent;
