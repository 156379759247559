import React, { Component } from 'react';
import './RegisterInfoComponent.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class RegisterInfoComponent extends Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      <React.Fragment>
        <div className="register-info-wrapper">
            <ul className="register-info-list">
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /><span className="register-info-special">Darmowa</span> rejestracja</li>
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /><span className="register-info-special">0zł</span> za wystawienie zlecenia</li>
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} />Wygodne <span className="register-info-special">porównanie ofert oraz opinii</span> sprawdzających</li>
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /><span className="register-info-special">Brak zobowiązań</span> - nie musisz akceptować żadnej oferty</li>
            </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterInfoComponent;
