import React, { Component } from 'react';
import './RegisterInfoComponentService.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

class RegisterInfoComponentService extends Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      <React.Fragment>
        <div className="register-info-wrapper">
            <ul className="register-info-list">
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /><span className="register-info-special">Brak</span> abonamentu. Rejestracja <span className="register-info-special">0zł</span></li>
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} />Codzienne <span className="register-info-special">powiadomienia o zleceniach</span> z Twojej okolicy</li>
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} />Sprawdzaj pojazdy <span className="register-info-special">i zarabiaj</span> dodatkowe pieniądze</li>
            <li><FontAwesomeIcon className="register-info-icon" icon={faCheck} /> Sam ustalasz <span className="register-info-special">zakres oraz cenę</span> inspekcji</li>
            </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterInfoComponentService;
