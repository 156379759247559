import React, { Component } from 'react';
import './GridComponent.scss';
import { Link } from "react-router-dom";
import timestampFormat from '../../utils/helpers/timestampFormat';

class GridComponent extends Component {
  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(tab) {
    }
    
  render() {
    const ordersList = this.props.data.map((order, index) => {
      const createdDate = timestampFormat(order.createdTimestamp);

      return (
          <tr key={index}>
              <td>
                <Link to={`/zlecenie/${order.id}`}>
                  <span className="carName">{order.model.make.name} {order.model.name}</span>
                </Link>
              </td>
              <td className="mobile-disable">{order.isFullInspection ? "Kompleksowa" : "Indywidualna"}</td>
              <td>{order.city && order.acceptedOffer ? order.city : order.city.slice(order.city.indexOf(',')+2)}</td>
              <td className="allorders-date">{createdDate.date} <span className="allorders-time">{createdDate.time}</span></td>
              <td  className="mobile-disable">{order.offers.length}</td>
          </tr>
      )
    }); 
    return (
      <React.Fragment>
        <table className="userOrders-table">
          <thead>
            <tr>
              <th>Pojazd</th>
              <th  className="mobile-disable">Zakres inspekcji</th>
              <th>Miejsce</th>
              <th>Dodano</th>
              <th  className="mobile-disable">Oferty</th>
            </tr>
          </thead>
          <tbody>
           {ordersList}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default GridComponent;