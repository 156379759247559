import React, { Component } from 'react';
import './CreateOfferView.scss';
import { serverURL } from '../../config';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
import 'moment/locale/pl';

import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";

import { Container, Row, Col } from 'react-grid-system';
import { Dropdown} from 'semantic-ui-react';

import ButtonComponent from "../../commons/ButtonComponent/ButtonComponent";
import InputComponent from "../../commons/InputComponent/InputComponent";
import TextareaComponent from "../../commons/TextareaComponent/TextareaComponent";
import CheckboxComponent from "../../commons/CheckboxComponent/CheckboxComponent";

class CreateOfferView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backendErrors: {
            },
            offerData: {
                price: "",
                vat: "0",
                days_available: [],
                up_to_hour: "",
            },
          }
        }

        onFieldChange(field, value) {
            if (field === 'price') {
                const vat = value / 1.23
                const netto = vat
                this.setState({
                  nettoPrice: netto.toFixed(2),
                  offerData: {
                    ...this.state.offerData,
                    price: parseFloat(value).toFixed(2),
                  }
                })   
            }
            else {
                this.setState({offerData: {
                    ...this.state.offerData,
                    [field]: value
                  }})
            }
          }

   onCheckChange(value) {
       const arrayOfDays = this.state.offerData.days_available;
       const isValueAv = arrayOfDays.includes(value);
       if (isValueAv) {
        const removeItem = arrayOfDays.filter(item => item !== value)
        this.setState({offerData: {
            ...this.state.offerData,
            days_available: removeItem
          }})

       } else {
        this.setState({offerData: {
            ...this.state.offerData,
            days_available: [...this.state.offerData.days_available, value]
          }})
       }
    }
  

      handleOfferCreation(e) {
        e.preventDefault()
        const token = localStorage.getItem('token');
        const pathArray = window.location.pathname.split('/');
        const orderId = pathArray[2];
        const updateData = Object.assign({ 'order_id': orderId }, this.state.offerData)
        
        fetch(`${serverURL}offers/order/${orderId}`, {
          method: 'POST',
          headers: new Headers({'Authorization': `bearer ${token}`, 'content-type': 'application/json'}),
          body: JSON.stringify(updateData),
        })
        .then((response) => {
    
          if (response.ok) {
            NotificationManager.success('Twoja oferta została złożona.', 'Gratulacje', 5000)
            this.props.history.push(`/zlecenie/${orderId}`)
          } else {
            response.json().then(json => {
              NotificationManager.error('Wystąpił błąd.', 'Uwaga!', 5000)

              this.setState({
                backendErrors: json.errors
              })
            })
          }
        }) 
      }

  render() {
    const { offerData } = this.state;
    const orderExpires = localStorage.getItem('orderExpires');
    const today = new Date();
    moment.locale('pl');
    const thisDay = moment().format("[Dzisiaj] (DD MMM)");
    const tomorrow = moment().add(1, 'days').format("[Jutro] (DD MMM)");
    const dayAfterTomorrow = moment().add(2, 'days').format("DD MMM");
    const twoDaysAfterTomorrow = moment().add(3, 'days').format("DD MMM");
    const timeOptions = [
        {key: "1", text: "01:00", value: "01:00"},
        {key: "2", text: "02:00", value: "02:00"},
        {key: "3", text: "03:00", value: "03:00"},
        {key: "4", text: "04:00", value: "04:00"},
        {key: "5", text: "05:00", value: "05:00"},
        {key: "6", text: "06:00", value: "06:00"},
        {key: "7", text: "07:00", value: "07:00"},
        {key: "8", text: "08:00", value: "08:00"},
        {key: "9", text: "09:00", value: "09:00"},
        {key: "10", text: "10:00", value: "10:00"},
        {key: "11", text: "11:00", value: "11:00"},
        {key: "12", text: "12:00", value: "12:00"},
        {key: "13", text: "13:00", value: "13:00"},
        {key: "14", text: "14:00", value: "14:00"},
        {key: "15", text: "15:00", value: "15:00"},
        {key: "16", text: "16:00", value: "16:00"},
        {key: "17", text: "17:00", value: "17:00"},
        {key: "18", text: "18:00", value: "18:00"},
        {key: "19", text: "19:00", value: "19:00"},
        {key: "20", text: "20:00", value: "20:00"},
        {key: "21", text: "21:00", value: "21:00"},
        {key: "22", text: "22:00", value: "22:00"},
    ];
    const expirationDate = new Date(orderExpires*1000);
    const todaysDate = moment();
    const expireDate = moment(expirationDate);
    const dateDiff = expireDate.diff(todaysDate, 'days');
    const dateDiffPos = Math.abs(dateDiff)
    const arrayOfDates =  new Array(dateDiffPos);
    const now = todaysDate.hours();
    
    const timeIfToday = timeOptions.filter(elem => elem.value.split(":")[0] > now + 1)
    const isOnlyToday = this.state.offerData.days_available.length === 1 && this.state.offerData.days_available.includes(1)
    const timeOptionsArray = isOnlyToday ? timeIfToday : timeOptions;
    const vatOptions = [
        {key: "1", text: "Tak (23%)", value: "0"},
        {key: "2", text: "Tak", value: "1"},
        {key: "3", text: "Nie", value: "2"},
      ]
    return (
      <React.Fragment>
        <form className="createOffer-container" onSubmit={this.handleOfferCreation}>
            <Container className="createOffer-container">
                <Row>
                    <Col md={1}></Col>
                    <Col xs={12} md={10}>
                    <h2 className="createOffer-header">Dodaj ofertę</h2>
                    </Col>
                </Row>
            </Container>
            <Container className="createOffer-container">
                <Row className="createOffer-top">
                    <Col xs={1}></Col>
                    <Col className="createOfferViewForm-column" xs={5}>
                        <h3>Sprawdzenie mozliwe w dniach:</h3>
                        {arrayOfDates.length >= 0 ? <CheckboxComponent 
                            checked={offerData.days_available === 1} 
                            name="days_available" 
                            value={1} 
                            text={thisDay}
                            onChange={() => this.onCheckChange(1)}
                        /> : ""}
                        {arrayOfDates.length >= 1 ? <CheckboxComponent 
                            checked={offerData.days_available === 2} 
                            name="days_available" 
                            value={2} 
                            text={tomorrow}
                            onChange={() => this.onCheckChange(2)}
                        /> : ""}
                        {arrayOfDates.length >= 2 ? <CheckboxComponent 
                            checked={offerData.days_available === 3} 
                            name="days_available" 
                            value={3} 
                            text={dayAfterTomorrow}
                            onChange={() => this.onCheckChange(3)}
                        /> : ""}
                        {arrayOfDates.length >= 3 ? <CheckboxComponent 
                            checked={offerData.days_available === 3} 
                            name="days_available" 
                            value={4} 
                            text={twoDaysAfterTomorrow}
                            onChange={() => this.onCheckChange(4)}
                        /> : ""}
                    </Col>
                    <Col className="createOfferViewForm-column-float" xs={5}>
                        Do której godziny najpóźniej (w dniach które zaznaczyłeś) musisz wiedzieć o zleceniu by wykonać je tego samego dnia?
                        <div className="dropdown-wrapp">
                            <Dropdown 
                                value={offerData.time}
                                fluid
                                selection
                                options={timeOptionsArray}
                                onChange={(e, { value }) => this.onFieldChange("up_to_hour", value)}
                            />
                        </div>
                        
                    </Col>
                </Row>
                <Row className="createOffer-bot">
                    <Col xs={1}></Col>
                    <Col xs={12} md={5}>
                        <h4 className="createOffer-header-4">Informacje dodatkowe</h4>
                    </Col>
                </Row>
                <Row className="createOffer-bot">
                    <Col xs={1}></Col>
                    <Col xs={12} md={5}>
                        <div className="createOffer-input">
                            <TextareaComponent 
                            onChange={e => this.onFieldChange('description', e.target.value)}
                            placeholder="Możesz napisac coś od siebie jeśli chcesz" />
                            <div className="createOffer-info"><span className="accent">Uwaga! </span>Nie zamieszczaj tutaj swoich danych kontaktowych.</div>
                        </div>
                    </Col>
                    <Col xs={12} md={5}>
                    <Container className="pricebox">
                        <Row className="pricebox-top">
                            <Col xs={6} className="pricebox-left accent">Cena: </Col>
                            <Col xs={6} className="pricebox-right">
                                <div className="pricebox-price">
                                    <InputComponent icon={false} 
                                    onChange={e => this.onFieldChange('price', e.target.value)}
                                    placeholder="Cena brutto" />
                                    <span className="pricebox-span">zł</span>
                                </div>
                                
                            </Col>
                        </Row>
                        <Row className="pricebox-bot">
                            <Col xs={6} className="strong">Czy wystawiasz faktury? </Col>
                            <Col xs={6} className="pricebox-right">
                            <div className="dropdown-wrapp-1">
                            <Dropdown 
                                value={offerData.vat}
                                fluid
                                selection
                                options={vatOptions}
                                onChange={(e, { value }) => this.onFieldChange("vat", value)}
                            />
                        </div>
                            </Col>
                        </Row>
                       <Row className="pricebox-bot">
                            <Col xs={6} className="strong">Cena netto: </Col>
                            <Col xs={6} className="pricebox-right">
                                <div className="strong">
                                {offerData.vat === "0" ?  this.state.nettoPrice : ""}
                                {offerData.vat === "1" ? (isNaN(parseFloat(this.state.offerData.price)) ? "-" : parseFloat(this.state.offerData.price).toFixed(2)) : ""}
                                {offerData.vat === "2" ? "-" : ""}
                                zł</div>
                            </Col>
                        </Row> 
                    </Container>
                    </Col>
                </Row>
                <Row className="createOffer-bot">
                    <Col xs={1}></Col>
                    <Col xs={12} md={10} className="createOfferViewForm-column">
                        <div className="createOfferViewForm-buttons">
                            <div className="createOfferViewForm-button-wrapper">
                                    <ButtonComponent 
                                        isOrange={true} 
                                        isBlock={true} 
                                        onClick={e => this.handleOfferCreation(e)}
                                        text="Dodaj ofertę"
                                    />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </form>
      </React.Fragment>
    );
  }
}

export default withRouter(CreateOfferView);